import React, { FC } from 'react';

import { translate } from '../../utils/translation';
import { ESalesChannel } from '../../enum';
import './index.scss';

interface IProps {
  channelType: ESalesChannel;
  progress?: number;
}

const ProgressBar: FC<IProps> = ({ channelType, progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar__heading">
        {`${translate('common.info.percentCompletedProcess.firstPart')} `}
        <span className="progress-bar__heading__percent">
          {progress} {` ${translate('common.info.percentCompletedProcess.secondPart')} `}
        </span>
        {translate('common.info.percentCompletedProcess.thirdPart')}
      </div>
      <div className="progress-bar__parent">
        <div className={`progress-bar__child bgColor-${channelType}`} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
