import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import { translate } from 'shared/utils/translation';
import { styleModal } from 'shared/assets/styles/react-modal';
import CloseIcon from 'shared/assets/images/compressImage/close-icon.svg';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';

import './index.scss';

interface IProps {
  openInfoModal: boolean;
  handleCloseQuestionnaireInfo?: () => void;
}

const QuestionnaireInfo: FC<IProps> = ({ openInfoModal, handleCloseQuestionnaireInfo }) => {
  return (
    <ReactModal
      style={styleModal}
      isOpen={openInfoModal}
      onRequestClose={handleCloseQuestionnaireInfo}
      shouldCloseOnOverlayClick={false}
      className="questionnaire__info__modal modal"
    >
      <Panel className="modal__header" justifyContent={4} alignItems={2}>
        <span className="modal__header__title">{translate('common.information')}</span>
        <img onClick={handleCloseQuestionnaireInfo} src={CloseIcon} alt="close" className="modal-close" role="none" />
      </Panel>
      <Panel className="modal-line" />
      <Panel justifyContent={2} paddingTop={2} className="questionnaire-info__modal__context" paddingLeft={2} paddingRight={2}>
        <Panel marginRight={2} paddingTop={1}>
          <img src={InfoSolid} alt="info" />
        </Panel>
        <Panel marginBottom={2} className="questionnaire__info__modal__context__content">
          {translate('checkInfo.questionnaire.content')}
        </Panel>
      </Panel>
    </ReactModal>
  );
};

export default QuestionnaireInfo;
