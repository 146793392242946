import React, { FC, useState } from 'react';
import { Panel, Hr } from '@kapital-bank/kb-ui-design-system';

import Container from 'shared/components/Container';
import { changeLang, translate } from 'shared/utils/translation';
import CloseMenu from 'shared/assets/images/compressImage/close-menu.svg';
import MinusRed from 'shared/assets/images/compressImage/minus-red.svg';
import Plus from 'shared/assets/images/compressImage/plus.svg';

import SubCategoryMobile from './SubCategoryMobile';
import { ELang } from '../../../../enum';
import { useGeneralInfoContext } from '../../../../context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  active: boolean;
  isMobileMenu: () => void;
}

const MobileNav: FC<IProps> = ({ active, isMobileMenu }) => {
  const { lang } = useGeneralInfoContext();
  const marginLeft = {
    marginLeft: '30px',
  };
  const [toggle, setToggle] = useState(0);

  const handleToggleClick = (id: number) => {
    setToggle(id);
    if (id === toggle) {
      setToggle(99);
    }
  };

  return (
    <Panel>
      {active ? (
        <Panel className="navbar__mobile__kb">
          <Container>
            <Panel flexDirection={0} justifyContent={4} marginBottom={4} marginTop={4} paddingTop={4}>
              <Panel>
                <Panel className="navbar__mobile__kb__content__langsBox">
                  {Object.values(ELang).map(lng => (
                    <div
                      key={lng}
                      className={`navbar__mobile__kb__content__lang ${lang === lng ? 'current' : ''}`}
                      onClick={() => changeLang(lng)}
                      role="none"
                    >
                      {lng.toUpperCase()}
                    </div>
                  ))}
                </Panel>
              </Panel>
              <Panel>
                <img src={CloseMenu} alt="menu" onClick={isMobileMenu} role="none" />
              </Panel>
            </Panel>
          </Container>

          <Hr />

          <Panel className="navbar__mobile__kb__content">
            <Container>
              <Panel className="navbar__mobile__kb__content__usertype" flexDirection={0}>
                <a href={translate('headerMenu.userTypeKb')[0].path} className="individual">
                  {translate('headerMenu.userTypeKb')[0].label}
                </a>
                <a href={translate('headerMenu.userTypeKb')[1].path} className="biznes">
                  {translate('headerMenu.userTypeKb')[1].label}
                </a>
              </Panel>
              <Panel flexDirection={0} justifyContent={4}>
                <ul className="navbar__mobile__kb__content__category">
                  {translate('headerMenu.Kb').map(({ id, title, children }: any, i: number) => (
                    <li
                      key={`${i}kbmenumobile`}
                      className="navbar__mobile__kb__content__category-item"
                      style={children ? undefined : marginLeft}
                      onClick={() => handleToggleClick(id)}
                      role="none"
                    >
                      <p className="navbar__mobile__kb__content__category-item__link">
                        {children ? <img src={toggle === children.id ? MinusRed : Plus} alt="open" /> : null}
                        <span> {title} </span>
                      </p>
                      {toggle === children?.id ? <SubCategoryMobile {...{ children }} /> : null}
                    </li>
                  ))}
                </ul>
              </Panel>
            </Container>
          </Panel>
        </Panel>
      ) : null}
    </Panel>
  );
};

export default MobileNav;
