import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import { API } from 'shared/api/loan';
import HeadingText from 'shared/components/HeadingText';
import ButtonCommon from 'shared/components/ButtonCommon';
import Container from 'shared/components/Container';
import ProgressBar from 'shared/components/ProgressBar';
import { useLoading } from 'shared/context/loading';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ELang, EProductCode } from 'shared/enum';
import { translate } from 'shared/utils/translation';
import { IDebtAmount, IGiftCardInfo, IOrderDetails } from 'shared/model';

import Months from './Months';
import DebtInfo from './DebtInfo';
import AmountInfo from './AmountInfo';
import ScoringInput from './ScoringInput';
import InsuranceProducts from '../Insurance/InsuranceProducts';
import GiftCard from './GiftCard';

import './index.scss';

interface IProps {
  scoringData?: any;
}

interface IItem {
  code: string;
}

const ScoringMulti: FC<IProps> = ({ scoringData }) => {
  const { channelType, rest, processId, productCode } = useGeneralInfoContext();
  const { lang } = useGeneralInfoContext();
  const { orderOffers, customerDebts } = scoringData.calculator;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const maxAvailableMonth = Object.keys(orderOffers)[Object.keys(orderOffers).length - 1] * 1 || 12;

  const { setLoading } = useLoading();
  const [minAmount, setMinAmount] = useState<number>(0);
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [duration, setDuration] = useState(maxAvailableMonth);
  const [periods, setPeriods] = useState(orderOffers[duration]); // set initial duration
  const [loanMonthMaxFirstAmount, setLoanMonthMaxFirstAmount] = useState(0);
  const [percent, setPercent] = useState(22);
  const [unAdjustedInterestRate, setUnAdjustedInterestRate] = useState(0);
  const [bankGuaranteeCommissionRate, setBankGuaranteeCommissionRate] = useState(0);
  const [cashCommissionRate, setCashCommissionRate] = useState(0);
  const [cashCreditServiceCommissionRate, setCashCreditServiceCommissionRate] = useState(0);
  const [bankGuaranteeCommissionAmount, setBankGuaranteeCommissionAmount] = useState(0);
  const [cashCommissionAmount, setCashCommissionAmount] = useState(0);
  const [cashCreditServiceCommissionAmount, setCashCreditServiceCommissionAmount] = useState(0);
  const [availableFinalAmount, setAvailableFinalAmount] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [refinancingAmount, setRefinancingAmount] = useState(0);
  const [debtor, setDebtor] = useState<boolean>(false);
  const [bluePeriod, setBluePeriod] = useState<boolean>(false);

  const [selectedPeriodData, setSelectedPeriodData] = useState<Record<string, any> | null>(null);
  const [selectedOfferId, setOfferId] = useState('');
  const [giftCardAvailable, setGiftCardAvailable] = useState<boolean>(false);
  const [giftState, setGiftState] = useState<boolean>(true);
  const [clientGiftState, setClientGiftState] = useState<boolean>(true);
  const [giftCardInfo, setGiftCardInfo] = useState<IGiftCardInfo | undefined>();

  const roundTo = (value: number, digits = 2) => {
    return Number(value.toFixed(digits));
  };

  const getResult = (loanAmount: number, loanDuration: number, loanPercent: number) => {
    if (loanAmount === 0) return 0;
    if (loanDuration === 0) return 0;

    const result =
      loanPercent > 0
        ? amount / (((1 + loanPercent / 1200) ** loanDuration - 1) / (1 + loanPercent / 1200) ** loanDuration / (loanPercent / 1200))
        : amount / loanDuration;
    return roundTo(result);
  };

  const calculateData = () => {
    if (amount >= minAmount && amount <= maxAmount) {
      setBankGuaranteeCommissionAmount((bankGuaranteeCommissionRate / 100) * amount);
      setCashCommissionAmount((cashCommissionRate / 100) * amount);
      setCashCreditServiceCommissionAmount((cashCreditServiceCommissionRate / 100) * amount);
      setAvailableFinalAmount(
        roundTo(amount - refinancingAmount - cashCommissionAmount - bankGuaranteeCommissionAmount - cashCreditServiceCommissionAmount)
      );
      const result = getResult(amount, duration, percent);
      setMonthlyPayment(result);
    }
  };

  const debtCalc = () => {
    return {
      refinancingAmount,
      productDebtAmount: customerDebts?.cash,
      advanceDebt: { value: customerDebts?.avans, currency: 'AZN' },
    };
  };

  useEffect(() => {
    rest.get<IGiftCardInfo>(API.loan.getGiftCardDetails()).then(({ data }) => {
      setGiftCardInfo(data);
    });
  }, []);

  useEffect(() => {
    let periodData;
    if (periods.length > 1) {
      setSelectedPeriodData(periods[1]);
      // eslint-disable-next-line prefer-destructuring
      periodData = periods[1];
      setLoanMonthMaxFirstAmount(periods[0].maxAmount);
    } else {
      setLoanMonthMaxFirstAmount(0);
      // eslint-disable-next-line prefer-destructuring
      periodData = periods[0];
      setSelectedPeriodData(periods[0]);
    }
    if (periodData) {
      setMinAmount(periodData.minAmount);
      setMaxAmount(periodData.maxAmount);
      setPercent(periodData.interestRate);
      setUnAdjustedInterestRate(periodData.unAdjustedInterestRate);
      setAmount(periodData.maxAmount);
      setOfferId(periodData.id);
      setRefinancingAmount(periodData.refinancingAmount);
    }
    calculateData();
  }, [periods]);

  useEffect(() => {
    setDebtor(customerDebts?.avans !== 0 || refinancingAmount !== 0);
    if (loanMonthMaxFirstAmount) {
      if (amount <= loanMonthMaxFirstAmount) {
        setSelectedPeriodData(periods[0]);
      } else {
        setSelectedPeriodData(periods[1]);
      }
    }
    if (selectedPeriodData) {
      setBankGuaranteeCommissionRate(selectedPeriodData.bankGuaranteeCommissionRate);
      setCashCommissionRate(selectedPeriodData.cashCommissionRate);
      setCashCreditServiceCommissionRate(selectedPeriodData.cashCreditServiceCommissionRate);
      setPercent(selectedPeriodData.interestRate);
      setUnAdjustedInterestRate(selectedPeriodData.unAdjustedInterestRate);
      setOfferId(selectedPeriodData.id);
      setGiftCardAvailable(selectedPeriodData.giftCardAvailable);
    }
    // if (refinancingAmount > 0) setHasRefinancingAmount(true);
    calculateData();
  }, [amount, selectedPeriodData]);

  // Used to keep track of user decision. If user disables the gift option (giftState) do not update it to true anymore.
  useEffect(() => {
    if (clientGiftState) {
      setGiftState(giftCardAvailable);
    }
  }, [giftCardAvailable]);

  // calculate data based on commission changes. State is async, and we need to update to the latest rates and calculate the results.
  useEffect(() => {
    calculateData();
  }, [
    bankGuaranteeCommissionAmount,
    cashCommissionAmount,
    cashCreditServiceCommissionAmount,
    bankGuaranteeCommissionRate,
    cashCreditServiceCommissionRate,
    cashCommissionRate,
  ]);

  // Load initial data
  useEffect(() => {
    if (scoringData.crossSells) {
      scoringData.crossSells.map(({ code }: IItem) => setSelectedList(selectedList => [...selectedList, code]));
    }
  }, [scoringData.crossSells]);

  const debtAmount: IDebtAmount = useMemo(() => {
    return debtCalc();
  }, [refinancingAmount]);

  const crossSellInsuranceTitle = useMemo(() => {
    return productCode !== EProductCode.GTKR ? (
      <p>
        {translate('scoring.insuranceProducts')} <span> *{translate('scoring.infoServiceFee')} </span>
      </p>
    ) : (
      <p>
        {translate('scoring.insuranceProducts')} <span> *{translate('scoring.infoServiceFeeCash')} </span>
      </p>
    );
  }, [productCode]);

  const onSubmit = () => {
    setLoading(true); // This loading waits socket event -> OrderFormPage: CUSTOMER_INFO
    const formData = {
      minAmount,
      maxAmount,
      selectedAmount: amount,
      period: duration,
      interestRate: percent,
      bankGuaranteeCommissionRate,
      cashCommissionRate,
      cashCreditServiceCommissionRate,
      selectedOfferId,
      crossSellCodes: selectedList,
      processId,
      giftCardSelected: giftState,
      monthlyPayment,
    };
    rest.post<IOrderDetails, void>(API.loan.sendOrderDetail, formData).catch(() => {
      setLoading(false);
    });
  };

  return (
    <Panel className="order-step-container">
      <Panel paddingTop={5}>
        <ProgressBar progress={45} channelType={channelType} />
      </Panel>
      <Panel className="order-step-container__box">
        <Container>
          <Panel className="multi-scoring">
            <HeadingText
              text={
                <span>
                  <span className={lang === ELang.EN ? `dangerColor-${channelType}` : ''}>{translate('titles.calculator.firstPart')} </span>
                  <span className={lang !== ELang.EN ? `dangerColor-${channelType}` : ''}>{translate('titles.calculator.secondPart')}</span>
                </span>
              }
            />
            <Panel marginTop={8}>
              <Row>
                <Col md={7} sm={12}>
                  <Panel className="scoring-input-component">
                    <ScoringInput
                      amount={amount}
                      minAmount={minAmount}
                      maxAmount={maxAmount}
                      loanMonthMaxFirstAmount={loanMonthMaxFirstAmount}
                      setBluePeriod={setBluePeriod}
                      setAmount={setAmount}
                    />
                  </Panel>
                  <Panel className="scoring-months">
                    <Months {...{ channelType, duration, scoringData, setDuration, setPeriods }} />
                  </Panel>
                  {scoringData.crossSells && (
                    <Panel className="cross-sell-products-wrapper">
                      <InsuranceProducts
                        titleForDesktop={<div className="scoring-input-box__label">{crossSellInsuranceTitle}</div>}
                        titleForMobile={
                          <div className="scoring-input-box__label">
                            <p>
                              {translate('scoring.insuranceProducts')} <span> *{translate('scoring.infoServiceFee')} </span>
                            </p>
                          </div>
                        }
                        crossSellList={scoringData.crossSells}
                        selectedList={selectedList}
                        setSelectedList={setSelectedList}
                      />
                    </Panel>
                  )}
                  {giftCardAvailable && giftCardInfo && (
                    <GiftCard giftInfo={giftCardInfo} giftState={giftState} setGiftState={setGiftState} setClientGiftState={setClientGiftState} />
                  )}
                </Col>
                <Col md={5} sm={12} className="multi-scoring__debtinfo-box web">
                  {debtor && (
                    <Panel>
                      <DebtInfo forCard={false} amount={debtAmount} />
                    </Panel>
                  )}
                  <AmountInfo
                    percent={percent}
                    unAdjustedInterestRate={unAdjustedInterestRate}
                    maxAmount={maxAmount}
                    bankGuaranteeCommissionRate={bankGuaranteeCommissionRate}
                    cashCommissionRate={cashCommissionRate}
                    availableFinalAmount={availableFinalAmount}
                    amount={amount}
                    minAmount={minAmount}
                    monthlyPayment={monthlyPayment}
                    bluePeriod={bluePeriod}
                    debtor={debtor}
                    creditServiceCommission={cashCreditServiceCommissionRate}
                  />
                  <Panel>
                    <ButtonCommon
                      channelType={channelType}
                      className="btn__ammount__info"
                      text={translate('common.actions.order')}
                      handleClick={() => {
                        onSubmit();
                      }}
                    />
                  </Panel>
                </Col>
              </Row>
            </Panel>
          </Panel>
        </Container>
      </Panel>
    </Panel>
  );
};

export default ScoringMulti;
