import { ELogLevel, EProcessENV } from 'shared/enum';
import { AxiosError, AxiosResponse } from 'axios';

const isProdEnv = process.env.APP_ENV === EProcessENV.PROD;

export const logger = (message: string | AxiosError | AxiosResponse, level: ELogLevel = ELogLevel.INFO) => {
  switch (level) {
    case ELogLevel.ERROR:
      // eslint-disable-next-line no-console
      console.error(message);
      break;
    case ELogLevel.WARN:
      if (!isProdEnv) {
        // eslint-disable-next-line no-console
        console.warn(message);
      }
      break;
    case ELogLevel.INFO:
      if (!isProdEnv) {
        // eslint-disable-next-line no-console
        console.info(message);
      }
      break;
    default:
      break;
  }
};
