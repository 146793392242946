import React, { FC, useEffect, useState, useMemo } from 'react';
import { Col, Input, Panel, Row } from '@kapital-bank/kb-ui-design-system';
import { useForm } from 'react-hook-form';
import RSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Pencil from 'shared/assets/images/compressImage/pencil.svg';
import { API } from 'shared/api/loan';
import { ESalesChannel } from 'shared/enum';
import HeadingText from 'shared/components/HeadingText';
import ProgressBar from 'shared/components/ProgressBar';
import ButtonCommon from 'shared/components/ButtonCommon';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import Container from 'shared/components/Container';
import { translate } from 'shared/utils/translation';
import { IDebtAmount, IOrderDetails } from 'shared/model';
import { useLoading } from 'shared/context/loading';

import DebtInfo from '../CreditCalculator/DebtInfo';
import InsuranceProducts from '../Insurance/InsuranceProducts';

import './index.scss';

interface IProps {
  scoringData?: any;
}

interface IItem {
  code: string;
}

const ScoringCard: FC<IProps> = ({ scoringData }) => {
  const { customerDebts, minAmount = 0, maxAmount = 0, duration = 0, percent = 0, refinancingAmount = 0 } = scoringData.calculator;
  const { channelType, rest, productCode, processId } = useGeneralInfoContext();
  const { setLoading } = useLoading();
  // eslint-disable-next-line no-nested-ternary
  const rcSliderColor =
    channelType === ESalesChannel.BIRBANK_AZ
      ? '#CE0F1D'
      : channelType === ESalesChannel.KAPITAL_AZ
      ? '#E25F6C'
      : channelType === ESalesChannel.UMICO
      ? '#7C62E3'
      : '#CE0F1D';

  const [amount, setAmount] = useState<number>(maxAmount);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const monthlyPercent = percent / 12;
  const roundMonthlyPercent = monthlyPercent.toFixed(2);
  const debtor = customerDebts?.avans !== 0 || refinancingAmount !== 0;
  const SLIDER_INFO = {
    linearGradientColor: '#CE0F1D',
  };
  if (channelType === ESalesChannel.KAPITAL_AZ) SLIDER_INFO.linearGradientColor = '#E25F6C';
  else if (channelType === ESalesChannel.UMICO) SLIDER_INFO.linearGradientColor = '#7C62E3';

  const debtCalc = () => {
    return {
      refinancingAmount,
      productDebtAmount: customerDebts?.birkart,
      advanceDebt: { value: customerDebts?.avans, currency: 'AZN' },
    };
  };

  // Load initial data
  useEffect(() => {
    if (scoringData.crossSells) {
      // TODO: Remove after card release pashabox
      scoringData.crossSells
        .filter(({ code }: IItem) => code !== 'CS3')
        .map(({ code }: IItem) => setSelectedList(selectedList => [...selectedList, code]));
    }
  }, [scoringData.crossSells]);

  const debtAmount: IDebtAmount = useMemo(() => {
    return debtCalc();
  }, [scoringData.calculator.refinancingAmount]);

  const getSelectedAmount = (amountSelected: string) => {
    if (!Number.isNaN(amountSelected) || amountSelected === '') {
      if (amountSelected.length > 5) {
        setAmount(parseInt(amount.toString().replace(/\D/g, ''), 10));
      } else if (amountSelected) {
        setAmount(parseInt(amountSelected.replace(/\D/g, ''), 10));
      } else {
        setAmount(0);
      }
    }
  };

  const { handleSubmit, watch } = useForm<any>({
    defaultValues: {
      amount,
    },
  });

  const onSubmit = () => {
    setLoading(true); // This loading waits socket event -> OrderFormPage: CUSTOMER_INFO
    const formResult = watch();

    const formData = {
      minAmount,
      maxAmount,
      crossSellCodes: selectedList,
      ...formResult,
      selectedAmount: amount,
      processId,
    };

    rest.post<IOrderDetails, void>(API.loan.sendOrderDetail, formData).catch(() => {
      setLoading(false);
    });
  };
  const [inputFocusScoring, setInputFocusScoring] = useState(false);
  const inputScoringRef = React.createRef();
  const focusScoringInput = () => {
    setInputFocusScoring(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputScoringRef.current?.focus();
  };

  const amountInputChange = (amountValue: string) => {
    setInputFocusScoring(false);
    if (amountValue) {
      const tmpAmount: number = parseInt(amountValue, 10);
      if (tmpAmount > maxAmount) {
        setAmount(maxAmount);
      } else if (tmpAmount < minAmount) {
        setAmount(minAmount);
      }
    }
  };
  const creditInfo = (
    <Panel>
      <Panel className="scoring__card__credit__description" marginBottom={3} key={0}>
        <Panel className="scoring__card__credit__description__title">
          {translate('common.credit.term')} ({translate('common.month')?.toUpperCase()})
        </Panel>
        <Panel className="scoring__card__credit__description__context"> {duration} </Panel>
      </Panel>
      <Panel className="scoring__card__credit__description" marginBottom={3} key={1}>
        <Panel className="scoring__card__credit__description__title"> {translate('common.monthlyInterestRate')}</Panel>
        <Panel className="scoring__card__credit__description__context"> {roundMonthlyPercent} %</Panel>
      </Panel>
    </Panel>
  );

  return (
    <Panel className="scoring__card order-step-container">
      <Panel paddingTop={5}>
        <ProgressBar progress={45} channelType={channelType} />
      </Panel>
      <Panel className="order-step-container__box">
        <Container>
          <Panel className={`${debtor ? 'multi-scoring' : 'card-scoring'}`}>
            <HeadingText
              text={
                <span>
                  {translate('titles.scoring.firstPart')}
                  <span className={`dangerColor-${channelType}`}>{translate('titles.scoring.secondPart')}</span>
                </span>
              }
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={debtor ? 7 : 12} sm={12} className="scoring__card__credit">
                  <Panel className="scoring__card__credit__slider__box">
                    <div className="scoring-input-box__label">
                      <span> {translate('common.credit.amount')} (AZN)</span>
                      <span> {translate('common.info.reduceAmount')} </span>
                    </div>
                    <span className="scoring__card__credit__slider__box__input__box">
                      <Input
                        className="scoring__card__credit__slider__box__input"
                        value={amount.toString()}
                        onChange={getSelectedAmount}
                        ref={inputScoringRef}
                        onBlur={value => amountInputChange(value)}
                      />
                      {!inputFocusScoring && <img src={Pencil} alt="pencil" onClick={focusScoringInput} role="none" />}
                    </span>
                    <RSlider
                      value={amount}
                      style={{ marginTop: '-9px', marginBottom: 30, width: '98%' }}
                      min={minAmount}
                      max={maxAmount}
                      defaultValue={amount}
                      step={10}
                      trackStyle={{ backgroundColor: rcSliderColor, height: 4 }}
                      handleStyle={{
                        borderColor: rcSliderColor,
                        height: 18,
                        width: 18,
                        marginLeft: 0,
                        marginTop: -8,
                        backgroundColor: 'white',
                        opacity: 1,
                      }}
                      railStyle={{ backgroundColor: '#FFFFFF', height: 4, borderRadius: 20 }}
                      onChange={val => getSelectedAmount(val.toString())}
                    />
                    <Panel justifyContent={4} alignItems={2} marginBottom={3} className="scoring__card__credit__slider__box__amounts">
                      <span>{minAmount}</span>
                      <span>{maxAmount}</span>
                    </Panel>
                  </Panel>
                  {creditInfo}
                  {scoringData.crossSells && (
                    // TODO: Remove after card release pashabox
                    <InsuranceProducts
                      titleForDesktop={
                        <div className="scoring-input-box__label">
                          <span> {translate('scoring.insuranceProducts')} </span>
                          <span> *{translate('scoring.infoServiceFee')} </span>
                        </div>
                      }
                      titleForMobile={
                        <div className="scoring-input-box__label">
                          <div> {translate('scoring.insuranceProducts')}</div>
                          <div> *{translate('scoring.infoServiceFee')}</div>
                        </div>
                      }
                      crossSellList={scoringData.crossSells?.filter(({ code }: IItem) => code !== 'CS3')}
                      selectedList={selectedList}
                      setSelectedList={setSelectedList}
                    />
                  )}
                </Col>
                {debtor && (
                  <Col md={5} sm={12} className="scoring__card__debt">
                    <Panel>
                      <DebtInfo forCard amount={debtAmount} />
                    </Panel>
                  </Col>
                )}
              </Row>
              <Panel marginTop={4} marginBottom={4}>
                <ButtonCommon
                  channelType={channelType}
                  className={`scoring__card__credit__btn ${debtor && 'scoring__card__credit__btn__change-width'}`}
                  text={translate('common.actions.order')}
                />
              </Panel>
            </form>
          </Panel>
        </Container>
      </Panel>
    </Panel>
  );
};

export default ScoringCard;
