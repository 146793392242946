import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import { Divider } from '@kbfront/kb-ui';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import { BaseSizes, Switch } from '@kb-front/ui-kit';
import { IGiftCardInfo } from 'shared/model';
import { translate } from 'shared/utils/translation';
import { ELang } from 'shared/enum';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import GiftCardModal from './GiftCardModal';

import './index.scss';

interface IProps {
  giftInfo: IGiftCardInfo;
  giftState: boolean;
  setGiftState: Dispatch<SetStateAction<boolean>>;
  setClientGiftState: Dispatch<SetStateAction<boolean>>;
}

const GiftCard: FC<IProps> = ({ giftInfo, giftState, setGiftState, setClientGiftState }) => {
  const CardImage = '/assets/images/calculatorGiftOffer/kart.png';
  const [openGiftInfoModal, setGiftInfoModal] = useState(false);
  const { lang } = useGeneralInfoContext();
  const [giftInfoModalData, setModalData] = useState<IGiftCardInfo>({
    creditLimit: giftInfo.creditLimit,
    title: giftInfo.title,
    generalInformation: giftInfo.generalInformation,
    gracePeriod: giftInfo.gracePeriod,
    partners: giftInfo.partners,
    serviceFee: giftInfo.serviceFee,
    bonus: giftInfo.bonus,
  });

  const handleCloseModal = () => {
    setGiftInfoModal(!openGiftInfoModal);
  };

  const openModal = () => {
    setGiftInfoModal(!openGiftInfoModal);
    setModalData({
      ...giftInfoModalData,
    });
  };

  return (
    <Panel className="scoring__gift__card-wrapper">
      <Divider height={1} />
      <Panel className="scoring__gift__card">
        <Panel className={`scoring__gift__card__item ${!giftState && 'deactivated'}`} flexDirection={0} justifyContent={4} marginTop={2}>
          <div className={`scoring__gift__card__item__header ${ELang.RU === lang && 'lang_ru'}`}>
            <span className="scoring__gift__card__item__header__text">{translate('common.gift')}</span>
          </div>
          <div className="scoring__gift__card__item__content">
            <div className="scoring__gift__card__item__content__text">
              <h5>{giftInfo.title}</h5>
            </div>
            <img alt="2 times edv" src={CardImage} />
          </div>
          <img src={InfoSolid} alt="info" onClick={() => openModal()} role="none" className="scoring__gift__card__item__info_icon" />
        </Panel>
        <Panel className="scoring__gift__card__switcher">
          <Switch
            value={giftState}
            onChange={value => {
              setGiftState(value);
              setClientGiftState(value);
            }}
            label={translate('scoring.wantGetAdvantageOfOffer')}
            size={BaseSizes.S}
          />
        </Panel>
        <GiftCardModal openModal={openGiftInfoModal} handleCloseModal={handleCloseModal} giftInfoModalData={giftInfoModalData} />
      </Panel>
    </Panel>
  );
};

export default GiftCard;
