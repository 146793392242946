import React, { FC, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, Col, EElementSize, EElementView, Panel, Row } from '@kapital-bank/kb-ui-design-system';
import { logger } from 'shared/utils/logger';
import { ELogLevel } from 'shared/enum';
import { StateActionDispatcher, ITransferReq } from 'shared/model';
import { getErrorCodeTranslation } from 'shared/api/rest';
import ButtonCommon from 'shared/components/ButtonCommon';
import { styleModal } from 'shared/assets/styles/react-modal';
import { translate } from 'shared/utils/translation';
import { useLoading } from 'shared/context/loading';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { API } from 'shared/api/loan';
import OTPModal from '../OTPModal';

import './index.scss';

interface IProps {
  openAgreementModal: boolean;
  setAgreementModal: StateActionDispatcher<boolean>;
  handleCloseAgreementModal?: () => void;
  setResponseError: StateActionDispatcher<string>;
  cardNumber?: string;
  branchId?: string;
}

const AgreementModal: FC<IProps> = ({ openAgreementModal, setAgreementModal, setResponseError, handleCloseAgreementModal, cardNumber, branchId }) => {
  const { rest, processId, channelType } = useGeneralInfoContext();
  const [openOTPModal, setOTPModal] = useState(false);
  const { setLoading } = useLoading();

  const handleOTPModal = () => {
    setOTPModal(!openOTPModal);
  };

  const sendCardNumber = () => {
    const transferData = {
      processId,
      transferOption: 'PAN',
      transferPan: cardNumber?.split(' ').join(''),
      branchId: branchId || undefined,
    };
    setLoading(true);
    rest
      .post<ITransferReq, void>(API.transfer.cashTransfer, transferData, undefined, { skipGlobalError: true })
      .then(() => {
        setOTPModal(!openOTPModal);
        setAgreementModal(false);
      })
      .catch(error => {
        logger(error, ELogLevel.ERROR);
        if (error.response.data?.errors?.length) {
          setResponseError(getErrorCodeTranslation(error.response.data.errors));
          setAgreementModal(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Panel>
      <ReactModal
        style={styleModal}
        isOpen={openAgreementModal}
        onRequestClose={handleCloseAgreementModal}
        shouldCloseOnOverlayClick={false}
        className="modal agreement-modal"
      >
        <Panel className="modal__header" justifyContent={4} alignItems={2}>
          <span className="modal__header__title">{translate('common.areYouSure')}</span>
          <img onClick={handleCloseAgreementModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
        </Panel>
        <Panel className="modal-line" />

        <Panel className="modal-common-context">
          <Panel marginBottom={2} paddingTop={2} className="agreement-modal__context">
            <Panel className="agreement-modal__context__description">{translate('formalization.popup.amountCreditedToCard')}</Panel>
            <Panel className="agreement-modal__context__card__title" marginBottom={2}>
              {translate('common.cardNumber')}
            </Panel>
            <Panel className="agreement-modal__context__card__number" marginBottom={5}>
              {cardNumber}
            </Panel>
          </Panel>

          <Row dataTestId="row">
            <Col md={6} sm={6} xs={6} className="">
              <Button onClick={() => setAgreementModal(false)} size={EElementSize.LG} view={EElementView.SECONDARY} className="modal-exit">
                {translate('common.no')}
              </Button>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <ButtonCommon
                channelType={channelType}
                className="agreement__approve"
                text={translate('common.yes')}
                handleClick={() => {
                  sendCardNumber();
                }}
              />
            </Col>
          </Row>
        </Panel>
      </ReactModal>
      <OTPModal openOTPModal={openOTPModal} handleOTPModal={handleOTPModal} />
    </Panel>
  );
};

export default AgreementModal;
