import React, { FC, useState } from 'react';
import { Panel, Hr } from '@kapital-bank/kb-ui-design-system';

import Container from 'shared/components/Container';
import BirbankMenu from 'shared/assets/images/compressImage/birbank-formenu.svg';
import KbLogo from 'shared/assets/images/compressImage/kb-logo.svg';
import MenuBtn from 'shared/assets/images/compressImage/menu-btn.svg';

import MobileNav from '../index';

import './index.scss';

const NavbarBrendMobile: FC = () => {
  const [active, setActive] = useState(false);

  const isMobileMenu = () => {
    setActive(!active);
  };

  return (
    <Panel className="navbar__brend__mobile__kb">
      <Container>
        <Panel flexDirection={0} justifyContent={4} marginBottom={4}>
          <Panel className="navbar__brend__mobile__kb__logo">
            <img src={KbLogo} alt="Logo" />
          </Panel>
          <Panel>
            <img src={BirbankMenu} alt="Logo" className="navbar__brend__mobile__kb-item" />
            <img src={MenuBtn} alt="menu" className="navbar__brend__mobile__kb-item" onClick={isMobileMenu} role="none" />
          </Panel>
        </Panel>
      </Container>
      <Hr />
      <MobileNav {...{ active, isMobileMenu }} />
    </Panel>
  );
};

export default NavbarBrendMobile;
