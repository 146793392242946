import React from 'react';
import { CircularProgressbar, EElementView, Panel } from '@kapital-bank/kb-ui-design-system';
import Container from 'shared/components/Container';
import SandGlass from 'shared/assets/images/compressImage/sand-glass.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { isFluidChannel } from 'shared/utils/helpers';
import { translate } from 'shared/utils/translation';

import './index.scss';

const StepLoading = () => {
  const { channelType } = useGeneralInfoContext();

  return (
    <Container className={`step-loading-content content-bgcolor-${channelType}`} fluid={isFluidChannel(channelType)}>
      <div className="step-loading-content__text">{translate('common.info.pleaseWait')}</div>
      <Panel className="step-loading-content__loading">
        <CircularProgressbar
          infinite
          message={<img src={SandGlass} alt="sand-glass" />}
          percentage={70}
          sqSize={120}
          strokeWidth={10}
          view={EElementView.DANGER}
        />
      </Panel>
    </Container>
  );
};

export default StepLoading;
