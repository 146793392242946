import React, { useState, FC } from 'react';
import { Panel, Button, EElementView } from '@kapital-bank/kb-ui-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import EnterIcon from 'shared/assets/images/compressImage/enter-icon.svg';
import { Sanitize } from 'shared/utils/helpers';

import { translate } from 'shared/utils/translation';
import { redirect } from 'shared/utils/redirect';

import './index.scss';

const MobileNavLink: FC = () => {
  const [cards, setCards] = useState(false);
  const params = window.location.search;

  return (
    <nav className="mobile-nav">
      <Panel className="mobile-nav__content">
        <Panel className="mobile-nav__content__dropdown" onClick={() => setCards(!cards)}>
          <div>{translate('headerMenu.Bb.title')}</div>
          <FontAwesomeIcon icon={cards ? faAngleDown : faAngleRight} />
        </Panel>
        {cards && (
          <Panel className="mobile-nav__content__dropdown__cards">
            {translate('headerMenu.Bb.cards').map(({ label, href }: any, i: number) => (
              <a key={`${i}bbmobilecards`} href={Sanitize(href)}>
                {label}
              </a>
            ))}
          </Panel>
        )}
        <Panel className="mobile-nav__content__links">
          {translate('headerMenu.Bb.links').map(
            ({ label, href }: any, i: number) =>
              label !== 'Kartlar' && (
                <a key={`${i}bbmobilelinks`} href={Sanitize(href).includes('ccl') ? `${Sanitize(href)}${Sanitize(params)}` : Sanitize(href)}>
                  {label}
                </a>
              )
          )}
        </Panel>
      </Panel>
      <Panel className="mobile-nav__btn-group" justifyContent={4} alignItems={2}>
        <Button onClick={() => redirect('https://birbank.az/login')} view={EElementView.PRIMARY}>
          <img src={EnterIcon} alt="icon" />
          {translate('headerMenu.Bb.entry')}
        </Button>
      </Panel>
    </nav>
  );
};
export default MobileNavLink;
