import React, { useContext } from 'react';
import { IGeneralInfo } from './models';

/** Context to provide general info. */
export const GeneralInfoContext = React.createContext<IGeneralInfo>({} as IGeneralInfo);

/** General info context react hook. */
export const useGeneralInfoContext = () => useContext(GeneralInfoContext);

export default GeneralInfoContext;
