import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { baseApiUrl, PROCESS_ID } from 'shared/consts';
import { ELogLevel, EProductStatus, ESocketEvent } from 'shared/enum';
import { logger } from 'shared/utils/logger';
import io, { Socket } from 'socket.io-client';

interface IProps {
  setProcessStep: Dispatch<SetStateAction<ESocketEvent | undefined>>;
  setProcessId: Dispatch<SetStateAction<string | null>>;
  processId: string | null;
}

export const useSocketState = ({ setProcessStep, setProcessId, processId }: IProps) => {
  const [socket, setSocket] = useState<Socket>();
  const [socketConnected, setSocketConnected] = useState<boolean>(false);

  useEffect(() => {
    if (processId) {
      setSocket(
        io(baseApiUrl, {
          reconnectionDelayMax: 10000,
          path: '/ws/v1/onboarding',
          transports: ['websocket'],
          query: { processId, client: 'web' },
        })
      );
    } else if (socket && socketConnected) {
      socket.disconnect();
    }
  }, [processId]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        logger(`<<<<<<<<<<< Socket was connected >>>>>>>>>>>`, ELogLevel.INFO);
        setSocketConnected(true);
      });

      socket.on('disconnect', () => {
        logger(`<<<<<<<<<<< Socket was disconnected >>>>>>>>>>>`, ELogLevel.INFO);
        setSocketConnected(false);
      });

      socket.on('LAST_EVENT', payload => {
        if (Object.values(ESocketEvent).includes(payload.event)) {
          logger(`setProcessStep: ${payload.event}`, ELogLevel.INFO);
          setProcessStep(payload.event);
        }
      });

      socket.onAny((eventName, payload) => {
        socket.emit('ack', payload);
        logger(`SOCKET BODY RESPONSE: ${eventName} ${JSON.stringify(payload)}`, ELogLevel.INFO); // processId,event,value
        if (Object.values(ESocketEvent).includes(eventName)) {
          logger(`setProcessStep: ${eventName}`, ELogLevel.INFO);
          if (eventName === EProductStatus.REDIRECTION) {
            // update the processId with old processId
            localStorage.setItem(PROCESS_ID, payload.value);
            setProcessId(payload.value);
          }
          setProcessStep(payload.event);
        }
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
        socket.off('connect');
        socket.off('disconnect');
        socket.offAny();
      }
    };
  }, [socket]);
};
