import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';
import { styleModal } from 'shared/assets/styles/react-modal';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import infoKB from 'shared/assets/images/compressImage/info-kb.svg';
import infoBB from 'shared/assets/images/compressImage/info-bb.svg';
import infoUM from 'shared/assets/images/compressImage/info-um.svg';
import { ESalesChannel } from 'shared/enum';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { IGiftCardInfo } from 'shared/model';
import { translate } from 'shared/utils/translation';

import './index.scss';

interface IProps {
  openModal: boolean;
  handleCloseModal: () => void;
  giftInfoModalData: IGiftCardInfo;
}

const GiftCardModal: FC<IProps> = ({ openModal, handleCloseModal, giftInfoModalData }) => {
  const { channelType } = useGeneralInfoContext();
  return (
    <ReactModal
      style={styleModal}
      isOpen={openModal}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      className="modal gift-modal"
    >
      <>
        <Panel>
          <Panel className="modal__header" justifyContent={4} alignItems={2}>
            <p className="modal__header__title">{translate('scoring.giftMoreInfoModal.title')}</p>
            <img className="modal-close" onClick={handleCloseModal} src={CloseIcon} alt="close" role="none" />
          </Panel>
        </Panel>
        <Panel className="modal-line" />
        <Panel className="modal-common-context">
          <Panel className="gift-modal__context">
            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.generalInformation')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.generalInformation}</p>

            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.creditLimit')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.creditLimit}</p>

            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.serviceFee')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.serviceFee}</p>

            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.partners')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.partners}</p>

            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.gracePeriod')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.gracePeriod}</p>

            <Panel className="gift-modal__context__title">{translate('scoring.giftMoreInfoModal.bonus')}</Panel>
            <p className="gift-modal__context__description">{giftInfoModalData.bonus}</p>
          </Panel>
        </Panel>
      </>
    </ReactModal>
  );
};

export default GiftCardModal;
