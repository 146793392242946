import React, { CSSProperties, useEffect, useState } from 'react';
import { EElementSize, Panel, Tabs } from '@kapital-bank/kb-ui-design-system';
import { OptionProps } from 'react-select';
import HeadingText from 'shared/components/HeadingText';
import ProgressBar from 'shared/components/ProgressBar';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { EDeliveryTabType } from 'shared/enum';
import { IOptions } from 'shared/model';
import { translate } from 'shared/utils/translation';
import { useLoading } from 'shared/context/loading';

import Branch from './Branch';
import Currier from './Currier';

import './index.scss';

const Delivery = () => {
  const { channelType } = useGeneralInfoContext();
  const { setLoading } = useLoading();
  const [tabType, setTabType] = useState<string>(EDeliveryTabType.CURIER);

  const customStyle = {
    option: (provided: CSSProperties, props: OptionProps<IOptions, false>) => ({
      ...provided,
      color: (props.selectProps as any).color,
      backgroundColor: props.isSelected ? '#f8f8f8' : 'none',
      '&:hover': {
        backgroundColor: '#e7e8ea',
      },
    }),
  };

  useEffect(() => {
    setLoading(false); // Hide loading that was set in Formalization Option/Card
    window.scrollTo(0, 0);
  }, []);
  const setTabBranch = () => setTabType(EDeliveryTabType.BRANCH);

  return (
    <Panel className="bg-soft-grey order-step-container__box delivery__holder" justifyContent={2}>
      <Panel paddingTop={7} paddingBottom={6}>
        <ProgressBar progress={95} channelType={channelType} />
        <HeadingText text={translate('delivery.choose.title')} classDesc="delivery-tab__desc" classHeader="delivery-tab__header" />
        <Panel marginTop={5} className="delivery-tab">
          <Tabs className="tabs">
            <Tabs.Item
              key="0"
              block
              isActive={tabType === EDeliveryTabType.CURIER}
              itemId="0"
              size={EElementSize.SM}
              onClick={() => {
                setTabType(EDeliveryTabType.CURIER);
              }}
            >
              <span className="delivery-tab__type">{translate('delivery.courier')}</span>
            </Tabs.Item>
            <Tabs.Item key="1" block isActive={tabType === EDeliveryTabType.BRANCH} itemId="1" size={EElementSize.SM} onClick={setTabBranch}>
              <span className="delivery-tab__type">{translate('common.fromBranch')}</span>
            </Tabs.Item>
          </Tabs>
        </Panel>
        {tabType === EDeliveryTabType.CURIER ? (
          <div style={{ display: tabType === EDeliveryTabType.CURIER ? '' : 'none' }}>
            <Currier customStyle={customStyle} setTabType={setTabBranch} />
          </div>
        ) : (
          <div style={{ display: tabType !== EDeliveryTabType.CURIER ? '' : 'none' }}>
            <Branch customStyle={customStyle} />
          </div>
        )}
      </Panel>
    </Panel>
  );
};

export default Delivery;
