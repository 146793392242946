import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import './index.scss';

interface IProps {
  text?: string;
  className?: string;
}
const Copyright: FC<IProps> = ({ text, className }) => {
  return (
    <Panel>
      <p className={`footer__copyright ${className}`}>
        © {new Date().getFullYear()} {text}
      </p>
    </Panel>
  );
};
export default Copyright;
