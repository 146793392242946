export enum ESalesChannel {
  KAPITAL_AZ = 'kb',
  BIRBANK_AZ = 'bb',
  UMICO = 'um',
  TERMINAL = 'terminal',
  _189 = '189',
}

export enum EDeliveryTabType {
  CURIER = 'curier',
  BRANCH = 'branch',
}

export enum ESocketEvent {
  PIN_PHONE = 'PIN_PHONE',
  OTP = 'OTP',
  CALCULATOR = 'CALCULATOR',
  REDIRECTION = 'REDIRECTION',
  CUSTOMER_INFO = 'CUSTOMER_INFO',
  DVS = 'DVS',
  DELIVERY = 'DELIVERY',
  TRANSFER_INFO = 'TRANSFER_INFO',
  TRANSFER = 'TRANSFER',
  ERROR = 'ERROR',
  LOADER = 'LOADER',
  END = 'END',
}

export enum EProductCode {
  GTKR = 'GTKR',
  BRCB_BLACK = 'BRCB_BLACK',
  BBUM = 'BBUM',
  BPUM = 'BPUM',
  BRML = 'BRML',
}

export enum EProductStatus {
  ERROR = 'ERROR',
  TELESALES_ERROR = 'TELESALES',
  END = 'END',
  IN_PROGRESS = 'IN_PROGRESS',
  REDIRECTION = 'REDIRECTION',
  COMPLETED = 'COMPLETED',
}

export enum ELang {
  AZ = 'az',
  EN = 'en',
  RU = 'ru',
}

export enum ELogLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum EProcessENV {
  DEV = 'development',
  PRE = 'preprod',
  PROD = 'production',
}

export enum EFinalPageType {
  SUCCESS = 'success',
  TECHERROR = 'techError',

  // provided by backend
  TELESALES = 'TELESALES',
  TELESALES_INCIDENT = 'TELESALES_INCIDENT',
  TELESALES_ERROR = 'TELESALES_ERROR',
  DEBIT_FLOW = 'DEBIT_FLOW',
}

export enum ESubPage {
  GTKR = 'Cash-Loan',
  BRCB_BLACK = 'Birbank-Cashback',
  BBUM = 'Birbank-Umico',
  BPUM = 'Birbank-Umico-Premium',
  BRML = 'Birbank-Miles',
}

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum EFeedbackType {
  TERRIBLE = 'TERRIBLE',
  BAD = 'BAD',
  NOT_BAD = 'NOT_BAD',
  GOOD = 'GOOD',
  AWESOME = 'AWESOME',
}

export enum ProductType {
  LOAN = 'LOAN',
}
