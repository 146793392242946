import React, { useEffect, useMemo, useState } from 'react';
import { Col, Panel, RadioButton, Row } from '@kapital-bank/kb-ui-design-system';
import InputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';

import Container from 'shared/components/Container';
import ProgressBar from 'shared/components/ProgressBar';
import ButtonCommon from 'shared/components/ButtonCommon';
import { luhnCheck, onSanitizeValue } from 'shared/utils/helpers';
import { useLoading } from 'shared/context/loading';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { translate } from 'shared/utils/translation';
import { API } from 'shared/api/loan';
import { IDetails, ITransferReq } from 'shared/model';
import { ESalesChannel } from 'shared/enum';
import GiftCardBranchSelector from 'shared/components/GiftCardBranchSelector';
import AgreementModal from './AgreementModal';

import './index.scss';

const FormalizationOption = () => {
  const { channelType, rest, processId } = useGeneralInfoContext();
  const { setLoading } = useLoading();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      number: '',
      branch: { value: '', label: '', id: '' },
    },
  });

  const watchField = watch();
  const [isCardOption, setIsCardOption] = useState(true);
  const [isBranchChecked, setIsBranchChecked] = useState(false);
  const [cardNumber, setCardNumber] = useState<string>();
  const [cardNumberTrimmed, setCardNumberTrimmed] = useState<string>('');
  const [openAgreementModal, setAgreementModal] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>('');
  const [hasGiftCard, setHasGiftCard] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (processId) {
      rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
        if (data.calculator?.giftCardSelected) {
          setHasGiftCard(data.calculator.giftCardSelected);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (responseError) {
      setResponseError('');
    }
    setCardNumberTrimmed(watchField.number.replace(/ /g, ''));
  }, [watchField.number]);

  const handleCloseAgreementModal = () => {
    setAgreementModal(!openAgreementModal);
  };

  const selectBranch = () => {
    setIsBranchChecked(true);
    setIsCardOption(false);
  };

  const buttonState = useMemo(() => {
    let active = false;
    // isCardOption true means card option selected
    if (hasGiftCard && isCardOption) {
      active = !watchField.number.includes('_') && watchField.number.length && watchField.branch.id && luhnCheck(cardNumberTrimmed);
    }
    if (hasGiftCard && !isCardOption) {
      active = !!watchField.branch.id;
    }
    if (!hasGiftCard) {
      if (isCardOption) {
        active = !watchField.number.includes('_') && watchField.number.length;
      } else {
        active = true;
      }
    }
    return active;
  }, [hasGiftCard, isCardOption, watchField]);

  const selectCard = () => {
    setIsBranchChecked(false);
    setIsCardOption(true);
  };

  const selectFormType = () => {
    setIsCardOption(!isCardOption);
    setIsBranchChecked(!isBranchChecked);
  };

  const onSubmit = (formData: Record<string, any>) => {
    // get from branch
    if (isBranchChecked) {
      // This loading waits socket event -> FormalizingCard: ESocketEvent.TRANSFER_INFO / DeliveryPage: ESocketEvent.DELIVERY
      setLoading(true);
      // eslint-disable-next-line no-param-reassign
      formData.transferOption = 'branch';
      const transferData = {
        processId,
        transferOption: 'BRANCH_ANY',
        branchId: watchField.branch.id || undefined,
      };
      rest.post<ITransferReq, void>(API.transfer.cashTransfer, transferData).catch(() => {
        setLoading(false);
      });
    }

    if (!isBranchChecked && !watchField?.number.includes('_') && watchField?.number.length) {
      // eslint-disable-next-line no-param-reassign
      formData.transferOption = 'card';
      setCardNumber(formData?.number);
      setAgreementModal(!openAgreementModal);
    }
  };

  return (
    <Container fluid={channelType === ESalesChannel.KAPITAL_AZ} className="bg-soft-grey formalize__loan_holder">
      <Panel className="formalize__loan">
        <ProgressBar progress={95} {...{ channelType }} />
        <Panel className="formalize__loan__title fontW">{translate('formalization.confirmOrder')}</Panel>
        <form onSubmit={handleSubmit(onSubmit)} className={`formalize__loan__form ${hasGiftCard ? 'has-giftcard' : ''}`}>
          <Row className="formalize__loan__type" dataTestId="row">
            <Col md={hasGiftCard ? 6 : 12}>
              <Panel className="formalize__loan__form__header_container" justifyContent={0} alignItems={2}>
                <Panel className="formalize__loan__form__header_container__img cash">
                  <img src={API.loan.getImage('CASH_MINI')} alt="cash credit" />
                </Panel>
                <h2 className="formalize__loan__form__header_container__text">{translate('formalization.creditOptionTitleCash')}</h2>
              </Panel>
              <div className="formalize__loan__type-item card bgwhite">
                <Panel
                  justifyContent={0}
                  padding={3}
                  onClick={() => {
                    selectFormType();
                    selectCard();
                  }}
                >
                  <Panel className="formalize__loan__type-item__photo">
                    <img src={`/assets/images/compressImage/yes-card-${channelType}.svg`} alt="phone" />
                  </Panel>
                  <Panel className="formalize__loan__type-item__context">
                    <Panel className="formalize__loan__type-item__context__title">{translate('formalization.depositToCard')}</Panel>
                    <Panel className="formalize__loan__type-item__context__description">{translate('formalization.infoCard')}</Panel>
                    {isCardOption ? (
                      <div className="card__number bgwhite">
                        <div className="card__number__input">
                          <label htmlFor="label" className="card__number__input__title">
                            {translate('common.cardNumber')}
                          </label>
                          <Controller
                            name="number"
                            control={control}
                            rules={{
                              required: true,
                              minLength: 16,
                            }}
                            render={({ field: { ref, name, onChange, value } }) => (
                              <InputMask
                                ref={ref}
                                name={name}
                                onChange={e => {
                                  onChange(onSanitizeValue(e));
                                }}
                                defaultValue={value}
                                className="phone-number__mask__input"
                                mask="9999 9999 9999 9999"
                                type="tel"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                error={errors.card}
                                placeholder="____ ____ ____ ____"
                              />
                            )}
                          />
                        </div>
                        {(watchField.number === '' || watchField.number.includes('_')) && (
                          <div className="error-text">{translate('common.error.cardNumberLength')}</div>
                        )}
                        {!watchField.number.includes('_') && !luhnCheck(cardNumberTrimmed) && (
                          <div className="error-text">{translate('common.error.cardNumberIncorrect')}</div>
                        )}
                        {responseError && <div className="error-text">{responseError}</div>}
                      </div>
                    ) : null}
                  </Panel>
                  <RadioButton
                    name="loan_get_type"
                    value="value 2"
                    className={`formalize__loan__type-item-checkbox checkbox-second radio-${channelType}`}
                    checked={!isBranchChecked}
                  />
                </Panel>
                <Panel
                  className="formalize__loan__type-item filial bgwhite"
                  onClick={() => {
                    selectFormType();
                    selectBranch();
                  }}
                  justifyContent={0}
                  padding={3}
                >
                  <Panel className="formalize__loan__type-item__photo">
                    <img src={`/assets/images/compressImage/yes-filial-${channelType}.svg`} alt="filial" />
                  </Panel>
                  <Panel className="formalize__loan__type-item__context">
                    <Panel className="formalize__loan__type-item__context__title">{translate('common.fromBranch')}</Panel>
                    <Panel className="formalize__loan__type-item__context__description">{translate('formalization.infoBranch')}</Panel>
                  </Panel>
                  <RadioButton
                    name="loan_get_type"
                    value="value 1"
                    className={`formalize__loan__type-item-checkbox radio-${channelType}`}
                    checked={isBranchChecked}
                  />
                </Panel>
              </div>
            </Col>
            {hasGiftCard && (
              <Col md={6}>
                <GiftCardBranchSelector control={control} />
              </Col>
            )}
          </Row>
          <Panel justifyContent={2} marginTop={8}>
            <ButtonCommon
              className="formalize__loan__btn"
              text={translate('actions.formalizeCredit')}
              channelType={channelType}
              disable={!buttonState}
            />
          </Panel>
        </form>
      </Panel>
      <AgreementModal
        openAgreementModal={openAgreementModal}
        setAgreementModal={setAgreementModal}
        setResponseError={setResponseError}
        handleCloseAgreementModal={handleCloseAgreementModal}
        cardNumber={cardNumber}
        branchId={watchField.branch?.id}
      />
    </Container>
  );
};

export default FormalizationOption;
