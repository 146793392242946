import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';

import './index.scss';

const SkeletonScoring: FC = () => {
  return (
    <Panel className="skelet__scoringcard">
      <Panel className="skelet__scoringcard__items">
        {[...Array(4)].map((item: number, index: number) => (
          <Panel key={index} marginBottom={8}>
            <Skeleton height={60} width={450} />
          </Panel>
        ))}
      </Panel>
    </Panel>
  );
};

export default SkeletonScoring;
