import { Panel } from '@kapital-bank/kb-ui-design-system';
import React, { FC } from 'react';

import './index.scss';

interface IProps {
  text?: string | React.ReactNode | React.ReactNodeArray;
  description?: string | React.ReactNode | React.ReactNodeArray;
  classHeader?: string;
  classDesc?: string;
}

const HeadingText: FC<IProps> = ({ text, description, classHeader = '', classDesc = '' }) => {
  return (
    <Panel className="heading">
      <div className={`heading__text ${classHeader}`}>{text}</div>
      <div className={`heading__desc ${classDesc}`}>{description}</div>
    </Panel>
  );
};

export default HeadingText;
