import React, { FC, ReactNode } from 'react';

import './index.scss';

interface IProps {
  className?: string;
  children?: ReactNode;
  fluid?: boolean;
  noPadding?: boolean;
}

const Container: FC<IProps> = ({ className, children, fluid, noPadding }) => {
  return <div className={`${className ? ` ${className}` : ''} ${fluid ? 'container-fluid' : 'container'} ${noPadding ? 'p-0' : ''}`}>{children}</div>;
};

export default Container;
