import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useCounter = (secondsInitial: number) => {
  const location = useLocation();
  const [startTimer, setStartTimer] = useState(false);
  const [counter, setCounter] = React.useState(() => {
    const state = location.state || ({} as any);
    if (!!state.counter || state.counter === 0) return +state.counter;
    return secondsInitial;
  });

  useEffect(() => {
    let counterHandler: NodeJS.Timeout;
    if (startTimer) {
      counterHandler = setTimeout(() => setCounter(counter - 1), 1000);
      if (counter <= 0) clearTimeout(counterHandler);
    }
    return () => {
      clearTimeout(counterHandler);
    };
  }, [counter, startTimer]);

  const minutes = useMemo(
    () =>
      Math.floor((counter % 3600) / 60)
        .toString()
        .padStart(2, '0'),
    [counter]
  );
  const seconds = useMemo(
    () =>
      Math.floor(counter % 60)
        .toString()
        .padStart(2, '0'),
    [counter]
  );

  return {
    minutes,
    seconds,
    setCounter,
    setStartTimer,
    counter,
  };
};
