import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Input, Panel } from '@kapital-bank/kb-ui-design-system';
import RSlider from 'rc-slider';

import Pencil from 'shared/assets/images/compressImage/pencil.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { translate } from 'shared/utils/translation';
import { ESalesChannel } from 'shared/enum';

import './index.scss';

interface IProps {
  amount: number;
  minAmount: number;
  maxAmount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  setBluePeriod: Dispatch<SetStateAction<boolean>>;
  loanMonthMaxFirstAmount: number;
}

const ScoringInput: FC<IProps> = ({ amount, maxAmount, minAmount, loanMonthMaxFirstAmount, setBluePeriod, setAmount }) => {
  const { channelType } = useGeneralInfoContext();
  const rcSliderColor =
    channelType === ESalesChannel.BIRBANK_AZ
      ? '#CE0F1D '
      : channelType === ESalesChannel.KAPITAL_AZ
      ? '#E25F6C'
      : channelType === ESalesChannel.UMICO
      ? '#7C62E3'
      : '#CE0F1D';

  useEffect(() => {
    const sliderLine = document.querySelector('.rc-slider .rc-slider-rail');
    const amountBlueLine = document.createElement('span');
    amountBlueLine.className = 'amount-blue-line';
    sliderLine?.insertAdjacentElement('afterend', amountBlueLine);
  }, []);

  useEffect(() => {
    const amountBlueLine = document.querySelector('.amount-blue-line');
    // first consider slider amount from 0 to max amount. this is just for calculation and show correct slide effect.
    const amountDifference = maxAmount - minAmount;
    const loanMonthDifference = loanMonthMaxFirstAmount - minAmount;

    if (loanMonthMaxFirstAmount) {
      let initialBluePercent = (loanMonthDifference * 100) / amountDifference;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      amountBlueLine.style.width = `${initialBluePercent}%`;
      const sliderRound = document.querySelector('.rc-slider-handle');
      if (amount <= loanMonthMaxFirstAmount) {
        initialBluePercent = ((amount - minAmount) * 100) / amountDifference;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        amountBlueLine.style.width = `${initialBluePercent}%`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRound.style.borderColor = '#5fc3e2';
        setBluePeriod(true);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRound.style.borderColor = rcSliderColor;
        setBluePeriod(false);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      amountBlueLine.style.width = `${0}%`;
      setBluePeriod(false);
    }
    if (amount < minAmount) {
      setBluePeriod(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      amountBlueLine.style.width = `${0}%`;
    }
  }, [loanMonthMaxFirstAmount, amount, maxAmount, minAmount, setBluePeriod]);

  const updateData = (value: string) => {
    const val = parseInt(value.replace(/\D/g, ''), 10) || 0;
    if (value.length > 5) {
      setAmount(amount);
    } else {
      setAmount(val);
    }
  };

  const [inputFocus, setInputFocus] = useState(false);
  const inputMultiRef = React.createRef();
  const focusMultiInput = () => {
    setInputFocus(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputMultiRef.current?.focus();
  };

  const amountInputChange = (amountValue: string) => {
    setInputFocus(false);
    if (amountValue) {
      const tmpAmount: number = parseInt(amountValue, 10);
      if (tmpAmount > maxAmount) {
        setAmount(maxAmount);
      } else if (tmpAmount < minAmount) {
        setAmount(minAmount);
      }
    }
  };

  return (
    <Panel className="scoring-input-box">
      <div className="scoring-input-box__label">
        <span> {`${translate('common.credit.amount')} `} (AZN)</span>
        <span> {translate('common.info.reduceAmount')}</span>
      </div>
      <Panel>
        <span className="scoring-input-box__input-box">
          <Input
            className="scoring-input-box__input-box__input"
            onChange={updateData}
            value={amount.toString()}
            ref={inputMultiRef}
            onBlur={value => {
              amountInputChange(value);
            }}
          />
          {!inputFocus && <img src={Pencil} alt="pencil" onClick={focusMultiInput} />}
        </span>

        <Panel className="scoring-input-box__slider-container">
          <Panel>
            <Panel className="scoring-input-box__slider-container__min-amount" />
            <Panel className={`scoring-input-box__slider-container__max-amount `} />
          </Panel>
          <RSlider
            value={amount}
            style={{ marginTop: -20, marginBottom: 30 }}
            min={minAmount}
            max={maxAmount}
            defaultValue={amount}
            step={10}
            trackStyle={{ backgroundColor: rcSliderColor, height: 4 }}
            handleStyle={{
              borderColor: rcSliderColor,
              height: 18,
              width: 18,
              marginLeft: 0,
              marginTop: -8,
              backgroundColor: 'white',
              opacity: 1,
            }}
            railStyle={{ backgroundColor: '#F9F9FA', height: 4, borderRadius: 20 }}
            onChange={value => updateData(value.toString())}
          />
        </Panel>
        <Panel justifyContent={4} alignItems={2} marginBottom={4} className="scoring-input-box__amounts">
          <div>{minAmount}</div>
          <div>{maxAmount}</div>
        </Panel>
      </Panel>
    </Panel>
  );
};

export default ScoringInput;
