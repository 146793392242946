import React, { FC, useState } from 'react';
import { Checkbox, EElementSize, EJustifyContent, Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';
import { styleModal } from 'shared/assets/styles/react-modal';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import ButtonCommon from 'shared/components/ButtonCommon';
import { translate } from 'shared/utils/translation';
import { IFeedbackOption } from 'shared/model';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

import './index.scss';

interface IProps {
  modalOpen: boolean;
  handleToggleModal: (selectedFeedbackOptions: number[]) => void;
  option?: IFeedbackOption;
}

const FeedbackChoicesModal: FC<IProps> = ({ modalOpen, handleToggleModal, option }) => {
  const { channelType } = useGeneralInfoContext();
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<number[]>([]);

  const isMobile = useMediaQuery('(max-width: 500px)');

  const handleSelectOption = (fbOptionId: number): void => {
    const options = [...selectedFeedbackOptions];
    const indexOfOption = options.indexOf(fbOptionId);
    if (indexOfOption > -1) {
      options.splice(indexOfOption, 1);
    } else {
      options.push(fbOptionId);
    }
    setSelectedFeedbackOptions(options);
  };

  return (
    <ReactModal
      style={styleModal}
      isOpen={modalOpen}
      onRequestClose={() => handleToggleModal(selectedFeedbackOptions)}
      shouldCloseOnOverlayClick={false}
      className="modal feedback_choice"
    >
      <>
        <Panel>
          <Panel className="modal__header" justifyContent={4} alignItems={4}>
            <div>
              <h4 className="feedback_choice__modal_header">{option?.title}</h4>
              <span className="feedback_choice__modal_header_offer">{option?.description}</span>
            </div>
            <img className="modal-close" onClick={() => handleToggleModal(selectedFeedbackOptions)} src={CloseIcon} alt="close" role="none" />
          </Panel>
        </Panel>
        <Panel className="modal-common-context">
          {option?.options?.map(fbCheck => {
            return (
              <Panel key={fbCheck.id} className="feedback_choice__option">
                <Checkbox
                  className={`checkbox-${channelType}`}
                  value={selectedFeedbackOptions.includes(fbCheck.id)}
                  onChange={() => handleSelectOption(fbCheck.id)}
                >
                  <span className="feedback_choice__option_title">{fbCheck.option}</span>
                </Checkbox>
              </Panel>
            );
          })}
          <Panel className="modal-line-bottom" />
          <Panel marginTop={7} className="approve__box" justifyContent={EJustifyContent.END}>
            <ButtonCommon
              size={isMobile ? EElementSize.LG : EElementSize.MD}
              channelType={channelType}
              className="approve__box__btn"
              text={translate('common.actions.send')}
              disable={!selectedFeedbackOptions.length}
              handleClick={() => handleToggleModal(selectedFeedbackOptions)}
            />
          </Panel>
        </Panel>
      </>
    </ReactModal>
  );
};

export default FeedbackChoicesModal;
