import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, EElementSize, EElementView, Panel, Row } from '@kapital-bank/kb-ui-design-system';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { AxiosError } from 'axios';
import ReactModal, { Styles } from 'react-modal';

import CloseIcon from 'shared/assets/images/compressImage/close-icon.svg';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import MarkerGrey from 'shared/assets/images/gray-map-marker.svg';
import MarkerRed from 'shared/assets/images/red-map-marker.svg';
import { API } from 'shared/api/loan';
import { GOOGLE_MAP_KEY, PIN } from 'shared/consts';
import LoadingCircle from 'shared/components/LoadingCircle';
import { translate } from 'shared/utils/translation';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ELogLevel } from 'shared/enum';
import { logger } from 'shared/utils/logger';
import { IDeliveryReq, IDeliveryResp, IVenueData } from 'shared/model';

import Search from './Search';
import VerificationModal from '../../../VerificationModal';

import './index.scss';

interface IProps {
  openAddressModal: boolean;
  handleCloseAddressModal: () => void;
  setAddress?: any;
  nearestVenue?: any;
  setNearestVenue: (val: any) => void;
  markerPosition?: any;
  setMarkerPosition?: any;
  setAddressValidation: any;
  setTabType: () => void;
}

const style: Styles = {
  content: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    zIndex: 1,
    transform: 'translate(-50%, -50%)',
  },
};

const containerStyle = {
  width: '100%',
  height: '60vh',
  borderRadius: '10px',
};

const AddressModal: FC<IProps> = ({
  openAddressModal,
  handleCloseAddressModal,
  setAddress,
  markerPosition,
  setMarkerPosition,
  setNearestVenue,
  nearestVenue,
  setAddressValidation,
  setTabType,
}) => {
  const { rest, channelType } = useGeneralInfoContext();
  const [venues, setVenues] = useState<Array<IVenueData>>([]);
  const [inputValue, setInput] = useState<string>('');
  const [checkedAdress, setCheckedAdress] = useState<Record<string, any> | null>(null);
  const [deliveryErrorCode, setDeliveryErrorCode] = useState<string | null>(null);
  const addressPart = inputValue.split(',');
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [infoWindowDisplay, setInfoWindowDisplay] = useState(true);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [libraries] = useState<('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[]>(['places', 'geometry']);
  const [geocodingTimeout, setGeocodingTimeout] = useState<NodeJS.Timeout>();
  const [errLocation, setErrLocation] = useState(false);
  const [venueCLosedInfoModal, setVenueCLosedInfoModal] = useState(false);

  const handleCloseVenueClosedInfoModal = () => {
    setVenueCLosedInfoModal(!venueCLosedInfoModal);
  };

  const setNearestVenueWithCheck = useCallback(
    obj => {
      if (obj.id !== nearestVenue.id || obj.branchCode !== nearestVenue.branchCode) {
        setNearestVenue(obj);
      }
    },
    [nearestVenue, setNearestVenue]
  );
  useEffect(() => {
    rest.get<Array<IVenueData>>(API.delivery.getVenues).then(({ data }) => {
      setVenues(data);
    });
  }, []);

  const handleCheckSubmit = () => {
    setLoadingCircle(true);
    const deliveryDetails = {
      city: addressPart[addressPart.length - 2]?.trim(),
      language: 'az',
      lat: markerPosition.lat,
      lon: markerPosition.lng,
      min_preparation_time_minutes: 10,
      pin: localStorage.getItem(PIN),
      post_code: 'az1000',
      scheduled_dropoff_time: null,
      street: addressPart[addressPart.length - 3]?.trim(),
    };

    rest
      .post<IDeliveryReq, IDeliveryResp>(API.delivery.sendDeliveryDetail(nearestVenue?.id), deliveryDetails)
      .then(({ data }) => {
        setCheckedAdress(data);
        if (data?.isBinding) {
          setAddress(inputValue);
          handleCloseAddressModal();
          setAddressValidation(false);
        }
      })
      .catch((error: AxiosError) => {
        setDeliveryErrorCode(error?.response?.data?.error);
        logger(error, ELogLevel.ERROR);
        if (error?.response?.data?.error === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA') {
          setIsUnavailable(true);
        }
        if (error?.response?.data?.error === 'VENUE_CLOSED') {
          handleCloseAddressModal();
          setVenueCLosedInfoModal(true);
        }
      })
      .finally(() => {
        setLoadingCircle(false);
      });
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
  });

  const mapRef: any = React.useRef();
  const onMapLoad = (map: any) => {
    mapRef.current = map;
  };

  const getNearestVenue = (mapCenter: google.maps.LatLng) => {
    // set the nearest venue.
    if (venues.length) {
      const distances: number[] = [];
      venues.forEach(venue => {
        const venueLatLon = new google.maps.LatLng(venue.lat, venue.lon);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, venueLatLon);
        distances.push(distance);
      });
      const indexOfMinValue = distances.reduce((iMax, x, i, arr) => (x < arr[iMax] ? i : iMax), 0);
      const nVenue = venues[indexOfMinValue];
      setNearestVenueWithCheck({ id: nVenue.venueId, branchCode: nVenue.branchCode });
    }
  };
  const panTo = useCallback(
    ({ lat, lng }) => {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(13);
      setMarkerPosition((prevState: any) => ({
        ...prevState,
        lat,
        lng,
      }));
      getNearestVenue(mapRef?.current?.getCenter());
    },
    [venues]
  );

  const getReverseGeocodingData = (lat: number, lng: number, timeout = 500) => {
    // added timeout prevent decoding on fast drags.
    clearTimeout(geocodingTimeout);
    const timer = setTimeout(() => {
      const latlng = new google.maps.LatLng(lat, lng);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const correctAddressList = results?.filter(
            item => !item.formatted_address.includes('+') && !item.formatted_address.includes('-') && !item.formatted_address.includes('Adsız yol')
          );
          const address = correctAddressList?.[0]?.formatted_address || '';
          setInput(address);
        }
      });
      getNearestVenue(latlng);
    }, timeout);
    setGeocodingTimeout(timer);
  };

  const resetHandler = () => {
    setInfoWindowDisplay(false);
    setDeliveryErrorCode(null);
    setIsUnavailable(false);
  };

  const onMarkerDragEnd = (e: any) => {
    setMarkerPosition({ ...markerPosition, lat: e.latLng.lat(), lng: e.latLng.lng() });
    getReverseGeocodingData(e.latLng.lat(), e.latLng.lng());
  };

  const onMapDragEnd = () => {
    setMarkerPosition({ ...markerPosition, lat: mapRef?.current?.getCenter().lat(), lng: mapRef?.current?.getCenter().lng() });
    getReverseGeocodingData(mapRef?.current?.getCenter().lat(), mapRef?.current?.getCenter().lng());
    resetHandler();
  };

  const successLocation = (position: any) => {
    setMarkerPosition({ ...markerPosition, lat: position.coords.latitude, lng: position.coords.longitude });
  };

  const errorLocation = () => {
    setErrLocation(true);
    setMarkerPosition({ ...markerPosition, lat: 40.4093, lng: 49.8671 });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successLocation, errorLocation);
    }
  }, []);

  const [displayBtn, setDisplay] = useState(false);
  const refBtn = useRef(null);
  const refButton = useRef<any>(null);

  useEffect(() => {
    if (refBtn) setDisplay(!displayBtn);
  }, []);

  useEffect(() => {
    if (isLoaded && openAddressModal && !errLocation) {
      setMarkerPosition({ ...markerPosition, lat: markerPosition.lat, lng: markerPosition.lng });
      getReverseGeocodingData(markerPosition.lat, markerPosition.lng, 0);
    }
  }, [isLoaded, openAddressModal]);

  if (loadError) return <>Error loading maps</>;
  if (!isLoaded) return <>Loading Maps</>;
  const leftReloadHandler = () => setIsUnavailable(false);
  return (
    <>
      <ReactModal
        style={style}
        isOpen={openAddressModal}
        onRequestClose={handleCloseAddressModal}
        shouldCloseOnOverlayClick={false}
        className="address-modal"
      >
        <Panel className="address-modal__header" justifyContent={4} alignItems={2}>
          <span className="address-modal__header__title">{translate('delivery.map')}</span>
          <img role="none" onClick={handleCloseAddressModal} src={CloseIcon} alt="icon" />
        </Panel>
        <Panel marginBottom={2} paddingTop={2} className="address-modal__body">
          <Row>
            <Col md={8}>
              <Search
                panTo={panTo}
                setInput={setInput}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                markerPosition={markerPosition}
                inputValue={inputValue}
                deliveryErrorCode={deliveryErrorCode}
                setDeliveryErrorCode={setDeliveryErrorCode}
              />
              <Panel justifyContent={0} alignItems={2} className="address-modal__body__info">
                <img src={InfoSolid} alt="info" />
                <div className="address-modal__body__info__text">
                  {`${translate('delivery.mapInfo.firstPart')} `}
                  <div className="dangerColor-bb marginR marginLxs"> {` ${translate('delivery.mapInfo.secondPart')} `}</div>
                  {translate('delivery.mapInfo.thirdPart')}
                </div>
              </Panel>
            </Col>
            <Col md={4}>
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ref={refButton}
                className={`${loadingCircle ? 'check-search' : 'search-btn'} approve-location-${channelType} ${displayBtn ? 'media-btn' : ''}`}
                size={EElementSize.MD}
                disabled={inputValue === '' || deliveryErrorCode === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA'}
                onClick={handleCheckSubmit}
              >
                {loadingCircle ? (
                  <>
                    <LoadingCircle />
                    {translate('delivery.checking')}
                  </>
                ) : (
                  translate('common.actions.confirm')
                )}
              </Button>
            </Col>
          </Row>

          <Panel
            className={`${
              (checkedAdress && !checkedAdress?.isBinding) || deliveryErrorCode === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA'
                ? 'address-modal__body__map__dropoff'
                : 'address-modal__body__map'
            }`}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: markerPosition.lat, lng: markerPosition.lng }}
              zoom={14}
              onLoad={onMapLoad}
              onDragEnd={onMapDragEnd}
            >
              <Marker
                key={markerPosition.lat - markerPosition.lng}
                position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
                onDrag={resetHandler}
                onDragEnd={e => {
                  onMarkerDragEnd(e);
                }}
                draggable
                icon={{
                  url:
                    (checkedAdress && !checkedAdress?.isBinding) || deliveryErrorCode === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA' ? MarkerGrey : MarkerRed,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(16, 50),
                }}
              />
              {infoWindowDisplay && (
                <InfoWindow position={{ lat: markerPosition.lat, lng: markerPosition.lng }}>
                  <Panel className="map-info">
                    <span>
                      {translate('delivery.chooseAddress.firstPart')}
                      <br />
                      {translate('delivery.chooseAddress.secondPart')}
                    </span>
                  </Panel>
                </InfoWindow>
              )}
            </GoogleMap>
          </Panel>

          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={refBtn}
            size={EElementSize.MD}
            className={`approve-btn ${displayBtn ? 'media-approve-btn' : ''}`}
            disabled={
              ((checkedAdress && !checkedAdress?.isBinding) || (deliveryErrorCode === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA' && inputValue === '')) &&
              true
            }
            onClick={handleCheckSubmit}
          >
            {translate('common.actions.confirm')}
          </Button>
        </Panel>
      </ReactModal>

      {/* -----------Venue Closed Info Modal---------------- */}
      <ReactModal
        style={style}
        isOpen={venueCLosedInfoModal}
        onRequestClose={handleCloseVenueClosedInfoModal}
        shouldCloseOnOverlayClick={false}
        className="venue-closed-modal"
      >
        <Panel className="venue-closed-modal__header">
          <Panel className="venue-closed-modal__header__title">{translate('common.info.title')}</Panel>
        </Panel>
        <Panel className="venue-closed-modal__content">
          <p className="venue-closed-modal__content__text">{translate('common.info.venueClosedInfo')}</p>
          <Button
            className="venue-closed-modal__content__btn"
            size={EElementSize.MD}
            view={EElementView.SECONDARY}
            onClick={handleCloseVenueClosedInfoModal}
          >
            {translate('common.actions.close')}
          </Button>
        </Panel>
      </ReactModal>
      <VerificationModal
        showReload={isUnavailable}
        leftReload={leftReloadHandler}
        rightReload={setTabType}
        text={translate('warning.changeSelection')}
        title={translate('warning.info')}
        rejectText={translate('warning.chooseBranch')}
        approveText={translate('warning.newDestination')}
        closeButton={false}
      />
    </>
  );
};

export default AddressModal;
