import React, { FC, useMemo } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';

import { translate } from 'shared/utils/translation';
import { EProductCode, ESalesChannel } from 'shared/enum';
import { IDetails, IProductData } from 'shared/model';
import TranferCashBB from 'shared/assets/images/compressImage/customer-info/transfer-cash-bb.svg';
import TranferCashKB from 'shared/assets/images/compressImage/customer-info/transfer-cash-kb.svg';
import TranferCashUM from 'shared/assets/images/compressImage/customer-info/transfer-cash-um.svg';
import CurierCardBB from 'shared/assets/images/compressImage/customer-info/curier-card-bb.svg';
import CurierCardKB from 'shared/assets/images/compressImage/customer-info/curier-card-kb.svg';
import CurierCardUM from 'shared/assets/images/compressImage/customer-info/curier-card-um.svg';
import BranchCashBB from 'shared/assets/images/compressImage/customer-info/filial-cash-bb.svg';
import BranchCashKB from 'shared/assets/images/compressImage/customer-info/filial-cash-kb.svg';
import BranchCashUM from 'shared/assets/images/compressImage/customer-info/filial-cash-um.svg';
import BranchCardBB from 'shared/assets/images/compressImage/customer-info/filial-card-bb.svg';
import BranchCardKB from 'shared/assets/images/compressImage/customer-info/filial-card-kb.svg';
import BranchCardUM from 'shared/assets/images/compressImage/customer-info/filial-card-um.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface ICreditDetail {
  title: string;
  value: string;
}

interface IProps {
  productInfo?: IProductData;
  orderDetails?: IDetails;
}

const CreditDetails: FC<IProps> = ({ productInfo, orderDetails }) => {
  const { productCode, channelType } = useGeneralInfoContext();

  const dataCreditDetail = useMemo(() => {
    const creditDetails: ICreditDetail[] = [
      { title: translate('common.credit.amount'), value: `${orderDetails?.product.amount || ''} AZN` },
      {
        title: productCode === EProductCode.GTKR ? translate('common.annualInterestRate') : translate('common.monthlyInterestRate'),
        value: `${orderDetails?.product.percent ?? ''} %`,
      },
      {
        title: translate('common.credit.term'),
        value: `${orderDetails?.product.period ?? ''} ${translate('common.month')?.toUpperCase()}`,
      },
      ...(productCode === EProductCode.GTKR
        ? [
            {
              title: translate('common.monthPayment'),
              value: `${orderDetails?.product.monthlyPayment ?? ''} AZN`,
            },
          ]
        : []),
    ];
    return creditDetails?.map(({ title, value }) => (
      <li key={title} className="credit__info__details__list-item">
        <Panel className="credit__info__details__list-item-amount">{value}</Panel>
        <Panel className="credit__info__details__list-item-description">{title}</Panel>
      </li>
    ));
  }, [orderDetails]);

  const creditInfoPhotoBox = productCode === EProductCode.GTKR ? 'cash-order-img-box' : 'credit-order-img-box';
  const creditInfoPhoto = productCode === EProductCode.GTKR ? 'cash-order-img-box__img' : 'credit-order-img-box__img';
  const orderMark = productCode === EProductCode.GTKR ? 'cash-order-mark' : 'credit-order-mark';
  const cashInfoDescription = productCode === EProductCode.GTKR ? 'cash-order-none' : '';

  return (
    <Panel className="credit__info">
      <Panel className={`credit__info__photo ${creditInfoPhotoBox}`}>
        {channelType === ESalesChannel.BIRBANK_AZ && <div className={`credit__info__photo__birbank-mark ${orderMark}`} />}
        {productInfo?.icon ? (
          <img src={productInfo.icon} alt="credit-img" className={creditInfoPhoto} />
        ) : (
          <Panel className="credit__info__photo__skeleton">
            <Skeleton height={300} width={200} />
          </Panel>
        )}
      </Panel>
      <Panel className={`credit__info__description credit__info__description__${channelType} ${cashInfoDescription}`}>
        <Panel className="credit__info__description__title">{productInfo?.title}</Panel>
        <Panel className="credit__info__description__context">{productInfo?.description}</Panel>
      </Panel>
      <Panel className={`credit__info__details credit__info__details__${channelType}`}>
        <Panel className="credit__info__details__title">{translate('checkInfo.detailCredit')}</Panel>
        <Panel>
          <ul className="credit__info__details__list">{dataCreditDetail}</ul>
        </Panel>
      </Panel>
      <Panel className={`credit__info__delivery__methods credit__info__delivery__methods__${channelType}`}>
        <Panel className="credit__info__details__title">{translate('checkInfo.deliveryTypes')}</Panel>
        <Panel className="credit__info__delivery__methods-item">
          <Panel
            className={`credit__info__delivery__methods-item__photo ${
              productCode === EProductCode.GTKR ? `card-${channelType}` : `curier-${channelType}`
            }`}
          >
            {productCode === EProductCode.GTKR ? (
              channelType === ESalesChannel.BIRBANK_AZ ? (
                <img src={TranferCashBB} alt="img" />
              ) : channelType === ESalesChannel.KAPITAL_AZ ? (
                <img src={TranferCashKB} alt="img" />
              ) : (
                <img src={TranferCashUM} alt="img" />
              )
            ) : channelType === ESalesChannel.BIRBANK_AZ ? (
              <img src={CurierCardBB} alt="img" />
            ) : channelType === ESalesChannel.KAPITAL_AZ ? (
              <img src={CurierCardKB} alt="img" />
            ) : (
              <img src={CurierCardUM} alt="img" />
            )}
          </Panel>
          <Panel className="credit__info__delivery__methods-item__context">
            <Panel justifyContent={0} flexDirection={4} className="credit__info__delivery__methods-item__context-title">
              {productCode === EProductCode.GTKR ? translate('checkInfo.transferCard') : translate('checkInfo.deliveryCourier')}
              {productCode !== EProductCode.GTKR ? (
                <div className={`credit__info__delivery__methods-item__context__free-curier  free-curier__${channelType}`}>
                  {translate('common.free')}
                </div>
              ) : null}
            </Panel>

            <Panel className="credit__info__delivery__methods-item__context-description">
              {productCode === EProductCode.GTKR ? translate('checkInfo.deliveryInfo.cash') : translate('checkInfo.deliveryInfo.birKart')}
            </Panel>
          </Panel>
        </Panel>
        <Panel className="credit__info__delivery__methods-item">
          <Panel className={`credit__info__delivery__methods-item__photo filial-${channelType}`}>
            {productCode === EProductCode.GTKR ? (
              channelType === ESalesChannel.BIRBANK_AZ ? (
                <img src={BranchCashBB} alt="img" />
              ) : channelType === ESalesChannel.KAPITAL_AZ ? (
                <img src={BranchCashKB} alt="img" />
              ) : (
                <img src={BranchCashUM} alt="img" />
              )
            ) : channelType === ESalesChannel.BIRBANK_AZ ? (
              <img src={BranchCardBB} alt="img" />
            ) : channelType === ESalesChannel.KAPITAL_AZ ? (
              <img src={BranchCardKB} alt="img" />
            ) : (
              <img src={BranchCardUM} alt="img" />
            )}
          </Panel>
          <Panel className="credit__info__delivery__methods-item__context">
            <Panel className="credit__info__delivery__methods-item__context-title">{translate('common.fromBranch')}</Panel>
            <Panel className="credit__info__delivery__methods-item__context-description">
              {productCode === EProductCode.GTKR ? translate('checkInfo.transferNewBankAccount') : translate('checkInfo.optionBranchCard')}
            </Panel>
          </Panel>
        </Panel>
      </Panel>
    </Panel>
  );
};
export default CreditDetails;
