import React, { FC } from 'react';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

const Loader: FC = () => {
  const { channelType } = useGeneralInfoContext();
  return <div className={`loader loader__type-${channelType}`} />;
};

export default Loader;
