import React from 'react';
import { Button, EElementSize, Modal, Panel } from '@kapital-bank/kb-ui-design-system';
import ButtonCommon from 'shared/components/ButtonCommon';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import './index.scss';

interface IProps {
  leftReload?: () => void;
  rightReload?: () => void;
  showReload: boolean;
  title: string;
  text: string;
  approveText?: string;
  rejectText?: string;
  closeButton?: boolean;
  onClose?: () => void;
}

const VerificationModal: React.FC<IProps> = ({
  showReload,
  leftReload,
  rightReload,
  text,
  title,
  rejectText,
  approveText,
  closeButton = true,
  onClose,
}) => {
  const { channelType } = useGeneralInfoContext();

  return (
    <Modal
      size={EElementSize.LG}
      forceOpened={showReload}
      className="verification__reload__modal verification-modal"
      closeButton={closeButton}
      onClose={onClose}
    >
      <Panel className="modal__title">{title}</Panel>
      <Panel className="modal-line" />
      <Panel justifyContent={2} paddingTop={2} marginBottom={5} className="verification__reload__modal__content">
        <span className="verification__reload__modal__content__textColor-bb">{text}</span>
      </Panel>

      <Panel className="verification__reload__modal-buttons">
        {approveText && (
          <Panel className={`verification__reload__modal-buttons__reject ${!rejectText && 'whole-btn'}`}>
            <Button className="btn" onClick={leftReload}>
              {approveText}
            </Button>
          </Panel>
        )}
        {rejectText && (
          <Panel className={`verification__reload__modal-buttons__approve ${!approveText && 'whole-btn'}`}>
            <ButtonCommon channelType={channelType} text={rejectText} handleClick={rightReload} />
          </Panel>
        )}
      </Panel>
    </Modal>
  );
};
export default VerificationModal;
