import React from 'react';
import ReactModal from 'react-modal';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import 'shared/assets/styles/main.scss';
import Layout from 'shared/layout';
import NotFoundPage from 'shared/views/NotFound';
import { IDetails } from 'shared/model';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ELang } from 'shared/enum';
import { getCurrentLang } from 'shared/utils/translation';
import { WithGeneralInfo } from './shared/context/WithGeneralInfo';

import { commonRoutes } from './routes/common';
import { OLD_FIRST_PAGE_LANG_PATH, OLD_FIRST_PAGE_PATH, OLD_STEP_LOADING_PATH, ROUTES } from './routes/consts';
import { PROCESS_ID } from './shared/consts';
import { API } from './shared/api/loan';
import { restClient } from './shared/api/rest';
import Loading from './shared/components/Loading';

ReactModal.setAppElement('#app');

// TODO: remove after BE is ready
const NavigateTempWrapper = () => {
  const { search } = useLocation();
  const { channelType } = useGeneralInfoContext();
  const { productCode: prCode } = useParams();
  const navigate = useNavigate();
  const processId = localStorage.getItem(PROCESS_ID);
  const productCode = prCode?.replace(/[^a-zA-Z_]+/, '');
  const currentLang = getCurrentLang();
  if (processId) {
    restClient(ELang.AZ, () => {
      navigate(`/${ELang.AZ}/order/${productCode}/end?t=${channelType}`);
    })
      .get<IDetails>(API.loan.getOrderDetail(processId))
      .then(({ data }) => {
        navigate(`/${currentLang}/order/${data.product?.code || productCode}/step-loading${search}`);
      })
      .catch(() => {
        navigate(`/${currentLang}/order/${productCode}${search}`);
      });
    return <Loading />;
  }
  return <Navigate to={`/${currentLang}/order/${productCode}${search}`} />;
};

const NavigateTempFirstPageWrapper = () => {
  const { search } = useLocation();
  const { lang = 'az', productCode } = useParams();
  return <Navigate to={`/${lang}/order/${productCode}${search}`} />;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path={ROUTES.ROOT.PATH}
          element={
            <WithGeneralInfo>
              <Layout>{commonRoutes}</Layout>
            </WithGeneralInfo>
          }
        />
        <Route path={OLD_FIRST_PAGE_PATH} element={<NavigateTempFirstPageWrapper />} />
        <Route path={OLD_FIRST_PAGE_LANG_PATH} element={<NavigateTempFirstPageWrapper />} />
        {/* TODO: ask BE add product to link after dvs */}
        <Route path={OLD_STEP_LOADING_PATH} element={<NavigateTempWrapper />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
