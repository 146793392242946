import React, { FC } from 'react';
import Select, { Props } from 'react-select';
import { getCustomStyles, prefixStyles } from '../../assets/styles/react-select';

interface IProps extends Props {
  asPrefix?: boolean;
}

const ReactSelect: FC<IProps> = props => {
  const { asPrefix, ...rest } = props;
  let addedStyles = {};
  if (asPrefix) {
    addedStyles = { ...prefixStyles };
  }
  return <Select styles={getCustomStyles(addedStyles)} {...rest} />;
};

export default ReactSelect;
