const frmt = (val: number) => (val < 10 ? `0${val}` : val);

export default {
  display: (value: string, format: string) => {
    const newDate = new Date(value);
    switch (format) {
      case 'dd.mm.yyyy':
        return `${frmt(newDate.getDate())}.${frmt(newDate.getMonth() + 1)}.${frmt(newDate.getFullYear())}`;
      case 'yyyy-mm-dd':
        return `${frmt(newDate.getFullYear())}-${frmt(newDate.getMonth() + 1)}-${frmt(newDate.getDate())}`;
      case 'dd.mm.yyyy hh:mm':
        return `${frmt(newDate.getDate())}.${frmt(newDate.getMonth() + 1)}.${frmt(newDate.getFullYear())} ${frmt(newDate.getHours())}:${frmt(
          newDate.getMinutes()
        )}`;
      case 'hh:mm':
        return `${frmt(newDate.getHours())}:${frmt(newDate.getMinutes())}`;
      default:
        return `${frmt(newDate.getDate())}.${frmt(newDate.getMonth() + 1)}.${frmt(newDate.getFullYear())}`;
    }
  },
};
