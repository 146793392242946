import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import './index.scss';

interface IProps {
  children: any;
}

const SubCategoryMobile: FC<IProps> = ({ children }) => {
  const params = window.location.search;

  const getCategory = (path: string) =>
    DOMPurify.sanitize(path).includes('ccl') ? `${DOMPurify.sanitize(path)}${DOMPurify.sanitize(params)}` : DOMPurify.sanitize(path);

  return (
    <div className="subcategory__mobile__kb">
      <Panel>
        <Panel className="subcategory__mobile__kb__content">
          <ul className="subcategory__mobile__kb__content-list">
            {children?.link1?.map(({ title, path }: any, i: number) => (
              <li className="subcategory__mobile__kb__content-list-item" key={`${i}kbmobilelink1`}>
                <a href={getCategory(path)} className="first">
                  {title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="subcategory__mobile__kb__content-list">
            {children?.link2?.map(({ title, path }: any, i: number) => (
              <li className="subcategory__mobile__kb__content-list-item" key={`${i}kbmobilelink2`}>
                <a href={getCategory(path)} className="second">
                  {title}
                </a>
              </li>
            ))}
          </ul>
          <ul className="subcategory__mobile__kb__content-list">
            {children?.link3?.map(({ title, path }: any, i: number) => (
              <li className="subcategory__mobile__kb__content-list-item" key={`${i}kbmobilelink3`}>
                <a href={getCategory(path)} className="second">
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </Panel>
        <Panel className="subcategory__mobile__kb__more__info" flexDirection={0}>
          <Panel className="subcategory__mobile__kb__more__info__photo">
            <img src={children?.moreInfo.mobileImg} alt="more" />
          </Panel>

          <Panel className="subcategory__mobile__kb__more__info__order" paddingRight={3}>
            <p className="subcategory__mobile__kb__more__info__order__title">
              {children?.moreInfo?.title1} <span className="bold">{children?.moreInfo?.title2}</span>
              <span className="bold new__title">{children?.moreInfo.newTitle ? children?.moreInfo?.newTitle : null}</span>
            </p>
            <p className="subcategory__mobile__kb__more__info__order__desc"> {children?.moreInfo?.desc} </p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="subcategory__mobile__kb__more__info__order__btn"
              href={
                DOMPurify.sanitize(children?.moreInfo?.path).includes('ccl')
                  ? `${DOMPurify.sanitize(children?.moreInfo?.path)}${DOMPurify.sanitize(params)}`
                  : DOMPurify.sanitize(children?.moreInfo?.path)
              }
            >
              {children?.moreInfo.button}
            </a>
          </Panel>
        </Panel>
      </Panel>
    </div>
  );
};

export default SubCategoryMobile;
