import React, { FC, useCallback, useEffect, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import { Controller, useForm } from 'react-hook-form';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import ButtonCommon from 'shared/components/ButtonCommon';
import { translate } from 'shared/utils/translation';
import { API } from 'shared/api/loan';
import { dateFormatter } from 'shared/utils/formatter';
import { useLoading } from 'shared/context/loading';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import LoadingMessage from 'shared/components/ReactSelect/LoadingMessage';
import {
  IBranchData,
  IBranchFormData,
  IBranchWorkHours,
  IComplateDeliveryReq,
  IDeliveryBranchReq,
  IDeliveryWorkDayReq,
  IDeliveryWorkHourReq,
  IDetails,
} from 'shared/model';

import './index.scss';

interface IProps {
  customStyle?: Record<string, any>;
}

const Branch: FC<IProps> = ({ customStyle }) => {
  const { rest, processId, channelType } = useGeneralInfoContext();
  const branchOptions: { value: string; label: string; id: string }[] = [];
  const branchWorkingTime: { value: string; label: string }[] = [];
  const workDaysOptions: Array<Date> = [];
  const { setLoading } = useLoading();
  const [dayInputDisable, setDayInputDisable] = useState<boolean>(true);
  const [timeInputDisable, setTimeInputDisable] = useState<boolean>(true);
  const [state, setData] = useState({
    branch: {},
    isLoading: true,
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IBranchFormData>({
    defaultValues: {
      branch: { value: '', label: '', id: '' },
      workDate: '',
      time: { label: '', value: '' },
    },
  });
  const watchField = watch();

  const getBranches = (serviceID: string) => {
    rest.get<IDeliveryBranchReq>(API.branch.getBranch(serviceID)).then(({ data }) => {
      data.branches?.forEach(({ id, serviceId, name }: IBranchData) => {
        branchOptions.push({
          value: serviceId,
          label: name,
          id,
        });
      });
      if (branchOptions) {
        setData({ branch: branchOptions, isLoading: false });
      }
    });
  };

  const getWorkDays = (branchId: string) => {
    rest.get<IDeliveryWorkDayReq>(API.branch.getWorkDays(branchId)).then(({ data }) => {
      data.workDays?.forEach((item: string) => {
        workDaysOptions.push(new Date(item));
      });
    });
  };

  const getWorkHours = (branchId: string, day: string) => {
    rest.get<IDeliveryWorkHourReq>(API.branch.getWorkHours(branchId, day)).then(({ data }) => {
      data.workHours?.forEach(({ time }: IBranchWorkHours) => {
        branchWorkingTime.push({
          value: time,
          label: time.substring(0, 5),
        });
      });
    });
  };

  useEffect(() => {
    if (processId) {
      rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
        getBranches(data.delivery?.branchServiceId.toString());
      });
    }
  }, []);

  useEffect(() => {
    if (watchField?.branch?.value) {
      setDayInputDisable(false);
      getWorkDays(watchField.branch.value);
      if (watchField.workDate) {
        setTimeInputDisable(false);
        getWorkHours(watchField.branch.value, dateFormatter.display(watchField.workDate, 'yyyy-mm-dd'));
      }
    }
  }, [watchField]);

  const onSubmit = (data: IBranchFormData) => {
    setLoading(true); // This loading waits socket event -> SuccessPage: ESocketEvent.END
    const completeData = {
      processId,
      branchId: data?.branch.id,
      shipmentAddress: data?.branch?.label,
      time: null,
      date: null,
    };
    rest.post<IComplateDeliveryReq, void>(API.loan.completeDelivery, completeData).catch(() => {
      setLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel className="branch-panel" marginTop={6}>
        <span className="branch-panel__box__title-branch"> 1. {translate('delivery.branch')} </span>
        <Panel className="branch-panel__box" marginTop={3}>
          <Controller
            name="branch"
            control={control}
            rules={{
              validate: v => !!v.id,
            }}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  name="branch-select"
                  onChange={onChange}
                  className="input-style-select"
                  styles={customStyle}
                  placeholder={translate('delivery.choose.branch')}
                  options={Object.values(state?.branch)}
                  isSearchable={false}
                  components={{ LoadingMessage }}
                  isLoading={state.isLoading}
                />
              );
            }}
          />
        </Panel>
        {errors?.branch?.type === 'validate' && <div className="error-text">{translate('delivery.choose.branch')}</div>}
        <Panel className="branch-panel__box" marginTop={7}>
          {/* <span className="branch-panel__box__title-date"> 2. {translate('delivery.choose.datetime')} </span> */}
          {/* <Panel className="date-time" marginTop={3}>
            <Panel className={`branch-panel__box__date-time branch-panel__box__date-time__${channelType}`}>
              <Controller
                control={control}
                name="workDate"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    className="input-style-datepicker"
                    placeholderText={translate('common.dmy')}
                    dateFormat="dd/MM/yyy"
                    shouldCloseOnSelect
                    popperPlacement="bottom"
                    selected={value ? new Date(value) : null}
                    includeDates={workDaysOptions}
                    disabled={dayInputDisable}
                    showDisabledMonthNavigation
                    onChange={(date: any) => {
                      onChange(date);
                    }}
                    customInput={<InputMask mask="99/99/9999" placeholder={translate('common.dmy')} />}
                  />
                )}
              />
              {errors?.workDate?.type === 'required' && <div className="error-text">{translate('delivery.choose.date')}</div>}
            </Panel>

            <Panel className="branch-panel__box__date-time">
              <Controller
                name="time"
                control={control}
                rules={{ validate: v => !!v.value }}
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      name="time-select"
                      onChange={onChange}
                      className="input-style-select"
                      styles={customStyle}
                      placeholder={translate('delivery.choose.time')}
                      options={branchWorkingTime}
                      isSearchable={false}
                      isDisabled={timeInputDisable}
                    />
                  );
                }}
              />
              {errors?.time?.type === 'validate' && <div className="error-text">{translate('delivery.choose.time')}</div>}
            </Panel>
          </Panel> */}
          <Panel marginTop={7} className="approve__box">
            <ButtonCommon channelType={channelType} className="approve__box__btn" text={translate('common.actions.confirm')} />
          </Panel>
        </Panel>
      </Panel>
    </form>
  );
};

export default Branch;
