import { Button, EButtonType, EElementSize } from '@kapital-bank/kb-ui-design-system';
import React, { FC } from 'react';
import { ESalesChannel } from '../../enum';

import './index.scss';

interface IProps {
  ref?: any;
  className?: string;
  text?: string;
  size?: string;
  disable?: boolean;
  handleClick?: () => void;
  channelType: ESalesChannel;
}
const ButtonCommon: FC<IProps> = ({ className = '', text, ref, size, disable, handleClick, channelType }) => {
  return (
    <div ref={ref} className={`button-panel ${className}`}>
      <Button
        onClick={handleClick}
        size={EElementSize.LG}
        className={`button-panel__btn bgColor-${channelType} ${size} approve`}
        disabled={disable}
        type={EButtonType.SUBMIT}
      >
        {text}
      </Button>
    </div>
  );
};

export default ButtonCommon;
