import { Styles } from 'react-modal';

export const styleModal: Styles = {
  content: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    zIndex: 120,
    transform: 'translate(-50%, -50%)',
  },
};
