import React, { FC, useEffect, useState } from 'react';
import { EJustifyContent, Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import OTPInput from 'shared/components/OTP';
import ButtonCommon from 'shared/components/ButtonCommon';
import { styleModal } from 'shared/assets/styles/react-modal';
import { translate } from 'shared/utils/translation';
import { useCounter } from 'shared/hooks/useCounter';
import { API } from 'shared/api/loan';
import { useLoading } from 'shared/context/loading';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import WatchIcon from 'shared/assets/images/compressImage/watch-icon.svg';
import { PHONE_NUMBER } from 'shared/consts';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ESalesChannel } from 'shared/enum';
import { IOTP } from 'shared/model';

import './index.scss';

interface IProps {
  openOTPModal: boolean;
  handleOTPModal: () => void;
}

const OTPModal: FC<IProps> = ({ openOTPModal, handleOTPModal }) => {
  const { rest, processId, channelType } = useGeneralInfoContext();
  const [otp, setOtp] = useState<string>();
  const { setLoading } = useLoading();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { minutes, seconds, setStartTimer, setCounter, counter } = useCounter(120);
  const phoneNumber = localStorage.getItem(PHONE_NUMBER);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [otpValues, setOTPValues] = useState(Array<string>(4).fill(''));
  const [activeInput, setActiveInput] = useState(0);
  const formData = {
    processId,
    phoneNumber,
  };

  const startOTP = () => {
    setErrorMessage('');
    setLoading(true);
    setOTPValues(Array<string>(4).fill(''));
    setActiveInput(0);
    rest
      .post<IOTP, void>(API.loan.initOtp, formData)
      .then(() => {
        setStartTimer(true);
        setCounter(60);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (openOTPModal) {
      startOTP();
    }
  }, [openOTPModal]);

  const verifyOtp = () => {
    setLoading(true); // This loading waits socket event -> DeliveryPage: ESocketEvent.DELIVERY
    const OTPData = {
      ...formData,
      otp,
    };
    setRequesting(true);
    setErrorMessage('');
    rest
      .post<IOTP, void>(API.loan.verifyOtp, OTPData, undefined, { skipGlobalError: true })
      .catch(err => {
        setLoading(false);
        if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            setOTPValues(Array<string>(4).fill(''));
            setActiveInput(0);
            setErrorMessage(translate('common.error.wrong_code'));
          } else if (status === 404) {
            setStartTimer(false);
            setCounter(0);
          }
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <ReactModal
      style={styleModal}
      isOpen={openOTPModal}
      onRequestClose={handleOTPModal}
      shouldCloseOnOverlayClick={false}
      className="modal otp-modal"
    >
      <Panel marginBottom={2} paddingTop={2}>
        <Panel className="modal__header" justifyContent={4} alignItems={2}>
          <span className="modal__header__title"> {translate('common.actions.confirm')}</span>
          <img onClick={handleOTPModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
        </Panel>
        <Panel className="modal-line" marginBottom={4} />
        <Panel className="otp-modal__context modal-common-context">
          <div className="otp-modal__context__description">
            {`${translate('formalization.popup.sendSecurityCode.firstPart')} `}
            <span className="bold">
              {`+994 (${phoneNumber?.substr(1, 2)}) ${phoneNumber?.substr(3, 3)} ${phoneNumber?.substr(6, 2)} ${phoneNumber?.substr(8, 2)}`}
            </span>{' '}
            {translate('formalization.popup.sendSecurityCode.secondPart')}
          </div>
          <Panel marginTop={4} className={`otp-modal__form ${channelType === ESalesChannel.KAPITAL_AZ && 'otp-modal__form__kb'}`}>
            <OTPInput
              autoFocus
              isNumberInput
              length={4}
              className="otp-container__wrapper"
              inputClassName="otpInput"
              onChangeOTP={otpValue => setOtp(otpValue)}
              verifyOtp={verifyOtp}
              setOTPValues={setOTPValues}
              otpValues={otpValues}
              setActiveInput={setActiveInput}
              activeInput={activeInput}
            />
            {errorMessage && (
              <Panel marginTop={2} justifyContent={EJustifyContent.CENTER}>
                <div className="error-text error-message">{errorMessage}</div>
              </Panel>
            )}
            {counter === 0 && !requesting && (
              <Panel marginTop={2} justifyContent={EJustifyContent.CENTER}>
                <div className="error-text error-message">{translate('otp.expired')}</div>
              </Panel>
            )}
            <ButtonCommon
              channelType={channelType}
              text={translate('common.actions.confirm')}
              className="otp-modal__form__approve__btn"
              disable={otp?.length !== 4 || counter === 0 || requesting}
              handleClick={verifyOtp}
            />
          </Panel>
          <Panel marginTop={4} alignItems={2} justifyContent={2}>
            <img src={WatchIcon} alt="icon" />
            <span className="otp-modal__context__time"> {`${minutes}:${seconds}`} </span>
            {counter === 0 && (
              <span className={`send__sms__text dangerColor-${channelType} cursor-pointer`} onClick={startOTP} role="none">
                {translate('common.actions.refreshSms')}
              </span>
            )}
          </Panel>
        </Panel>
      </Panel>
    </ReactModal>
  );
};

export default OTPModal;
