import React from 'react';

import NavbarBrendMobile from './MobileNavLink/NavbarBrendMobile';
import NavbarBrendKb from './NavbarBrendKb';
import NavbarKb from './NavbarKb';

const HeaderKb = () => {
  return (
    <header className="header__kb">
      <NavbarBrendMobile />
      <NavbarBrendKb />
      <NavbarKb />
    </header>
  );
};

export default HeaderKb;
