import React from 'react';
import DOMPurify from 'dompurify';
import { Col, EIconName, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import MobileAppsCommon from '../../MobileAppsCommon';
import SocialMediaCommon from '../../SocialMediaCommon';

import Copyright from '../../CopyRight';
import './index.scss';

const FooterKb = () => {
  const params = window.location.search;
  const mobileAppsKb = translate('footer.Kb.mobileAppsKb');

  const socialIconsKb = [
    { icon: EIconName.FACEBOOK, href: 'https://www.facebook.com/kapitalbank' },
    { icon: EIconName.INSTAGRAM, href: 'https://www.instagram.com/kapital_bank/' },
    { icon: EIconName.TWITTER, href: 'https://twitter.com/KapitalBankOJSC' },
    { icon: EIconName.LINKEDIN, href: 'https://az.linkedin.com/company/kapitalbankojsc/' },
    { icon: EIconName.YOUTUBE, href: 'https://www.youtube.com/channel/UCarG-YHqZPd42HPEBqSSmmw' },
  ];

  const getCategory = (path: string) =>
    DOMPurify.sanitize(path).includes('ccl') ? `${DOMPurify.sanitize(path)}${DOMPurify.sanitize(params)}` : DOMPurify.sanitize(path);

  const links = translate('footer.Kb.links').map(({ title, children }: any, i: number) => (
    // eslint-disable-next-line react/no-array-index-key
    <Col xs={6} sm={3} md={3} key={`${i}kbfootercol`}>
      <div className="footer__kb__products__links-list">
        <div className="footer__kb__products__links-list__title" key={`${i}kbfooterheader`}>
          {title}
        </div>
        {children.map(({ title: childTitle, href }: any, i: number) => (
          <a className="footer__kb__products__links-list-item" href={getCategory(href)} key={`${i}kbfooterlink`}>
            {childTitle}
          </a>
        ))}
      </div>
    </Col>
  ));

  const endLinks = translate('footer.Kb.linkFooter').map(({ path, img }: any, i: number) => (
    <a href={DOMPurify.sanitize(path)} className="footer__kb__end__right__item" key={`${i}kbendlink`}>
      <img src={img} alt="footerImg" />
    </a>
  ));

  return (
    <footer className="footer__kb">
      <Panel className="footer__kb__products">
        <Row dataTestId="row">
          <Col md={9} xs={11} className="footer__kb__products__col">
            <Row dataTestId="row" className="footer__kb__products__links">
              {links}
            </Row>
          </Col>
          <Col md={3} xs={12} className="footer__kb__products__media">
            <MobileAppsCommon mobileAppsKb={mobileAppsKb} />
          </Col>
        </Row>
        <Panel className="footer__kb__products__social-media">
          <SocialMediaCommon socialIconsKb={socialIconsKb} />
        </Panel>
      </Panel>

      <Row className="footer__kb__end">
        <Col xs={12} md={6}>
          <Copyright text={translate('footer.Kb.copyright')} className="copyright" />
        </Col>
        <Col sm={12} md={6} className="footer__kb__end__right">
          {endLinks}
        </Col>
      </Row>
    </footer>
  );
};

export default FooterKb;
