import React, { FC } from 'react';
import { Button, Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import ButtonCommon from 'shared/components/ButtonCommon';
import { styleModal } from 'shared/assets/styles/react-modal';
import { translate } from 'shared/utils/translation';
import { API } from 'shared/api/loan';
import { ICustomerInfoReq } from 'shared/model';
import { useLoading } from 'shared/context/loading';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  openQuestionnaireVerificationModal: boolean;
  handleCloseQuestionnaireVerificatioModal: () => void;
  submitData?: any;
}

const QuestionnaireVerification: FC<IProps> = ({ openQuestionnaireVerificationModal, handleCloseQuestionnaireVerificatioModal, submitData }) => {
  const { rest, channelType } = useGeneralInfoContext();
  const { setLoading } = useLoading();
  const submitFalseFatca = () => {
    setLoading(true); // This loading waits socket event -> TeleSalesSuccessPage: ESocketEvent.ERROR
    rest
      .post<ICustomerInfoReq, void>(API.loan.confirmInfo, submitData, undefined, { errorTitle: translate('common.error.notRegistered') })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <ReactModal
      style={styleModal}
      isOpen={openQuestionnaireVerificationModal}
      onRequestClose={handleCloseQuestionnaireVerificatioModal}
      shouldCloseOnOverlayClick={false}
      className="questionnaire__reload__modal modal"
    >
      <Panel className="modal__header" justifyContent={4} alignItems={2}>
        <span className="modal__header__title"> {translate('checkInfo.modal.title')} </span>
        <img onClick={handleCloseQuestionnaireVerificatioModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
      </Panel>
      <Panel className="modal-line" />
      <Panel className="modal-common-context">
        <Panel justifyContent={2} paddingTop={2} marginBottom={5} className="questionnaire__reload__modal__content">
          <span className="questionnaire__reload__modal__content__textColor-bb">{translate('checkInfo.modal.info')}</span>
        </Panel>
        <Panel className="questionnaire__reload__modal-buttons">
          <Panel className="questionnaire__reload__modal-buttons__reject">
            <Button className="verification__reject" onClick={handleCloseQuestionnaireVerificatioModal}>
              {translate('actions.returnSurvey')}
            </Button>
          </Panel>
          <Panel className="questionnaire__reload__modal-buttons__approve">
            <ButtonCommon
              channelType={channelType}
              text={translate('common.actions.confirm')}
              handleClick={submitFalseFatca}
              className="verification__approve"
            />
          </Panel>
        </Panel>
      </Panel>
    </ReactModal>
  );
};
export default QuestionnaireVerification;
