import React, { useEffect } from 'react';
import { CircularProgressbar, EElementView, Panel } from '@kapital-bank/kb-ui-design-system';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { EProductCode } from 'shared/enum';
import { useCounter } from 'shared/hooks/useCounter';
import { translate } from 'shared/utils/translation';

import HeadingText from '../HeadingText';
import ProgressBar from '../ProgressBar';

import './index.scss';

const Loading = () => {
  let counterDurationSec = 120;
  const PRE_SITE_HOST = 'pre.ui-credit.kapitalbank.az';
  if (window.location.hostname === PRE_SITE_HOST) {
    counterDurationSec = 300;
  }
  const { channelType, productCode } = useGeneralInfoContext();
  const { minutes, seconds, setStartTimer, setCounter } = useCounter(counterDurationSec);

  useEffect(() => {
    setStartTimer(true);
    if (minutes === '0' && seconds === '0') setCounter(counterDurationSec);
  }, []);

  useEffect(() => {
    if (window.screen.width < 576) {
      window.scrollTo(0, 450);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Panel className="loading order-step-container">
      <ProgressBar progress={30} {...{ channelType }} />
      <HeadingText
        text={
          productCode === EProductCode.GTKR ? (
            <span>
              {translate('titles.cash.firstPart')} <span className={`dangerColor-${channelType}`}>{translate('titles.cash.secondPart')} </span>
              {translate('titles.cash.thirdPart')}
            </span>
          ) : (
            <span>
              {translate('titles.birKart.firstPart')} <span className={`dangerColor-${channelType}`}>{translate('titles.birKart.secondPart')} </span>
              {translate('titles.birKart.thirdPart')}
              <span className={`dangerColor-${channelType}`}>{` ${translate('titles.birKart.fourthPart')}`}</span>
              {` ${translate('titles.birKart.fifthPart')}`}
            </span>
          )
        }
      />
      <Panel className={`loading__common loading__loader-${channelType}`}>
        <CircularProgressbar
          infinite
          message={<span className="time">{`${minutes}:${seconds}`}</span>}
          percentage={70}
          sqSize={120}
          strokeWidth={10}
          view={EElementView.DANGER}
        />
      </Panel>
      <Panel className="loading__description"> {translate('common.info.loadingMessage')}</Panel>
    </Panel>
  );
};

export default Loading;
