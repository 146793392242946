import { Panel } from '@kapital-bank/kb-ui-design-system';
import React, { FC } from 'react';
import ButtonCommon from 'shared/components/ButtonCommon';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { redirect } from 'shared/utils/redirect';
import { translate } from 'shared/utils/translation';
import { IFinalProcessInfoDetails } from 'shared/model';

import SkeletonProcessInfo from './SkeletonProcessInfo';

import './index.scss';

interface IProps {
  processInfoDetails: IFinalProcessInfoDetails;
}

const ProcessInfo: FC<IProps> = ({ processInfoDetails }) => {
  const { channelType } = useGeneralInfoContext();

  return processInfoDetails.image ? (
    <Panel className="process-info">
      <Panel className="process-info__header">
        <img src={processInfoDetails.image} alt="success" />
        <Panel className="process-info__header__title">{processInfoDetails.title}</Panel>
      </Panel>
      <Panel className={`process-info__content ${processInfoDetails.hasError ? 'text-center' : ''}`}>{processInfoDetails.content}</Panel>
      <Panel className="process-info__footer">
        <ButtonCommon
          className="process-info__footer__btn"
          text={processInfoDetails.btnText}
          channelType={channelType}
          handleClick={() => redirect(processInfoDetails.btnLink)}
        />
        {processInfoDetails.hasHomeLink && (
          <span onClick={() => redirect(processInfoDetails.homeLink || 'https://kaitalbank.az')} role="none">
            {translate('finalPage.returnHomePage')}
          </span>
        )}
      </Panel>
    </Panel>
  ) : (
    <SkeletonProcessInfo />
  );
};

export default ProcessInfo;
