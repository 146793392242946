export const MAIN_PATH = `/:lang/order/:productCode`;
export const OLD_FIRST_PAGE_PATH = `/order/step/:productCode`;
export const OLD_FIRST_PAGE_LANG_PATH = `/:lang/order/step/:productCode`;
export const OLD_STEP_LOADING_PATH = `/order/step-loading/:productCode`;

export const ROUTES = {
  ROOT: {
    PATH: `${MAIN_PATH}/*`,
  },
  MAIN: {
    PATH: `/*`,
  },
  PIN: {
    PATH: `/`,
  },
  OTP: {
    PATH: `/otp`,
  },
  LOADING: {
    PATH: `/loading`,
  },
  SCORING: {
    PATH: `/scoring`,
  },
  ORDER_FORM: {
    PATH: `/check-info`,
  },
  STEP_LOADING: {
    PATH: `/step-loading`,
  },
  FORMALIZATION_OPTION: {
    PATH: `/formalization-option`,
  },
  FORMALIZING_CARD: {
    PATH: `/formalizing-card`,
  },
  DELIVERY: {
    PATH: `/delivery`,
  },
  FINAL: {
    PATH: `/end`,
  },
};
