import React, { FC, useEffect, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { IBranchData, IDeliveryBranchReq } from 'shared/model';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { API } from 'shared/api/loan';
import { translate } from 'shared/utils/translation';
import { ELang } from 'shared/enum';
import LoadingMessage from '../ReactSelect/LoadingMessage';

import './index.scss';

interface IProps {
  control: Control;
}
const GiftCardBranchSelector: FC<IProps> = ({ control }) => {
  const { channelType, rest } = useGeneralInfoContext();
  const [branches, setBranches] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const { lang } = useGeneralInfoContext();

  const branchOptions: { value: string; label: string; id: string }[] = [];
  const getBranches = (serviceID: string) => {
    rest.get<IDeliveryBranchReq>(API.branch.getBranch(serviceID)).then(({ data }) => {
      data.branches?.forEach(({ id, serviceId, name }: IBranchData) => {
        branchOptions.push({
          value: serviceId,
          label: name,
          id,
        });
      });
      setLoading(false);
      if (branchOptions) {
        setBranches(branchOptions);
      }
    });
  };
  useEffect(() => {
    getBranches('172');
  }, []);
  return (
    <Panel>
      <Panel className="branch_giftform__header_container gift-section" justifyContent={0} alignItems={2}>
        <div className={`branch_giftform__header_container__gift_img ${ELang.RU === lang && 'lang_ru'}`}>
          <span className="branch_giftform__header_container_gift_img__text">{translate('common.gift')}</span>
        </div>
        <Panel className="branch_giftform__header_container__img">
          <img src={API.loan.getImage('BRCB_BLACK_MINI')} alt="cashback card" />
        </Panel>
        <h2 className="branch_giftform__header_container__text">{translate('formalization.creditOptionTitleGift')}</h2>
      </Panel>

      <Panel className="bgwhite branch-for-gift">
        <Panel className="branch_giftform__type-item" onClick={() => {}} justifyContent={0} padding={3} flexDirection={2}>
          <Panel justifyContent={0}>
            <Panel className="branch_giftform__type-item__photo">
              <img src={`/assets/images/compressImage/yes-filial-${channelType}.svg`} alt="filial" />
            </Panel>
            <Panel className="branch_giftform__type-item__context">
              <Panel className="branch_giftform__type-item__context__title">{translate('common.fromBranch')}</Panel>
              <Panel className="branch_giftform__type-item__context__description">{translate('formalization.infoGiftCardBranch')}</Panel>
              <Panel className="branch-panel__box" marginTop={3}>
                <Controller
                  name="branch"
                  control={control}
                  rules={{
                    validate: v => !!v.id,
                  }}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        name="branch-select"
                        onChange={onChange}
                        className="input-style-select"
                        placeholder={translate('delivery.choose.branch')}
                        options={Object.values(branches)}
                        isSearchable={false}
                        components={{ LoadingMessage }}
                        isLoading={loading}
                      />
                    );
                  }}
                />
              </Panel>
              {/* {errors?.branch?.type === 'validate' && <div className="error-text">{translate('delivery.choose.branch')}</div>} */}
            </Panel>
          </Panel>
        </Panel>
        {/* Deactivated for MVP */}
        {/* <Divider height={1} />
                  <Panel className="branch-for-gift__switch" paddingLeft={3} paddingRight={3} justifyContent={0}>
                    <Panel className="branch-for-gift__switch__placeholder_img">
                      <img src={`/assets/images/compressImage/yes-filial-${channelType}.svg`} alt="filial" />
                    </Panel>
                    <Switch
                      value={cancelGiftCard}
                      onChange={value => setCancelGiftCard(value)}
                      label={translate('formalization.doNotWantGiftCard')}
                      size={BaseSizes.S}
                    />
                  </Panel> */}
      </Panel>
    </Panel>
  );
};

export default GiftCardBranchSelector;
