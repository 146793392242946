import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Col, Panel, Row } from '@kapital-bank/kb-ui-design-system';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import ReactTooltip from 'react-tooltip';
import { translate } from 'shared/utils/translation';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  percent?: number;
  maxAmount: number;
  bankGuaranteeCommissionRate?: number;
  cashCommissionRate?: number;
  availableFinalAmount?: number;
  amount: number;
  minAmount: number;
  bluePeriod: boolean;
  monthlyPayment?: number;
  debtor: boolean;
  creditServiceCommission: number;
  unAdjustedInterestRate: number;
}

const AmountInfo: FC<IProps> = ({
  percent,
  maxAmount,
  bluePeriod,
  minAmount,
  bankGuaranteeCommissionRate,
  cashCommissionRate,
  availableFinalAmount,
  amount,
  monthlyPayment,
  debtor,
  creditServiceCommission,
  unAdjustedInterestRate,
}) => {
  const toolTipRef = useRef<any>(null);
  const { channelType } = useGeneralInfoContext();

  const renderInterestRate = useMemo(() => {
    const hasUnAdjustedInterestRate = !!unAdjustedInterestRate;
    return (
      <div className="interest-rate">
        {hasUnAdjustedInterestRate ? (
          <>
            <span className="interest-rate-prev">{unAdjustedInterestRate}%</span>
            <span className="is-special un-adjusted-interest-rate">{percent}%</span>
            <div className="rate-message-wrapper">
              <span className="is-special rate-message">({translate('common.specialForYou')})</span>
            </div>
          </>
        ) : (
          <span>{percent}%</span>
        )}
      </div>
    );
  }, [percent, unAdjustedInterestRate]);

  const exceedsLimits = () => {
    return amount < minAmount || amount > maxAmount;
  };
  const creditDetails = {
    leftColumnData: [
      { id: 0, title: translate('common.monthPayment'), value: `${exceedsLimits() ? '-' : monthlyPayment} AZN` },
      { id: 1, title: translate('calculator.totalAmount'), value: `${exceedsLimits() ? '-' : amount} AZN` },
      { id: 2, title: translate('common.annualInterestRate'), value: renderInterestRate },
    ],
    rightColumnData: [
      { id: 0, title: translate('calculator.guarantyCommission'), value: `${bankGuaranteeCommissionRate}%` },
      { id: 1, title: translate('calculator.cashingCommission'), value: `${cashCommissionRate}%` },
      { id: 2, title: translate('calculator.creditServiceCommission'), value: `${creditServiceCommission}%` },
    ],
  };
  useEffect(() => {
    if (window.matchMedia('(max-width: 427px)').matches) {
      const toolTipStyle = toolTipRef.current.style || {};
      toolTipRef.current.style = { ...toolTipStyle, left: '28px' };
    }
  }, []);
  return (
    <Panel className="amount-info-box">
      <Panel className="amount-info-box__content">
        <Panel className="amount-info-box__content__details">
          <Row>
            <Col className="amount-info-box__content__col" xs={6} md={6} sm={6}>
              {creditDetails.leftColumnData.map(({ id, title, value }) => (
                <Panel className="amount-info-box__content__items" key={id}>
                  <div className="amount-info-box__content__title">{title}</div>
                  <div
                    className={`amount-info-box__content__value ${
                      title === translate('common.annualInterestRate') && `${bluePeriod ? 'scoring-blue' : `dangerColor-${channelType}`}`
                    }`}
                  >
                    {value}
                  </div>
                </Panel>
              ))}
            </Col>
            <Col className="amount-info-box__content__col" xs={6} md={6} sm={6}>
              {creditDetails.rightColumnData.map(({ id, title, value }) => (
                <Panel key={id}>
                  <div className="amount-info-box__content__title">{title}</div>
                  <div className="amount-info-box__content__value">{value}</div>
                </Panel>
              ))}
            </Col>
          </Row>
        </Panel>
        <Panel className="amount-info-box__content__finally">
          <span className="amount-info-box__content__title">{translate('calculator.finalAmount')}</span>
          <span className="info-img">
            <img src={InfoSolid} alt="info" data-tip data-for="registerTip" />
          </span>
          <div className="amount-info-box__content__value">{exceedsLimits() ? '-' : availableFinalAmount} AZN </div>
        </Panel>
        <ReactTooltip ref={toolTipRef} id="registerTip" type="info" place="top" effect="solid" backgroundColor="#ffff">
          {debtor ? translate('calculator.advanceDebtInfo') : translate('calculator.hasntdebt')}
        </ReactTooltip>
      </Panel>
    </Panel>
  );
};

export default AmountInfo;
