import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Input, Panel } from '@kapital-bank/kb-ui-design-system';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Skeleton from 'react-loading-skeleton';

import { prefixOptions } from 'shared/consts/select-options';
import { numberFormatter, numberValidator } from 'shared/utils/numberFormatter';
import ReactSelect from 'shared/components/ReactSelect';
import ButtonCommon from 'shared/components/ButtonCommon';
import { translate } from 'shared/utils/translation';
import { API } from 'shared/api/loan';
import { useLoading } from 'shared/context/loading';
import { ESalesChannel } from 'shared/enum';
import { checkDuplicateCharacters, onSanitizeValue } from 'shared/utils/helpers';
import ProgressBar from 'shared/components/ProgressBar';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { PHONE_NUMBER } from 'shared/consts';
import {
  ICreditFormData,
  ICustomerInfoReq,
  IDetails,
  IProductData,
  IQuestion,
  IQuestionOption,
  IQuestionOptionRs,
  IQuestionRes,
  IQuestionRs,
} from 'shared/model';

import anketKB from 'shared/assets/images/compressImage/anket-kb.svg';
import anketBB from 'shared/assets/images/compressImage/anket-bb.svg';
import anketUM from 'shared/assets/images/compressImage/anket-um.svg';

import Questionnaire from '../Questionnaire';
import QuestionnaireVerification from '../QuestionnaireVerification';
import AgreementModalCheckInfo from '../AgreementModalCheckInfo';

import './index.scss';

interface IProps {
  productInfo?: IProductData;
  orderDetails?: IDetails;
}

interface IPrefixProps {
  value?: string;
}

const CreditConfirm: FC<IProps> = ({ productInfo, orderDetails }) => {
  const { setLoading } = useLoading();
  const { rest, processId, channelType } = useGeneralInfoContext();
  const [defaultFactualAddress, setDefaultFactualAddress] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [hasYesAnswer, setHasYesAnswer] = useState(false);
  const [answerCheck, setAnswerCheck] = useState(true);
  const [dataQuestionnaire, setDataQuestionnaire] = useState<IQuestion[]>([]);
  const [openQuestionnaireModal, setQuestionnaireModal] = useState<boolean>(false);
  const [openQuestionnaireVerificationModal, setQuestionnaireVerificationModal] = useState<boolean>(false);
  const [openModal, setModal] = useState<boolean>(false);
  const phoneN1Ref = useRef<HTMLInputElement>();
  const phoneN2Ref = useRef<HTMLInputElement>();
  const [sameAddress, setSameAddress] = useState<boolean>(true);

  const handleCloseModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setModal(!openModal);
  };

  const handleKeyDown = (e: { keyCode: number; preventDefault: () => void }) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const amount = useMemo(() => {
    return orderDetails?.product?.amount;
  }, [orderDetails]);

  const [submitData, setSubmitData] = useState({
    processId,
    additionalNumber1: '',
    additionalNumber2: '',
    fatca: null,
    factualAddress: '',
  });

  const handleCloseQuestionnaireModal = () => {
    setQuestionnaireModal(!openQuestionnaireModal);
    if (!dataQuestionnaire.length) {
      rest.get<IQuestionRes>(API.questionnaire.getQuestionnaire).then(res => {
        if (res) {
          const formattedQuestionsData = res?.data?.questionnaires.map((question: IQuestionRs) => {
            return {
              ...question,
              questionOptions: question.questionOptions.map((questionOption: IQuestionOptionRs) => {
                return {
                  ...questionOption,
                  selected: questionOption.id === question.defaultOptionId,
                };
              }),
            };
          });
          setDataQuestionnaire(formattedQuestionsData);
        }
      });
    }
  };

  const handleCloseQuestionnaireVerificatioModal = () => {
    setQuestionnaireVerificationModal(!openQuestionnaireVerificationModal);
    if (openQuestionnaireVerificationModal) {
      if (!dataQuestionnaire.length) {
        handleCloseQuestionnaireModal();
      } else {
        setQuestionnaireModal(!openQuestionnaireModal);
      }
    }
  };

  useEffect(() => {
    setFullName(orderDetails?.customer?.maskedFullName || '');
    setDefaultFactualAddress(orderDetails?.customer?.address || '');
  }, [orderDetails]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm<ICreditFormData>({
    defaultValues: {
      prefix1: { value: '', label: '(_ _ _)' },
      prefix2: { value: '', label: '(_ _ _)' },
      additionalNumber1: '',
      additionalNumber2: '',
      hasYesAnswer: true,
      accept: true,
      factualAddress: '',
    },
  });

  const watchField = watch();

  const additionalNumber =
    watchField.additionalNumber1 !== '' &&
    watchField.prefix1.value === watchField.prefix2.value &&
    watchField.additionalNumber1 === watchField.additionalNumber2;

  const validatePrefix = (option: IPrefixProps) => {
    if (!option.value) {
      return translate('common.error.prefixNumber');
    }
    return true;
  };

  useEffect(() => {
    setLoading(false); // Hide loading that was set in ScoringCard onSubmit
  }, []);

  useEffect(() => {
    setValue('factualAddress', defaultFactualAddress || '');
  }, [defaultFactualAddress]);

  const onSameAddressCheck = async (value: boolean) => {
    setSameAddress(value);
    if (value) {
      setValue('factualAddress', defaultFactualAddress);
      if (defaultFactualAddress) {
        await trigger('factualAddress');
      }
    } else {
      setValue('factualAddress', '');
    }
  };

  useEffect(() => {
    let hasAYesAnswer = false;
    let isCheck = true;
    dataQuestionnaire.forEach((question: IQuestion) => {
      question?.questionOptions.forEach((questionOption: IQuestionOption) => {
        // 1 is radio yes; 3 is checkbox
        if (questionOption.id === 1 && questionOption.selected) {
          hasAYesAnswer = true;
        }
        isCheck = questionOption.id === 3 && questionOption.selected;
      });
    });
    setHasYesAnswer(hasAYesAnswer);
    setAnswerCheck(isCheck);
  }, [dataQuestionnaire]);

  const onSubmit = (data: ICreditFormData) => {
    if (hasYesAnswer || !answerCheck) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSubmitData(prevState => ({
        ...prevState,
        factualAddress: data?.factualAddress,
        additionalNumber1: numberFormatter(data?.prefix1?.value || '', data?.additionalNumber1),
        additionalNumber2: numberFormatter(data?.prefix2?.value || '', data?.additionalNumber2),
        fatca: false,
      }));
      handleCloseQuestionnaireVerificatioModal();
    } else if (!hasYesAnswer && !additionalNumber) {
      const customerInfo = {
        processId,
        factualAddress: data.factualAddress,
        additionalNumber1: numberFormatter(data.prefix1.value || '', data.additionalNumber1),
        additionalNumber2: numberFormatter(data.prefix2.value || '', data.additionalNumber2),
        fatca: true,
      };
      setLoading(true); // This loading waits socket event -> dvs redirect: DVS
      rest.post<ICustomerInfoReq, void>(API.loan.confirmInfo, customerInfo).catch(() => {
        setLoading(false);
      });
    }
  };

  // phone focus
  const setInputRef1 = (el: HTMLInputElement | null) => {
    phoneN1Ref.current = el ?? undefined;
  };
  const setInputRef2 = (el: HTMLInputElement | null) => {
    phoneN2Ref.current = el ?? undefined;
  };

  const num = useRef<any>({});
  const num1 = useRef<any>({});
  const num2 = useRef<any>({});

  const pre = useRef<any>({});
  const pre1 = useRef<any>({});
  const pre2 = useRef<any>({});

  const orderPhoneNum = localStorage.getItem(PHONE_NUMBER);

  return (
    <Panel className={`order__form order__form__${channelType}`}>
      <Panel marginTop={7} marginBottom={7}>
        <ProgressBar progress={60} channelType={channelType} />
      </Panel>
      <Panel className="order__form__title">
        <span className={`dangerColor-${channelType}`}>{amount} AZN</span>
        <span>{productInfo?.title}</span>
      </Panel>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel className="order__form__title-child">1. {translate('checkInfo.checkInfo')}</Panel>
        <Panel className="order__form__user">
          <span className="order__form__user__label">{translate('checkInfo.fullName')}</span>
          {fullName ? (
            <Input disabled value={fullName} className="order__form__user__name" />
          ) : (
            <Skeleton height={30} className="order__form__user__skeleton" />
          )}
        </Panel>

        <Panel className="order__form__title-child">{translate('checkInfo.fillAdditionalInfo')}</Panel>
        <Panel>
          <Panel className="order__form__location">
            <Controller
              name="factualAddress"
              control={control}
              rules={{ required: true, minLength: 10 }}
              render={({ field: { name, onChange, value } }) => {
                return (
                  <Input
                    disabled={sameAddress}
                    name={name}
                    onChange={onChange}
                    value={value}
                    label={translate('checkInfo.location')}
                    className="input-block order__form__location_input"
                  />
                );
              }}
            />
          </Panel>
          {errors?.factualAddress?.type === 'required' && <div className="error-text">{translate('common.error.fillFactualAddress')}</div>}
          {errors?.factualAddress?.type === 'minLength' && <div className="error-text">{translate('common.error.factualAddressLength')}</div>}
          <Panel>
            <Checkbox className={`checkbox-${channelType}`} value={sameAddress} onChange={() => onSameAddressCheck(!sameAddress)}>
              <span className="order__form__location__description__text">Yaşayış ünvanı qeydiyyat ünvanımla eynidir.</span>
            </Checkbox>
          </Panel>
        </Panel>
        <Panel className="order__form__title-child">
          <span>2. {translate('checkInfo.otherNumber')}</span>
          <span>{translate('checkInfo.relation')}</span>
        </Panel>

        <Panel className="order__form__mobile" justifyContent={4} marginTop={2}>
          <Panel className="order__form__mobile__box">
            <Panel className="order__form__mobile__box__phone">
              <div className="order__form__mobile__box__phone__label">
                <span className={`phone-number__label__color-${channelType}`}>{`${translate('checkInfo.phoneNumber')} №1`} </span>
              </div>
              <Panel
                justifyContent={0}
                alignItems={2}
                className={`order__form__mobile__box__phone__mask ${!watchField.prefix1.label?.includes('_') ? 'order-phone-box-full' : ''}`}
              >
                <Controller
                  name="prefix1"
                  control={control}
                  rules={{
                    required: true,
                    validate: validatePrefix,
                  }}
                  render={({ field: { name, onChange, value } }) => (
                    <ReactSelect
                      name={name}
                      value={value}
                      options={prefixOptions}
                      classNamePrefix="order__form__mobile__box__phone__mask__select"
                      onChange={e => {
                        phoneN1Ref.current?.focus();
                        onChange(e);
                      }}
                      isSearchable={false}
                      asPrefix
                    />
                  )}
                />
                <Controller
                  name="additionalNumber1"
                  control={control}
                  rules={{
                    required: true,
                    validate: value => {
                      if (
                        pre1.current.value + value === pre.current.value + num.current ||
                        pre1.current.value + value === pre2.current.value + num2.current
                      ) {
                        return translate('common.error.samePhone');
                      }
                      if (watchField.prefix1.value + value.replaceAll(' ', '') === orderPhoneNum) {
                        return translate('common.error.checkOrderNumWithAddNum');
                      }
                      if (!checkDuplicateCharacters(value)) {
                        return translate('common.error.correctPhone');
                      }
                      if (numberValidator(value)) {
                        return translate('common.error.phoneNumber');
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, name, onChange, value } }) => {
                    return (
                      <InputMask
                        ref={ref}
                        name={name}
                        onChange={e => {
                          onChange(onSanitizeValue(e));
                        }}
                        defaultValue={value}
                        onKeyDown={handleKeyDown}
                        className="order__form__mobile__box__phone__mask__input"
                        mask="999 99 99"
                        type="tel"
                        placeholder="___ __ __"
                        inputRef={setInputRef1}
                      />
                    );
                  }}
                />
              </Panel>
            </Panel>
            {errors?.additionalNumber1?.type === 'required' && <div className="error-text">{translate('common.error.important')}</div>}
            {errors?.additionalNumber1?.type === 'validate' && <div className="error-text">{errors?.additionalNumber1.message}</div>}
            {errors?.additionalNumber1?.type === 'minLength' && <div className="error-text">{translate('common.error.phoneNumber')}</div>}
            {errors?.prefix1?.type === 'validate' && !errors?.additionalNumber1 && <div className="error-text">{errors?.prefix1.message}</div>}
          </Panel>
          <Panel className="order__form__mobile__box">
            <Panel className={`order__form__mobile__box__phone order__form__mobile__box__phone__${channelType}`}>
              <div className="order__form__mobile__box__phone__label">
                <span className={`phone-number__label__color-${channelType}`}>{`${translate('checkInfo.phoneNumber')} №2`} </span>
              </div>
              <Panel
                justifyContent={0}
                alignItems={2}
                className={`order__form__mobile__box__phone__mask ${!watchField.prefix2.label?.includes('_') ? 'order-phone-box-full-second' : ''}`}
              >
                <Controller
                  name="prefix2"
                  control={control}
                  rules={{
                    required: true,
                    validate: validatePrefix,
                  }}
                  render={({ field: { name, onChange, value } }) => (
                    <ReactSelect
                      name={name}
                      value={value}
                      options={prefixOptions}
                      classNamePrefix="order__form__mobile__box__phone__mask__select"
                      onChange={e => {
                        phoneN2Ref.current?.focus();
                        onChange(e);
                      }}
                      isSearchable={false}
                      asPrefix
                    />
                  )}
                />
                <Controller
                  name="additionalNumber2"
                  control={control}
                  rules={{
                    required: true,
                    validate: value => {
                      if (
                        pre2.current.value + value === pre1.current.value + num1.current ||
                        pre2.current.value + value === pre.current.value + num.current
                      ) {
                        return translate('common.error.samePhone');
                      }
                      if (watchField.prefix2.value + value.replaceAll(' ', '') === orderPhoneNum) {
                        return translate('common.error.checkOrderNumWithAddNum');
                      }
                      if (!checkDuplicateCharacters(value)) {
                        return translate('common.error.correctPhone');
                      }
                      if (numberValidator(value)) {
                        return translate('common.error.phoneNumber');
                      }
                      return true;
                    },
                  }}
                  render={({ field: { ref, name, onChange, value } }) => {
                    return (
                      <InputMask
                        ref={ref}
                        name={name}
                        onChange={e => {
                          onChange(onSanitizeValue(e));
                        }}
                        defaultValue={value}
                        onKeyDown={handleKeyDown}
                        className="order__form__mobile__box__phone__mask__input"
                        mask="999 99 99"
                        type="tel"
                        placeholder="___ __ __"
                        inputRef={setInputRef2}
                      />
                    );
                  }}
                />
              </Panel>
            </Panel>
            {additionalNumber && <div className="error-text">Nömrələr təkrarlana bilməz</div>}
            {errors?.additionalNumber2?.type === 'required' && <div className="error-text">{translate('common.error.important')}</div>}
            {errors?.additionalNumber2?.type === 'minLength' && <div className="error-text">{translate('common.error.phoneNumber')}</div>}
            {errors?.additionalNumber2?.type === 'validate' && <div className="error-text">{errors?.additionalNumber2.message}</div>}
            {errors?.prefix2?.type === 'validate' && !errors?.additionalNumber2 && <div className="error-text">{errors?.prefix2.message}</div>}
          </Panel>
        </Panel>
        <Panel className="order__form__title-child">3. {translate('checkInfo.questionnaire.title')}</Panel>

        <Panel className="order__form__questionnaire">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
          <a onClick={handleCloseQuestionnaireModal}>
            <img src={channelType === ESalesChannel.BIRBANK_AZ ? anketBB : channelType === ESalesChannel.UMICO ? anketUM : anketKB} alt="anketInfo" />
            <span className={`order__form__anket__link dangerColor-${channelType}`}>{translate('checkInfo.questionnaire.pass')}</span>
          </a>
          <Panel className="order__form__questionnaire__description" marginTop={1}>
            <Controller
              control={control}
              name="accept"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox {...{ onChange, onBlur, value }} className={`order__form__questionnaire__check checkbox-${channelType}`}>
                  <span className="order__form__questionnaire__description__text">{translate('checkInfo.questionnaire.confirm')}</span>
                </Checkbox>
              )}
            />
            {errors?.accept && <div className="error-text">{translate('common.actions.checked')}</div>}
          </Panel>
        </Panel>
        <Panel className="order__form__facereq-text">{translate('checkInfo.faceReqInfo')}</Panel>
        <Panel className="order__form__buttons" flexDirection={0} justifyContent={4}>
          <Panel className="order__form__buttons__reject">
            <Button onClick={handleCloseModal}>{translate('common.actions.reject')}</Button>
          </Panel>
          <Panel className="order__form__buttons__approve">
            <ButtonCommon text={translate('common.actions.confirm')} channelType={channelType} />
          </Panel>
        </Panel>

        <div>
          <Questionnaire
            handleCloseQuestionnaireModal={handleCloseQuestionnaireModal}
            openQuestionnaireModal={openQuestionnaireModal}
            dataQuestionnaire={dataQuestionnaire}
            setDataQuestionnaire={setDataQuestionnaire}
          />
          <QuestionnaireVerification
            openQuestionnaireVerificationModal={openQuestionnaireVerificationModal}
            handleCloseQuestionnaireVerificatioModal={handleCloseQuestionnaireVerificatioModal}
            submitData={submitData}
          />
        </div>
      </form>
      <AgreementModalCheckInfo openModal={openModal} setModal={setModal} handleCloseModal={handleCloseModal} />
    </Panel>
  );
};

export default CreditConfirm;
