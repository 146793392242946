import { StylesConfig } from 'react-select';
import { CSSProperties } from 'react';
// Colors
const darkColor = '#25282B';
const grayColor = '#A0A4A8';
const backgroundColorSelect = '#f8f8f8';
const backgroundColorOption = '#e7e8ea';
const borderSelect = `1px solid #ECEDEF`;
const inActive = '#d2d3d6';
const boxShadow = '0px 1px 4px rgba(0, 0, 0, 0.25)';

export const getCustomStyles: (styles?: CSSProperties) => StylesConfig = (styles = {}) => ({
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided, props) => ({
    ...provided,
    transition: 'all .2s ease',
    paddingTop: '0px',
    paddingBottom: '0px',
    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    color: props.isDisabled ? grayColor : darkColor,
    '&:hover': {
      color: darkColor,
    },
  }),
  control: (provided, props) => {
    return {
      ...provided,
      width: styles.width || '100%',
      marginBottom: styles.marginBottom,
      fontSize: '18px',
      fontWeight: styles.fontWeight || 400,
      height: styles.height || '3.75rem',
      minHeight: styles.minHeight || '3.75rem',
      borderRadius: '0.6125rem',
      border: styles.border || borderSelect,
      boxShadow: 'none',
      backgroundColor: props.selectProps.isDisabled ? inActive : '#fff',
      '&:hover': {
        border: styles.border || borderSelect,
      },
    };
  },
  menu: provided => ({
    ...provided,
    fontWeight: 400,
    width: styles.width || '100%',
    marginTop: '0px',
    borderRaius: '0.25rem',
    padding: '0.5rem 0',
    boxShadow,
    zIndex: '2',
  }),
  option: (provided, props) => ({
    ...provided,
    color: darkColor,
    padding: '1rem 0.5rem',
    fontSize: '1.125rem',
    backgroundColor: props.isSelected ? backgroundColorSelect : 'none',
    '&:hover': {
      backgroundColor: backgroundColorOption,
    },
  }),
  singleValue: provided => ({
    ...provided,
    width: styles.width || '100%',
    color: darkColor,
    fontSize: '1.125rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: darkColor,
    fontSize: '1.125rem',
  }),
});

export const prefixStyles: CSSProperties = {
  border: 'none',
  width: '5.6rem',
  height: '1.5625rem',
  minHeight: '1.5625rem',
  paddingTop: '0px',
  paddingBottom: '0px',
};
