import React, { FC } from 'react';
import { Col, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import Container from 'shared/components/Container';
import { EProductCode, ESalesChannel } from 'shared/enum';
import { IProductData } from 'shared/model';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import SkeletonProductInfo from './Skeleton';

import './index.scss';

interface IProps {
  productData: IProductData;
}

const ProductInfo: FC<IProps> = ({ productData }) => {
  const { productCode, channelType } = useGeneralInfoContext();

  const titleBrend = productData?.title?.substring(0, productData?.title?.indexOf(' '));
  const titleDescription = productData?.title?.substring(productData?.title?.indexOf(' '), productData?.title?.length);

  const cashloanAmountInfo = productData?.details?.map(({ title, info }: any, index: number) => (
    <Col md={3} key={`${index * index}productItem`} xs={6} className="cashloan-info__container__content__tarif-list__item">
      <Panel>{info}</Panel>
      <Panel>{title}</Panel>
    </Col>
  ));

  const imgClassBox = productCode === EProductCode.GTKR ? 'cash-img-box' : 'credit-img-box';
  const imgClass = productCode === EProductCode.GTKR ? 'cash-img-box__img' : 'credit-img-box__img';

  return productData?.title ? (
    <Panel className={`cashloan-info product-bgColor__${channelType}`}>
      <Container className="cashloan-info__container">
        {channelType === ESalesChannel.BIRBANK_AZ && <div className="bg-birbank-mark" />}
        <Panel className={`${imgClassBox} cash-img-box__${channelType}`}>
          <img src={productData?.icon} alt="card" className={imgClass} />
        </Panel>
        <Panel dataTestId="row" className={`cashloan-info__container__content cashloan-info__container__content__${channelType}`}>
          <Panel className="cashloan-info__container__content__header">
            <Panel className="cashloan-info__container__content__header__title">
              <span className={`dangerColor-${channelType}`}> {titleBrend} </span>
              <span> {titleDescription} </span>
            </Panel>
            <Panel className="cashloan-info__container__content__header__desc">
              <span>{productData?.description}</span>
            </Panel>
          </Panel>
          <Row dataTestId="row" className="cashloan-info__container__content__tarif-list">
            {cashloanAmountInfo}
          </Row>
        </Panel>
      </Container>
    </Panel>
  ) : (
    <SkeletonProductInfo />
  );
};

export default ProductInfo;
