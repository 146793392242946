import React, { useEffect, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import { API } from 'shared/api/loan';
import Container from 'shared/components/Container';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ESalesChannel } from 'shared/enum';
import { IDetails, IProductData } from 'shared/model';

import CreditDetails from './CreditDetails';
import CreditConfirm from './CreditForm';

import './index.scss';

const OrderForm = () => {
  const smallScreenWidth = window.screen.width < 576;
  const { channelType, productCode, rest, processId } = useGeneralInfoContext();
  const [productInfo, setProductInfo] = useState<IProductData>({});
  const [orderDetail, setOrderDetail] = useState<IDetails>();

  const getOrderDetail = () => {
    if (processId) {
      rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
        setOrderDetail(data);
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    rest.get<Array<IProductData>>(API.loan.getProductInfo(productCode)).then(response => {
      setProductInfo(response?.data[0]);
    });
    getOrderDetail();
  }, [productCode]);

  const showContainer = channelType === ESalesChannel.KAPITAL_AZ || channelType === ESalesChannel.UMICO;
  return (
    <Container fluid={showContainer || smallScreenWidth}>
      <Panel className="credit-order">
        <Container fluid={showContainer}>
          <Panel className={`credit-order__row credit-order__row__${channelType}`}>
            <Panel className={`credit-order__confirm credit-order__confirm__${channelType}`}>
              <CreditConfirm productInfo={productInfo} orderDetails={orderDetail} />
            </Panel>
            <Panel className={`credit-order__details credit-order__details__${channelType}`}>
              <CreditDetails productInfo={productInfo} orderDetails={orderDetail} />
            </Panel>
          </Panel>
        </Container>
      </Panel>
    </Container>
  );
};

export default OrderForm;
