import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EJustifyContent, Panel } from '@kapital-bank/kb-ui-design-system';

import WatchIcon from 'shared/assets/images/compressImage/watch-icon.svg';
import { API } from 'shared/api/loan';
import HeadingText from 'shared/components/HeadingText';
import OTPInput from 'shared/components/OTP';
import ProgressBar from 'shared/components/ProgressBar';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { EProductCode } from 'shared/enum';
import { useCounter } from 'shared/hooks/useCounter';
import { translate } from 'shared/utils/translation';
import { PHONE_NUMBER } from 'shared/consts';
import { useLoading } from 'shared/context/loading';
import { IOTP } from 'shared/model';
import { ROUTES } from '../../routes/consts';

import './index.scss';

const OTPForm = () => {
  const { lang, rest, productCode, channelType, processId } = useGeneralInfoContext();
  const { setLoading } = useLoading();
  const [otp, setOtp] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { minutes, seconds, setStartTimer, setCounter, counter } = useCounter(60);
  const navigate = useNavigate();
  const [otpValues, setOTPValues] = useState(Array<string>(4).fill(''));
  const [activeInput, setActiveInput] = useState(0);
  const otpApprove = otp?.length !== 4 || counter === 0;

  const reSend = {
    processId,
    phoneNumber: localStorage.getItem(PHONE_NUMBER),
  };
  const OTPData = {
    ...reSend,
    otp,
  };

  const verifyOtp = () => {
    setErrorMessage('');
    setLoading(true);
    rest
      .post<IOTP, void>(API.loan.verifyOtp, OTPData, undefined, { skipGlobalError: true })
      .then(() => {
        setLoading(false);
        navigate(`/${lang}/order/${productCode}${ROUTES.LOADING.PATH}?t=${channelType}`);
      })
      .catch(err => {
        if (err.response) {
          const { status } = err.response;
          if (status === 400) {
            setOTPValues(Array<string>(4).fill(''));
            setOtp('');
            setActiveInput(0);
            setErrorMessage(translate('common.error.wrong_code'));
          } else if (status === 404) {
            setStartTimer(false);
            setCounter(0);
          } else {
            navigate(`/${lang}/order/${productCode}/end?t=${channelType}`);
          }
        }
        setLoading(false);
      });
  };

  const startOTP = () => {
    setErrorMessage('');
    setLoading(true);
    setOTPValues(Array<string>(4).fill(''));
    setActiveInput(0);
    rest
      .post<IOTP, void>(API.loan.initOtp, reSend)
      .then(() => {
        setStartTimer(true);
        setCounter(60);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(false); // Hide loading that was set in PinAndPhone onSubmit
    if (window.screen.width < 576) {
      window.scrollTo(0, 550);
    } else {
      window.scrollTo(0, 0);
    }
    startOTP();
  }, []);

  useEffect(() => {
    if (!otpApprove) {
      verifyOtp();
    }
  }, [otpApprove]);

  return (
    <Panel justifyContent={2} className="order-step-container">
      <Panel className="otp">
        <ProgressBar progress={15} channelType={channelType} />
        <HeadingText
          classHeader="otp-form__heading"
          text={
            productCode === EProductCode.GTKR ? (
              <span>
                {translate('titles.cash.firstPart')} <span className={`dangerColor-${channelType}`}>{translate('titles.cash.secondPart')} </span>{' '}
                {translate('titles.cash.thirdPart')}
              </span>
            ) : (
              <span>
                {translate('titles.birKart.firstPart')}{' '}
                <span className={`dangerColor-${channelType}`}>{translate('titles.birKart.secondPart')} </span>
                {translate('titles.birKart.thirdPart')} <span className={`dangerColor-${channelType}`}>{translate('titles.birKart.fourthPart')}</span>{' '}
                {translate('titles.birKart.fifthPart')}
              </span>
            )
          }
        />
        <div className="description">
          {translate('otp.info')}
          <span className="mobile-number">
            {`+994 (${OTPData.phoneNumber?.substr(1, 2)}) ${OTPData.phoneNumber?.substr(3, 3)} ${OTPData.phoneNumber?.substr(
              6,
              2
            )} ${OTPData.phoneNumber?.substr(8, 2)}`}
          </span>
          . {translate('otp.enterCode')}
        </div>
        <Panel className="otp-form">
          <OTPInput
            autoFocus
            isNumberInput
            verifyOtp={verifyOtp}
            length={4}
            className="otp-container__wrapper"
            inputClassName="otpInput"
            onChangeOTP={otpValue => setOtp(otpValue)}
            setOTPValues={setOTPValues}
            otpValues={otpValues}
            setActiveInput={setActiveInput}
            activeInput={activeInput}
          />
          {errorMessage && counter > 0 && (
            <Panel marginTop={2} justifyContent={EJustifyContent.CENTER}>
              <div className="error-text error-message">{errorMessage}</div>
            </Panel>
          )}
          {counter === 0 && (
            <Panel marginTop={2} justifyContent={EJustifyContent.CENTER}>
              <div className="error-text error-message">{translate('otp.expired')}</div>
            </Panel>
          )}
        </Panel>
        <Panel marginTop={3} alignItems={2} justifyContent={2} className="send__sms">
          <img src={WatchIcon} alt="icon" />
          <span className="time">{`${minutes}:${seconds}`}</span>
          {counter === 0 && (
            <span className={`send__sms__text dangerColor-${channelType} cursor-pointer`} onClick={startOTP} role="none">
              {translate('common.actions.refreshSms')}
            </span>
          )}
        </Panel>
      </Panel>
    </Panel>
  );
};

export default OTPForm;
