import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import { Panel } from '@kapital-bank/kb-ui-design-system';
import './index.scss';

interface IProps {
  children: any;
}

const SubCategory: FC<IProps> = ({ children }) => {
  const params = window.location.search;
  const getCategory = (path: string) =>
    DOMPurify.sanitize(path).includes('ccl') ? `${DOMPurify.sanitize(path)}${DOMPurify.sanitize(params)}` : DOMPurify.sanitize(path);

  return (
    <div className="subcategory">
      <Panel className="subcategory__products">
        <Panel className="subcategory__content">
          <ul className="subcategory__content-list">
            {children.link1?.map(({ title, path, formobile }: any, i: number) => (
              <li className={`subcategory__content-list-item ${formobile}`} key={`${i}categorylink1`}>
                <a href={getCategory(path)}>{title}</a>
              </li>
            ))}
          </ul>
          <ul className="subcategory__content-list">
            {children.link2?.map(({ title, path }: any, i: number) => (
              <li className="subcategory__content-list-item" key={`${i}categorylink2`}>
                <a href={getCategory(path)}>{title}</a>
              </li>
            ))}
          </ul>
          <ul className="subcategory__content-list">
            {children.link3?.map(({ title, path }: any, i: number) => (
              <li className="subcategory__content-list-item" key={`${i}categorylink3`}>
                <a href={getCategory(path)}>{title}</a>
              </li>
            ))}
          </ul>
        </Panel>
        <Panel className="subcategory__more__info">
          <Panel className="subcategory__more__info__order">
            <p className="subcategory__more__info__order__title">
              {children.moreInfo.title1} <span className="bold">{children.moreInfo.title2}</span>
              <span className="bold new__title">{children.moreInfo.newTitle ? children.moreInfo.newTitle : null}</span>
            </p>
            <p className="subcategory__more__info__order__desc"> {children.moreInfo.desc} </p>
            <a
              className="subcategory__more__info__order__btn"
              href={
                DOMPurify.sanitize(children?.moreInfo?.path).includes('ccl')
                  ? `${DOMPurify.sanitize(children?.moreInfo?.path)}${DOMPurify.sanitize(params)}`
                  : DOMPurify.sanitize(children.moreInfo.path)
              }
            >
              {children.moreInfo.button}
            </a>
          </Panel>
          <Panel className="subcategory__more__info__order__photo">
            <img src={children.moreInfo.img} alt="click" />
          </Panel>
        </Panel>
      </Panel>
    </div>
  );
};
export default SubCategory;
