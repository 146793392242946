import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { Button, Col, EElementSize, EElementView, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import ButtonCommon from 'shared/components/ButtonCommon';
import { styleModal } from 'shared/assets/styles/react-modal';
import { translate } from 'shared/utils/translation';
import { redirect } from 'shared/utils/redirect';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  openModal: boolean;
  setModal: (value: boolean) => void;
  handleCloseModal?: (e: React.MouseEvent) => void;
}

const AgreementModalCheckInfo: FC<IProps> = ({ openModal, setModal, handleCloseModal }) => {
  const { channelType } = useGeneralInfoContext();
  return (
    <Panel>
      <ReactModal style={styleModal} isOpen={openModal} onRequestClose={handleCloseModal} shouldCloseOnOverlayClick={false} className="modal">
        <Panel className="modal__header" justifyContent={4} alignItems={2}>
          <span className="modal__header__title">{translate('common.areYouSure')}</span>
          <img onClick={handleCloseModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
        </Panel>
        <Panel className="modal-line" />

        <Panel className="modal-common-context">
          <Panel marginBottom={2} paddingTop={2} className="agreement-modal__context">
            <Panel className="agreement-modal__context__description" marginBottom={3}>
              {translate('checkInfo.refuseInfo')}
            </Panel>
          </Panel>

          <Row dataTestId="row">
            <Col md={6} sm={6} xs={6}>
              <Button onClick={() => setModal(false)} size={EElementSize.LG} view={EElementView.SECONDARY} className="modal-exit">
                {translate('common.no')}
              </Button>
            </Col>
            <Col md={6} sm={6} xs={6}>
              <ButtonCommon
                channelType={channelType}
                className="agreement__approve"
                text={translate('common.yes')}
                handleClick={() => redirect('https://www.kapitalbank.az/')}
              />
            </Col>
          </Row>
        </Panel>
      </ReactModal>
    </Panel>
  );
};

export default AgreementModalCheckInfo;
