import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import { styleModal } from 'shared/assets/styles/react-modal';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import infoKB from 'shared/assets/images/compressImage/info-kb.svg';
import infoBB from 'shared/assets/images/compressImage/info-bb.svg';
import infoUM from 'shared/assets/images/compressImage/info-um.svg';
import { ESalesChannel } from 'shared/enum';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  openInsuranceModal: boolean;
  handleCloseModal: () => void;
  insuranceModalData: any;
}

const InsuranceModal: FC<IProps> = ({ openInsuranceModal, handleCloseModal, insuranceModalData }) => {
  const { channelType } = useGeneralInfoContext();
  const modalContentList = [
    { id: 0, title: 'Ümumi məlumat', desc: insuranceModalData?.generalInformation },
    { id: 1, title: 'Sığorta haqqı:', desc: insuranceModalData?.detail },
    insuranceModalData?.amount != null && { id: 2, title: 'Sığorta məbləği:', desc: insuranceModalData?.amount },
  ];

  return (
    <ReactModal
      style={styleModal}
      isOpen={openInsuranceModal}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      className="modal insurance-modal"
    >
      <>
        <Panel>
          <Panel className="modal__header" justifyContent={4} alignItems={2}>
            <p className="modal__header__title">{insuranceModalData?.title}</p>
            <img className="modal-close" onClick={handleCloseModal} src={CloseIcon} alt="close" role="none" />
          </Panel>
        </Panel>
        <Panel className="modal-line" />
        <Panel className="modal-common-context">
          <Panel className="insurance-modal__context">
            {modalContentList?.map(({ id, title, desc }: any) => (
              <div key={id}>
                <Panel className="insurance-modal__context__title">{title}</Panel>
                <p className="insurance-modal__context__description">{desc}</p>
              </div>
            ))}

            <div className="insurance-modal__context__description" />
            <Panel className={`insurance-modal__context__warning dangerColor-${channelType}`}>
              <img src={channelType === ESalesChannel.BIRBANK_AZ ? infoBB : channelType === ESalesChannel.UMICO ? infoUM : infoKB} alt="info" />
              <Panel marginLeft={2}>{insuranceModalData?.note}</Panel>
            </Panel>
          </Panel>
        </Panel>
      </>
    </ReactModal>
  );
};

export default InsuranceModal;
