import { ESocketEvent } from '../enum';
import { MAIN_PATH, ROUTES } from '../../routes/consts';

export const baseApiUrl =
  process.env.APP_ENV === 'development'
    ? 'https://dev.gw-onboarding.kapitalbank.az'
    : process.env.APP_ENV === 'preprod'
    ? 'https://pre.gw-onboarding.kapitalbank.az'
    : 'https://gw-onboarding.kapitalbank.az';

export const strapiEnv = process.env.APP_ENV === 'development' ? 'dev.' : process.env.APP_ENV === 'preprod' ? 'pre.' : '';

export const GOOGLE_MAP_KEY = 'AIzaSyCTsapkm9EoN2aCkQdPb4CksGVEy9hRec4';

export const PROCESS_ID = 'processId';
export const PHONE_NUMBER = 'phoneNumber';
export const PIN = 'pin';
export const UTM_PARAMS = 'utmParams';
export enum EPageStep {
  PIN_PHONE = 'PIN_PHONE',
  OTP = 'OTP',
  LOADING_SCORING = 'LOADING_SCORING',
  SCORING = 'SCORING',
  CHECK_INFO = 'CHECK_INFO',
  DELIVERY = 'DELIVERY',
  FORM_CARD = 'FORM_CARD',
  FORM_OPTION = 'FORM_OPTION',
  FINAL = 'FINAL',
  STEP_LOADING = 'STEP_LOADING',
}

export const STEPS = {
  [MAIN_PATH]: EPageStep.PIN_PHONE,
  [`${MAIN_PATH}${ROUTES.OTP.PATH}`]: EPageStep.OTP,
  [`${MAIN_PATH}${ROUTES.LOADING.PATH}`]: EPageStep.LOADING_SCORING,
  [`${MAIN_PATH}${ROUTES.SCORING.PATH}`]: EPageStep.SCORING,
  [`${MAIN_PATH}${ROUTES.ORDER_FORM.PATH}`]: EPageStep.CHECK_INFO,
  [`${MAIN_PATH}${ROUTES.DELIVERY.PATH}`]: EPageStep.DELIVERY,
  [`${MAIN_PATH}${ROUTES.FORMALIZING_CARD.PATH}`]: EPageStep.FORM_CARD,
  [`${MAIN_PATH}${ROUTES.FORMALIZATION_OPTION.PATH}`]: EPageStep.FORM_OPTION,
  [`${MAIN_PATH}${ROUTES.FINAL.PATH}`]: EPageStep.FINAL,
  [`${MAIN_PATH}${ROUTES.STEP_LOADING.PATH}`]: EPageStep.STEP_LOADING,
};

export const processEventToPageMatcher = (processEvent?: ESocketEvent): EPageStep[] => {
  switch (processEvent) {
    case undefined:
      return [EPageStep.PIN_PHONE];
    case ESocketEvent.OTP:
      return [EPageStep.OTP, EPageStep.LOADING_SCORING];
    case ESocketEvent.CALCULATOR:
      return [EPageStep.SCORING];
    case ESocketEvent.CUSTOMER_INFO:
      return [EPageStep.CHECK_INFO];
    case ESocketEvent.DELIVERY:
      return [EPageStep.DELIVERY];
    case ESocketEvent.TRANSFER_INFO:
      return [EPageStep.FORM_CARD];
    case ESocketEvent.TRANSFER:
      return [EPageStep.FORM_OPTION];
    case ESocketEvent.END:
      return [EPageStep.FINAL];
    case ESocketEvent.ERROR:
      return [EPageStep.FINAL];
    default:
      return [EPageStep.PIN_PHONE];
    // DVS?
  }
};

export const rejectFeedbackType = 'REFUSED';
