import React from 'react';

import './index.scss';

const LoadingCircle = () => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8];

  const items = numbers.map((key: number) => <div key={key} />);

  return <div className="loading-default">{items}</div>;
};

export default LoadingCircle;
