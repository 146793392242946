import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import Loader from 'shared/components/Loader';

interface ILoadingProvider {
  loading: boolean;
  setLoading(param: boolean): void;
}

interface IProps {
  children: ReactNode;
}

const LoadingContext = createContext({} as ILoadingProvider);

const LoadingProvider = ({ children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const contextProps = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);
  return (
    <LoadingContext.Provider value={contextProps}>
      {loading && <Loader />}
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('context must be inside provider !');
  }
  return context;
};

export { LoadingProvider, useLoading };
