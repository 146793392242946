import React, { useEffect, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import 'react-loading-skeleton/dist/skeleton.css';

import { EProductCode } from 'shared/enum';
import { API } from 'shared/api/loan';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { IDetails } from 'shared/model';
import ScoringMulti from '../components/CreditCalculator';
import ScoringCard from '../components/ScoringCard';
import SkeletonScoring from '../components/ScoringCard/Skeleton';
import SkeletonScoringMulti from '../components/CreditCalculator/Skeleton';

const ScoringPage = () => {
  const { rest, productCode, processId } = useGeneralInfoContext();
  const [scoringData, setScoringData] = useState<Record<string, any>>();
  const [type, setType] = useState(productCode);

  useEffect(() => {
    if (window.screen.width < 676) {
      window.scrollTo(0, 650);
    } else {
      window.scrollTo(0, 0);
    }

    if (processId) {
      rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
        setScoringData(data);
        if (data.calculator.orderOffers) {
          setType(EProductCode.GTKR);
        }
      });
    }
  }, [processId]);

  return scoringData ? (
    scoringData?.calculator?.orderOffers ? (
      <ScoringMulti scoringData={scoringData} />
    ) : (
      <ScoringCard scoringData={scoringData} />
    )
  ) : (
    <Panel>{type === EProductCode.GTKR ? <SkeletonScoringMulti /> : <SkeletonScoring />}</Panel>
  );
};

export default ScoringPage;
