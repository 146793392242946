import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, EElementSize, Panel } from '@kapital-bank/kb-ui-design-system';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import ButtonCommon from 'shared/components/ButtonCommon';
import DeliveryImage from 'shared/assets/images/compressImage/delivery-brend.svg';
import { translate } from 'shared/utils/translation';
import { dateFormatter } from 'shared/utils/formatter';
import { API } from 'shared/api/loan';
import { useLoading } from 'shared/context/loading';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { PIN } from 'shared/consts';
import { logger } from 'shared/utils/logger';
import { EDeliveryTabType, ELogLevel } from 'shared/enum';
import { IComplateDeliveryReq, ICurrierDate, ICurrierFormData, IDeliveryReq, IDeliveryResp } from 'shared/model';

import AddressModal from './AddressModal';

import './index.scss';

interface IProps {
  customStyle?: Record<string, any>;
  setTabType: () => void;
}

const Currier: FC<IProps> = ({ customStyle, setTabType }) => {
  const { rest, processId, channelType } = useGeneralInfoContext();
  const [openAddressModal, setAddressModal] = useState<boolean>(false);
  const [address, setAddress] = useState('');
  const [nearestVenue, setNearestVenue] = useState({
    id: '',
    branchCode: '',
  });
  const [addressValidation, setAddressValidation] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [dateState, setDateState] = useState<ICurrierDate>({
    selectedDay: '',
    availableTime: '',
    startTime: 0,
    endTime: 0,
    availableDays: 0,
    interval: 0,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICurrierFormData>({
    defaultValues: {
      deliveryDate: '',
    },
  });

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const handleCloseAddressModal = () => {
    setAddressModal(!openAddressModal);
  };

  const getDayAndTime = () => {
    rest.get<ICurrierDate>(API.delivery.getDate).then(({ data }) => {
      setDateState(data);
    });
  };

  useEffect(() => {
    getDayAndTime();
  }, []);

  // ---Date Range
  const getStartDay = new Date(dateState.availableTime?.split(' ')[0].split('-').reverse().join('-'));
  const getEndDay = new Date(dateState.availableTime?.split(' ')[0].split('-').reverse().join('-'));
  getEndDay.setDate(getStartDay.getDate() + (dateState.availableDays - 1));
  // -------

  // ---Time Select
  const now = new Date();
  const avaiableTimesOptions = useMemo(() => {
    const timesArray: { value: string; label: string }[] = [];
    const todaysTime: string[] = dateState.availableTime.slice(11, 16).split(':');
    now.setHours(dateState.selectedDay === 'TODAY' ? Number(todaysTime[0]) : dateState.startTime);
    now.setMinutes(dateState.selectedDay === 'TODAY' ? Number(todaysTime[1]) - dateState.interval : -dateState.interval);

    while (now.getHours() < dateState?.endTime) {
      now.setMinutes(now.getMinutes() + dateState.interval);

      timesArray.push({
        value: `${`0${now.getHours()}`.slice(-2)}:${`0${now.getMinutes()}`.slice(-2)}:00`,
        label: `${`0${now.getHours()}`.slice(-2)}:${`0${now.getMinutes()}`.slice(-2)}`,
      });
    }
    return timesArray;
  }, [dateState]);

  const nextDays = (date: any) => {
    if (date.toString().slice(0, 15) !== now.toString().slice(0, 15)) {
      setDateState({ ...dateState, selectedDay: '' });
    } else {
      setDateState({ ...dateState, selectedDay: 'TODAY' });
    }
  };
  // -----

  useEffect(() => {
    if (address !== '') setAddressValidation(false);
  }, []);

  // ONSUBMIT
  const onSubmit = (data: any) => {
    setLoading(true); // This loading waits socket event -> SuccessPage: ESocketEvent.END
    let shipmentId: any;
    logger(data, ELogLevel.INFO);
    if (address === '') setAddressValidation(true);
    const addressPart = address.split(',');
    const deliveryDetails = {
      city: addressPart[addressPart.length - 2]?.trim(),
      language: 'en',
      lat: markerPosition.lat,
      lon: markerPosition.lng,
      min_preparation_time_minutes: 10,
      pin: localStorage.getItem(PIN),
      post_code: 'AZ1000',
      scheduled_dropoff_time: `${dateFormatter.display(data?.deliveryDate, 'yyyy-mm-dd')}T${data.deliveryTime.value}.000+04:00`,
      street: addressPart[addressPart.length - 3]?.trim(),
    };

    rest
      .post<IDeliveryReq, IDeliveryResp>(API.delivery.sendDeliveryDetail(nearestVenue?.id), deliveryDetails)
      .then(({ data: respData }) => {
        shipmentId = respData.id;
        const completeData = {
          processId,
          branchId: nearestVenue?.branchCode,
          shipmentId,
          shipmentAddress: addressPart[addressPart.length - 3]?.trim(),
          date: dateFormatter.display(data?.deliveryDate, 'yyyy-mm-dd'),
          time: data.deliveryTime.value.replaceAll(':', '-'),
        };
        rest.post<IComplateDeliveryReq, void>(API.loan.completeDelivery, completeData).catch(() => {
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel className="curier-panel" marginTop={7}>
        <Panel className="curier-panel__box">
          <Panel className="curier-panel__box__company" justifyContent={0} alignItems={2} marginBottom={9}>
            <Panel className="curier-panel__box__company__img">
              <img src={DeliveryImage} alt="logo" />
            </Panel>
            <Panel className="curier-panel__box__company__brand">
              <Panel className="curier-panel__box__company__brandname">
                <span className="bold curier-panel__box__company__brandname__name">{translate('delivery.wolt')}</span>
                <span className={`curier-panel__box__company__brandname__free dangerColor-${channelType}`}>{translate('common.free')}</span>
              </Panel>
              <Panel marginLeft={2} paddingBottom={3}>
                <span className="curier-panel__box__company__desc">
                  {translate('delivery.woltContent.firstPart')}
                  <br />
                  {translate('delivery.woltContent.secondPart')}
                  <br />
                  {translate('delivery.woltContent.thirdPart')}
                </span>
              </Panel>
            </Panel>
          </Panel>
        </Panel>
        <span className="curier-panel__box__title-address"> 1. {translate('delivery.choose.location')} </span>
        <Panel className="curier-panel__box__adress user__adress" justifyContent={4} alignItems={2} marginTop={2}>
          <span className="address-label">
            {translate('delivery.address')} <br />
            {address}
          </span>
          <Button
            className={`curier-panel__box__adress__choosing bgColor-${channelType}`}
            onClick={() => setAddressModal(!openAddressModal)}
            size={EElementSize.SM}
          >
            {address ? translate('delivery.changeAddress') : translate('common.actions.choose')}
          </Button>
        </Panel>
        {addressValidation && <div className="error-text"> {translate('delivery.enterAddress')} </div>}
        <Panel className="curier-panel__box" marginTop={5}>
          <span className="curier-panel__box__title-date">2. {translate('delivery.choose.datetime')}</span>
          <Panel className="date-time">
            <Panel className={`curier-panel__box__date-time curier-panel__box__date-time__${channelType}`}>
              <Controller
                control={control}
                name="deliveryDate"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    className="input-style-datepicker"
                    placeholderText={translate('common.dmy')}
                    dateFormat="dd/MM/yyy"
                    shouldCloseOnSelect
                    popperPlacement="bottom"
                    selected={value ? new Date(value) : null}
                    minDate={getStartDay}
                    maxDate={getEndDay}
                    showDisabledMonthNavigation
                    onChange={(date: any) => {
                      onChange(date);
                      nextDays(date);
                    }}
                    customInput={<InputMask mask="99/99/9999" placeholder={translate('common.dmy')} />}
                  />
                )}
              />
              {errors?.deliveryDate?.type === 'required' && <div className="error-text">{translate('delivery.choose.date')}</div>}
            </Panel>
            <Panel className="curier-panel__box__date-time">
              <Controller
                control={control}
                name="deliveryTime"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    name="deliveryTime-select"
                    value={value}
                    onChange={onChange}
                    className="input-style-select"
                    styles={customStyle}
                    placeholder={translate('delivery.choose.time')}
                    options={avaiableTimesOptions}
                    isSearchable={false}
                  />
                )}
              />
              {errors?.deliveryTime?.type === 'required' && <div className="error-text">{translate('delivery.choose.time')}</div>}
              {/* {errorTextVisible && <span className="error-text">{translate('delivery.noTime')}</span>} */}
            </Panel>
          </Panel>
        </Panel>
        <Panel marginTop={7} className="deliver__approve__box">
          <ButtonCommon
            channelType={channelType}
            className="deliver__approve__box__btn"
            text={translate('common.actions.confirm')}
            handleClick={() => {
              if (address === '') setAddressValidation(true);
            }}
          />
        </Panel>
      </Panel>

      <AddressModal
        openAddressModal={openAddressModal}
        handleCloseAddressModal={handleCloseAddressModal}
        setAddress={setAddress}
        nearestVenue={nearestVenue}
        setNearestVenue={setNearestVenue}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        setAddressValidation={setAddressValidation}
        setTabType={setTabType}
      />
    </form>
  );
};

export default Currier;
