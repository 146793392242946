import React from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';

import './index.scss';

const SkeletonScoringMulti = () => {
  return (
    <Panel className="skeleton__scoringmulti">
      <Panel className="skeleton__scoringmulti__items__calc">
        <Panel className="skeleton__scoringmulti__items__calc__input">
          <Skeleton height={100} />
        </Panel>
        <Panel flexDirection={0} justifyContent={4} className="skeleton__scoringmulti__items__calc__months">
          {[...Array(4)].map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Panel className="skeleton__scoringmulti__items__calc__months__element" key={index}>
              <Skeleton />
            </Panel>
          ))}
        </Panel>
      </Panel>
      <Panel className="skeleton__scoringmulti__items__debt-info">
        <Skeleton />
      </Panel>
    </Panel>
  );
};

export default SkeletonScoringMulti;
