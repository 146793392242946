import { matchPath } from 'react-router-dom';
import { IDetails } from 'shared/model';
import { EProductStatus, ESocketEvent } from '../../enum';
import { API } from '../../api/loan';
import { redirect } from '../../utils/redirect';
import { IUtilsParams } from './models';
import { ROUTES } from '../../../routes/consts';
import { processEventToPageMatcher, STEPS } from '../../consts';

export const getNewUrl = (path: string, params: IUtilsParams) => {
  const { lang, productCode, channelType } = params;
  return `/${lang}/order/${productCode}${path}?t=${channelType}`;
};

// TODO: Remove this logic -> discuss with BE better approach for redirection
export const redirectFlow = (step: ESocketEvent, params: IUtilsParams) => {
  const { processId, rest, productCode } = params;
  rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data: { progressingStatus, step: stepFromBE, product } }) => {
    const innerRedirect = productCode === product?.code;
    let newStep = step;
    if ((progressingStatus === EProductStatus.IN_PROGRESS && stepFromBE !== step) || progressingStatus === EProductStatus.REDIRECTION) {
      newStep = stepFromBE;
    } else if (progressingStatus === EProductStatus.ERROR) {
      newStep = ESocketEvent.ERROR;
    } else if (progressingStatus === EProductStatus.END || progressingStatus === EProductStatus.COMPLETED) {
      newStep = ESocketEvent.END;
    }
    const url = Object.keys(STEPS).find(p => !!matchPath(p, window.location.pathname));
    const currentUrlStep = url ? STEPS[url] : undefined;
    if (!currentUrlStep || !processEventToPageMatcher(newStep).includes(currentUrlStep)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      redirectTo(newStep, { ...params, productCode: product?.code || productCode }, innerRedirect);
    }
  });
};

export const redirectTo = (step: ESocketEvent, params: IUtilsParams, innerRedirect = true) => {
  const { processId, rest, navigate } = params;
  const redirectMethod = innerRedirect ? navigate : redirect;

  const redirectDVS = () => {
    rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
      if (data.dvs?.dvsLink) {
        redirect(data.dvs.dvsLink);
      } else {
        redirectMethod(getNewUrl(ROUTES.FINAL.PATH, params));
      }
    });
  };

  const errorCase = () => {
    rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(() => {
      redirectMethod(getNewUrl(ROUTES.FINAL.PATH, params));
    });
  };

  switch (step) {
    case ESocketEvent.OTP:
      redirectMethod(getNewUrl(ROUTES.OTP.PATH, params));
      break;

    case ESocketEvent.CALCULATOR:
      redirectMethod(getNewUrl(ROUTES.SCORING.PATH, params));
      break;

    case ESocketEvent.CUSTOMER_INFO:
      redirectMethod(getNewUrl(ROUTES.ORDER_FORM.PATH, params));
      break;

    case ESocketEvent.DVS:
      redirectDVS();
      break;

    case ESocketEvent.DELIVERY:
      redirectMethod(getNewUrl(ROUTES.DELIVERY.PATH, params));
      break;

    case ESocketEvent.TRANSFER_INFO:
      redirectMethod(getNewUrl(ROUTES.FORMALIZING_CARD.PATH, params));
      break;

    case ESocketEvent.TRANSFER:
      redirectMethod(getNewUrl(ROUTES.FORMALIZATION_OPTION.PATH, params));
      break;

    case ESocketEvent.ERROR:
      errorCase();
      break;

    case ESocketEvent.END:
      redirectMethod(getNewUrl(ROUTES.FINAL.PATH, params));
      break;

    // TODO: find out when we got this event from BE -> write doc
    case ESocketEvent.REDIRECTION:
      redirectFlow(step, params);
      break;

    default:
      break;
  }
};
