import React, { FC } from 'react';

import './index.scss';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ESalesChannel } from '../../enum';

interface IProps {
  mobileAppsKb?: any;
  mobileAppsBb?: any;
}

const MobileAppsCommon: FC<IProps> = ({ mobileAppsKb, mobileAppsBb }) => {
  const { channelType } = useGeneralInfoContext();
  const mobileapps = channelType === ESalesChannel.BIRBANK_AZ ? mobileAppsBb : mobileAppsKb;
  return (
    <ul className="footer__mobile-apps__list">
      {mobileapps?.map(({ header, body, path, svg }: any, i: number) => (
        <li key={mobileapps === 'bb' ? `${i}appsbb` : `${i}appskb`} className={`mobile-apps mobile-apps-${channelType}`}>
          <a href={path}>
            <img src={svg} alt="svg" />
            <p>
              <span>{header}</span>
              <span>{body}</span>
            </p>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default MobileAppsCommon;
