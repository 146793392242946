import React, { FC } from 'react';
import { Button, EElementView, Panel } from '@kapital-bank/kb-ui-design-system';
import { Sanitize } from 'shared/utils/helpers';

import { translate } from 'shared/utils/translation';
import { redirect } from 'shared/utils/redirect';
import EnterIcon from 'shared/assets/images/compressImage/enter-icon.svg';

import Container from '../../../Container';

import './index.scss';

const Navbar: FC = () => {
  const params = window.location.search;
  return (
    <Container>
      <nav className="navbar">
        <Panel flexDirection={0} justifyContent={4}>
          <Panel className="navbar__link-content">
            <ul className="navbar__link">
              {translate('headerMenu.Bb.links').map(({ label, href }: any, i: number) => (
                <li key={`${i}bbmenu`} className="navbar__link__li">
                  <a href={Sanitize(href).includes('ccl') ? `${Sanitize(href)}${Sanitize(params)}` : Sanitize(href)}>{label}</a>
                </li>
              ))}
            </ul>
          </Panel>
          <Panel className="navbar__btn">
            <Button onClick={() => redirect('https://birbank.az/login')} view={EElementView.PRIMARY}>
              <img src={EnterIcon} alt="icon" />
              {translate('headerMenu.Bb.entry')}
            </Button>
          </Panel>
        </Panel>
      </nav>
    </Container>
  );
};

export default Navbar;
