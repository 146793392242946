import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, EElementSize, EElementView, ETextAlign, Panel, Row } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';

import { API } from 'shared/api/loan';
import Container from 'shared/components/Container';
import ButtonCommon from 'shared/components/ButtonCommon';
import ProgressBar from 'shared/components/ProgressBar';
import CardImage from 'shared/assets/images/compressImage/card-formalize.svg';
import { useLoading } from 'shared/context/loading';
import { ESalesChannel } from 'shared/enum';
import { translate } from 'shared/utils/translation';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { IDetails, ITransferReq } from 'shared/model';
import GiftCardBranchSelector from 'shared/components/GiftCardBranchSelector';
import { useForm } from 'react-hook-form';

import './index.scss';

const FormalizingCard = () => {
  const { channelType, rest, processId } = useGeneralInfoContext();
  const { setLoading } = useLoading();
  const [pan, setPan] = useState('');
  const [hasGiftCard, setHasGiftCard] = useState<boolean>(false);

  const { control, watch } = useForm<any>({
    defaultValues: {
      number: '',
      branch: { value: '', label: '', id: '' },
    },
  });
  const watchField = watch();

  useEffect(() => {
    setLoading(false); // Hide loading that was set in Formalization Option
    if (processId) {
      rest.get<IDetails>(API.loan.getOrderDetail(processId)).then(({ data }) => {
        setPan(data.transfer?.maskedSalaryPan);
        if (data.calculator?.giftCardSelected) {
          setHasGiftCard(data.calculator.giftCardSelected);
        }
      });
    }
  }, []);

  const buttonState = useMemo(() => {
    let active = true;
    if (hasGiftCard) {
      active = !!watchField.branch.id;
    }
    return active;
  }, [hasGiftCard, watchField]);

  const transferCash = () => {
    setLoading(true); // This loading waits socket event -> FormalizingCard: ESocketEvent.TRANSFER_INFO / DeliveryPage: ESocketEvent.DELIVERY
    const transferData = {
      processId,
      transferOption: 'PAN',
      branchId: watchField.branch.id || undefined,
    };
    rest.post<ITransferReq, void>(API.transfer.cashTransfer, transferData).catch(() => {
      setLoading(false);
    });
  };

  return (
    <Container fluid={channelType === ESalesChannel.KAPITAL_AZ} className="bg-soft-grey end__formalize_holder">
      <Panel className="end__formalize">
        <ProgressBar progress={95} channelType={channelType} />
        <Panel className="end__formalize__title" textAlign={ETextAlign.CENTER} marginBottom={2}>
          {translate('formalization.confirmOrder')}
        </Panel>

        <Panel className={`end__formalize__form ${hasGiftCard ? 'has-giftcard' : ''}`}>
          <Row>
            <Col md={hasGiftCard ? 6 : 12}>
              <Panel marginBottom={6}>
                <Panel className="formalize__loan__form__header_container" justifyContent={0} alignItems={2}>
                  <Panel className="formalize__loan__form__header_container__img cash">
                    <img src={API.loan.getImage('CASH_MINI')} alt="cash credit" />
                  </Panel>
                  <h2 className="formalize__loan__form__header_container__text">{translate('formalization.creditOptionTitleCash')}</h2>
                </Panel>
                <Button size={EElementSize.LG} view={EElementView.WHITE} className="end__formalize__card" disabled>
                  <Panel className="end__formalize__card__title">{translate('common.cardNumber')}</Panel>
                  {pan ? (
                    <Panel className="end__formalize__card__container">
                      <div className="end__formalize__card__container__number">{pan}</div>
                      <div>
                        <img src={CardImage} alt="card" />
                      </div>
                    </Panel>
                  ) : (
                    <Panel className="end__formalize__skeleton">
                      <Skeleton className="end__formalize__skeleton__item" height={28} />
                    </Panel>
                  )}
                </Button>
              </Panel>
            </Col>
            {hasGiftCard && (
              <Col md={6}>
                <GiftCardBranchSelector control={control} />
              </Col>
            )}
          </Row>
          <Panel justifyContent={2} paddingBottom={6} paddingTop={6}>
            <ButtonCommon disable={!buttonState} {...{ channelType }} text={translate('actions.formalizeCredit')} handleClick={transferCash} />
          </Panel>
        </Panel>
      </Panel>
    </Container>
  );
};

export default FormalizingCard;
