import React, { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

import { ELang, ELogLevel, EProductCode, ESalesChannel, ESocketEvent } from 'shared/enum';
import { redirect } from 'shared/utils/redirect';
import { regProductCode } from 'shared/utils/helpers';
import { GeneralInfoContext } from './GeneralInfoContext';
import { restClient } from '../../api/rest';
import { useQuery } from '../../hooks/useQuery';
import { IGeneralInfo } from './models';
import { PROCESS_ID, processEventToPageMatcher, STEPS, EPageStep } from '../../consts';
import Loader from '../../components/Loader';
import { MAIN_PATH } from '../../../routes/consts';
import { LoadingProvider } from '../loading';
import { useSocketState } from './hooks/socket';
import { logger } from '../../utils/logger';
import { redirectTo } from './utils';
import { getCurrentLang } from '../../utils/translation';

/** With GeneralInfo context */
export const WithGeneralInfoComponent: React.FC = ({ children }) => {
  let customErrorTimer: ReturnType<typeof setTimeout>;
  const reportCustomError = (window as any).dtrum?.reportCustomError;
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { lang, productCode } = useParams();
  const firstPageUrl = `/${lang}/order/${productCode}${search}`;
  const isFirstPage = !!useMatch(MAIN_PATH);

  const [navigateToFirstPage, setNavigateToFirstPage] = useState(false);
  const [processId, setProcessId] = useState(isFirstPage ? null : localStorage.getItem(PROCESS_ID));
  const [processStep, setProcessStep] = useState<ESocketEvent>();

  useSocketState({
    setProcessStep,
    setProcessId,
    processId,
  });

  const providedChannelType = query.get('t') as ESalesChannel;
  const channelType = Object.values(ESalesChannel).includes(providedChannelType) ? providedChannelType : ESalesChannel.KAPITAL_AZ;
  const providedLanguage = lang as ELang;
  const currentLang = Object.values(ELang).includes(providedLanguage) ? providedLanguage : getCurrentLang();
  const providedProduct = productCode as EProductCode; // TODO: check validity of passed product
  const isProductCode = regProductCode(providedProduct);

  const rest = restClient(currentLang, () => {
    navigate(`/${lang}/order/${productCode}/end?t=${channelType}`);
  });

  const currentUrlStep = useMemo(() => {
    const url = Object.keys(STEPS).find(p => !!matchPath(p, pathname));
    return url ? STEPS[url] : undefined;
  }, [pathname]);

  const isValidPage = useMemo(() => {
    return (
      currentUrlStep === EPageStep.FINAL ||
      (processStep !== ESocketEvent.REDIRECTION && !!currentUrlStep && processEventToPageMatcher(processStep).includes(currentUrlStep))
    );
  }, [currentUrlStep, processStep]);

  const processStepInitialLoading = useMemo(() => {
    return !processStep && processId; // If processId exists but no processStep exists - it means that need to wait initial socket connection
  }, [processId, processStep]);

  useEffect(() => {
    if (!isProductCode) {
      redirect(channelType === ESalesChannel.BIRBANK_AZ ? 'https://birbank.az/cards/creditcards' : 'https://www.kapitalbank.az/cards/TaksitCards');
    }
  }, [productCode]);

  useEffect(() => {
    if (isFirstPage) {
      setNavigateToFirstPage(false);
      localStorage.clear();
      setProcessId(null);
      setProcessStep(undefined);
    }
  }, [pathname]);

  useEffect(() => {
    if (!currentUrlStep) {
      logger(`No such step for url: ${pathname}`, ELogLevel.WARN);
      setNavigateToFirstPage(true);
    } else if (!processStepInitialLoading && !isValidPage) {
      if (processId && processStep) {
        redirectTo(processStep, {
          lang: currentLang,
          channelType,
          processId,
          productCode: providedProduct,
          navigate,
          rest,
        });
      } else {
        setNavigateToFirstPage(true);
      }
    }
  }, [currentUrlStep, processStep]);

  useEffect(() => {
    if (navigateToFirstPage) {
      navigate(firstPageUrl);
    }
  }, [navigateToFirstPage]);

  /** Dynatrace error logging. */
  useEffect(() => {
    if (reportCustomError) {
      if (!processStepInitialLoading && !isValidPage) {
        customErrorTimer = setTimeout(
          () => reportCustomError('PageNotValidForTooLong', `processId: ${processId}; processStep: ${processStep};`),
          10000
        );
      } else {
        clearTimeout(customErrorTimer);
      }
    }
    return () => {
      clearTimeout(customErrorTimer);
    };
  }, [isValidPage]);

  const contextProps = useMemo<IGeneralInfo>(
    () => ({
      channelType,
      lang: currentLang,
      rest,
      productCode: providedProduct,
      setProcessStep,
      processId,
      setProcessId,
    }),
    [channelType, currentLang, rest, providedProduct, setProcessStep, processId, setProcessId]
  );

  return (
    <GeneralInfoContext.Provider value={contextProps}>
      <LoadingProvider>{!isValidPage ? <Loader /> : children}</LoadingProvider>
    </GeneralInfoContext.Provider>
  );
};
WithGeneralInfoComponent.displayName = 'WithGeneralInfo';

export { WithGeneralInfoComponent as WithGeneralInfo };
