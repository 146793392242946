import DOMPurify from 'dompurify';
import { EProductCode, ESalesChannel, ESubPage } from 'shared/enum';

export const isDev = () => process.env.NODE_ENV === 'development';

export const checkDuplicateCharacters = (string: string): boolean => {
  return (
    string
      .split(' ')
      .join('')
      .split('')
      .filter((item, pos, self) => self.indexOf(item) === pos).length > 1
  );
};

export const isFluidChannel = (channelType: string) => {
  const channelList: Array<string> = [ESalesChannel.KAPITAL_AZ, ESalesChannel.UMICO];
  return channelList.includes(channelType);
};

export const luhnCheck = (card: string): boolean => {
  const arr = card
    .split('')
    .reverse()
    .map(x => parseInt(x, 10));

  const lastDigit = arr.shift() || 0;

  let sum = arr.reduce((acc, val, i) => {
    const myVal = val * 2;
    return i % 2 !== 0 ? acc + val : acc + (myVal > 9 ? myVal - 9 : myVal);
  }, 0);
  sum += lastDigit;

  return sum % 10 === 0;
};

export const setCookie = (name: string, value: string, second: number, path = '/') => {
  let expires = '';
  if (second) {
    const date = new Date();
    date.setTime(date.getTime() + second * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${encodeURIComponent(value) || ''}${expires}; path=${path}`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const removeCookie = (name: string, path = '/') => {
  document.cookie = `${name}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const regProductCode = (val: string) => /^[a-zA-Z_]+$/.test(val);

export const onSanitizeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
  const transformedEvent = {
    ...event,
    currentTarget: {
      ...event.currentTarget,
      value: DOMPurify.sanitize(event.currentTarget.value),
    },
  };

  return transformedEvent;
};

export const getSubPage = (productCode: string) => {
  switch (productCode) {
    case EProductCode.BRCB_BLACK:
      return ESubPage.BRCB_BLACK;
    case EProductCode.BBUM:
      return ESubPage.BBUM;
    case EProductCode.BRML:
      return ESubPage.BRML;
    case EProductCode.BPUM:
      return ESubPage.BPUM;
    default:
      return '';
  }
};

export const Sanitize = (text: string) => {
  return DOMPurify.sanitize(text);
};
