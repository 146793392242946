import React, { FC, useState } from 'react';
import { Checkbox, Panel, RadioButton } from '@kapital-bank/kb-ui-design-system';
import 'react-loading-skeleton/dist/skeleton.css';

import infoKB from 'shared/assets/images/compressImage/info-kb.svg';
import infoBB from 'shared/assets/images/compressImage/info-bb.svg';
import infoUM from 'shared/assets/images/compressImage/info-um.svg';
import { ESalesChannel } from 'shared/enum';
import { IQuestion, IQuestionOption } from 'shared/model';

import QuestionnaireInfo from '../../QuestionnaireInfo';
import SkeletonQuestionnarire from './Skeleton';

interface IProps {
  channelType: string;
  dataQuestionnaire: IQuestion[];
  setDataQuestionnaire: (questions: IQuestion[]) => void;
}

const Question: FC<IProps> = ({ channelType, dataQuestionnaire, setDataQuestionnaire }) => {
  const [openInfoModal, setInfoModal] = useState<boolean>(false);

  const handleCloseQuestionnaireInfo = () => {
    setInfoModal(!openInfoModal);
  };

  const answerChanged = (selectedAnswerId: number, id: number) => {
    const newData = [...dataQuestionnaire];
    newData[id - 1].questionOptions = newData[id - 1].questionOptions.map((questionOption: IQuestionOption) => {
      return {
        ...questionOption,
        selected: questionOption.id === selectedAnswerId,
      };
    });
    setDataQuestionnaire(newData);
  };

  const answerChecked = (id: number) => {
    const newData = [...dataQuestionnaire];
    newData[id - 1].questionOptions[0].selected = !newData[id - 1].questionOptions[0].selected;
    setDataQuestionnaire(newData);
  };

  return (
    <div>
      {dataQuestionnaire.length > 0 ? (
        dataQuestionnaire?.map(({ value, questionOptions, id }: any) => (
          <li className="questionnaire-modal__context__list-item" key={`${id}questions`}>
            <Panel className="questionnaire-modal__context__list-item__question" justifyContent={0} key={`${id}question`}>
              <Panel className="questionnaire-modal__context__list-item__question__number" key={id}>
                {id}.
              </Panel>
              <Panel className="questionnaire-modal__context__list-item__question">{value}</Panel>
              {id === 8 || id === 9 ? (
                <Panel className="questionnaire-modal__context__list-item__question__icon" onClick={() => setInfoModal(!openInfoModal)}>
                  <img src={channelType === ESalesChannel.BIRBANK_AZ ? infoBB : channelType === ESalesChannel.UMICO ? infoUM : infoKB} alt="info" />
                </Panel>
              ) : null}
            </Panel>
            <Panel className="questionnaire-modal__context__list-item__variants" justifyContent={4} key={`${id}variants`}>
              {questionOptions[0]?.id === 3 ? (
                <Checkbox onChange={() => answerChecked(id)} className={`checkbox-${channelType}`} value={questionOptions[0].selected}>
                  <span className="order__form__questionnaire__description__text">{questionOptions[0].value}</span>
                </Checkbox>
              ) : (
                <>
                  {questionOptions.map((option: any) => (
                    <RadioButton
                      key={option.id}
                      name={`${id}`}
                      value={option.id}
                      className={`radio-${channelType}`}
                      checked={option.selected}
                      onChange={value => answerChanged(value, id)}
                    >
                      {option.value}
                    </RadioButton>
                  ))}
                </>
              )}
            </Panel>
          </li>
        ))
      ) : (
        <SkeletonQuestionnarire />
      )}
      <QuestionnaireInfo openInfoModal={openInfoModal} handleCloseQuestionnaireInfo={handleCloseQuestionnaireInfo} />
    </div>
  );
};

export default Question;
