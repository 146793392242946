import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import OrderStepWrapper from 'shared/components/OrderStepWrapper';
import Loader from 'shared/components/Loader';

import FinalPage from 'view/FinalPage';
import PinAndPhonePage from '../view/PinAndPhonePage';
import ScoringPage from '../view/ScoringPage';
import OrderFormPage from '../view/OrderFormPage';
import OTPPage from '../view/OTPPage';
import StepLoadingPage from '../view/StepLoadingPage';

import FormalizationOptionPage from '../view/FormalizationOptionPage';
import FormalizingCardPage from '../view/FormalizingCardPage';
import DeliveryPage from '../view/DeliveryPage';
import LoaderPage from '../view/LoaderPage';
import { ROUTES } from './consts';

const NotFoundPage = lazy(() => import('shared/views/NotFound'));

/**
 * Add Routes
 */

const OrderStep: FC = () => (
  <OrderStepWrapper>
    <Routes>
      <Route path={ROUTES.PIN.PATH} element={<PinAndPhonePage />} />
      <Route path={ROUTES.OTP.PATH} element={<OTPPage />} />
      <Route path={ROUTES.LOADING.PATH} element={<LoaderPage />} />
      <Route path={ROUTES.SCORING.PATH} element={<ScoringPage />} />
      {/** Not Found Route */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </OrderStepWrapper>
);

export const commonRoutes: JSX.Element = (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path={ROUTES.MAIN.PATH} element={<OrderStep />} />
      <Route path={ROUTES.ORDER_FORM.PATH} element={<OrderFormPage />} />
      <Route path={ROUTES.STEP_LOADING.PATH} element={<StepLoadingPage />} />
      <Route path={ROUTES.FORMALIZATION_OPTION.PATH} element={<FormalizationOptionPage />} />
      <Route path={ROUTES.FORMALIZING_CARD.PATH} element={<FormalizingCardPage />} />
      <Route path={ROUTES.DELIVERY.PATH} element={<DeliveryPage />} />
      <Route path={ROUTES.FINAL.PATH} element={<FinalPage />} />
      {/** Not Found Route */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Suspense>
);
