import React from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

const SkeletonProductInfo = () => {
  const { channelType } = useGeneralInfoContext();
  const renderSkeletonColumn = (rows: number, height: number, top: number, name: string) => (
    <Panel className="skeleton__productinfo__column">
      {[...Array(rows)].map(() => (
        <Panel marginTop={top} className={`skeleton__productinfo__item__${name}`} key={`${Math.random()}productSkleten`}>
          <Skeleton height={height} />
        </Panel>
      ))}
    </Panel>
  );
  return (
    <Panel className={`skeleton__productinfo skeleton__productinfo__${channelType}`}>
      {renderSkeletonColumn(2, 60, 15, 'info')}
      {renderSkeletonColumn(1, 280, 0, 'card')}
    </Panel>
  );
};

export default SkeletonProductInfo;
