import { ELang } from 'shared/enum';

const STORAGE_CURRENT_LANG = 'lang';
export const defaultLang = ELang.AZ;
export const supportedLangs = Object.values(ELang);

interface ILangData {
  [key: string]: any;
}

interface ITranslationParam {
  [key: string]: string;
}

// eslint-disable-next-line import/no-mutable-exports
export let lang: ELang | string;
let langData: ILangData;

const paths = window.location.pathname.split('/');
let langCode: string;

/**
 * @description DVS redirect link do not have lang param on the url so that lang change redirects to wrong url.
 * Added more checks to detect user latest lang and continue over that.
 * 1st check if url has correct lang. Then localstorage for saved one. If none of them set default lang.
 */
export const setCurrentLang = (): ELang => {
  const savedLang = localStorage.getItem(STORAGE_CURRENT_LANG);
  langCode = defaultLang;
  if (supportedLangs.includes(paths[1].toLowerCase() as ELang)) {
    langCode = paths[1].toLowerCase();
  } else if (savedLang && supportedLangs.includes(savedLang as ELang)) {
    langCode = savedLang;
  }
  localStorage.setItem(STORAGE_CURRENT_LANG, langCode);
  return langCode as ELang;
};

setCurrentLang();

export const getCurrentLang = () => langCode as ELang;

const getLangData = async () => {
  return import(`../../assets/lang/${lang}.json`);
};

export const translationInit = async () => {
  /** Check if localStorage has lang */
  lang = supportedLangs.includes(langCode as ELang) ? langCode : defaultLang;
  /** set html lang attribute */
  const html = document.querySelector('html');
  html?.setAttribute('lang', lang);

  /** GET LANG DATA */
  langData = await getLangData();
};

export const changeLang = (lng = defaultLang) => {
  const language = supportedLangs.includes(lng as ELang) ? lng : defaultLang;
  localStorage.setItem(STORAGE_CURRENT_LANG, language);
  window.location.pathname = window.location.pathname.replace(langCode, language);
};

export const translate = (value?: string, params?: ITranslationParam) => {
  try {
    return value
      ? value.split('.').reduce((o, i) => {
          let result = o[i];
          if (params && Object.keys(params) && Object.keys(params).length && typeof result === 'string') {
            Object.keys(params).forEach(key => {
              result = result.replace(new RegExp(`{${key}}`, 'gi'), params[key]);
            });
          }
          return result;
        }, langData as any)
      : value;
  } catch (e) {
    return value;
  }
};
