import React from 'react';
import { translate } from 'shared/utils/translation';

import './index.scss';

const LoadingMessage = () => {
  return <div className="load-message">{translate('common.loading')}</div>;
};

export default LoadingMessage;
