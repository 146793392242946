import React, { useEffect } from 'react';

import Navbar from './Navbar';
import NavbarBrend from './NavbarBrend';

import './index.scss';

const Header = () => {
  useEffect(() => {
    const headerBb = document.querySelector('.header-bb');

    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 200) {
        headerBb?.classList.add('header-bb__shadow');
      } else {
        headerBb?.classList.remove('header-bb__shadow');
      }
    });
  });

  return (
    <header className="header-bb">
      <NavbarBrend />
      <Navbar />
    </header>
  );
};

export default Header;
