import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import BirbankNavbar from 'shared/assets/images/compressImage/birbank-navbar.svg';

import SubCategory from './SubCategory';

import './index.scss';

const NavbarKb: FC = () => {
  return (
    <Panel className="navbar__kb">
      <Panel className="navbar__kb__content">
        <ul className="navbar__kb__content__category">
          {translate('headerMenu.Kb').map(({ title, path, children }: any, i: number) => (
            <li key={`${i}kbmenuitem`} className="navbar__kb__content__category-item">
              <a href={path} className="navbar__kb__content__category-item__link">
                {title}
              </a>
              {children ? <SubCategory {...{ children }} /> : null}
            </li>
          ))}
        </ul>
        <Panel>
          <a href="shared/components/kapital/Header/NavbarKb/index">
            <img src={BirbankNavbar} alt="birbank" />
          </a>
        </Panel>
      </Panel>
    </Panel>
  );
};

export default NavbarKb;
