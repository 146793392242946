import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import { IDebtAmount } from 'shared/model';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  forCard: boolean;
  amount: IDebtAmount;
}

const DebtInfo: FC<IProps> = ({ forCard, amount }) => {
  const { channelType } = useGeneralInfoContext();
  const debtDetails = [
    {
      id: 0,
      title: forCard ? translate('calculator.currentCardDebt') : translate('calculator.currentCashDebt'),
      value: amount.productDebtAmount,
      currency: 'AZN',
      hasDebt: amount.productDebtAmount,
    },
    {
      id: 1,
      title: translate('calculator.currentAdvanceDebt'),
      value: amount.advanceDebt.value,
      currency: amount.advanceDebt.currency,
      hasDebt: amount.advanceDebt.value,
    },
  ];

  return (
    <Panel className="debt-info">
      {debtDetails.map(({ id, title, value, currency, hasDebt }) => (
        <Panel key={id} className={`debt-info__content ${!hasDebt && 'display-none'}`} justifyContent={4}>
          <div className="description"> {title}</div>
          <div className="amount">{`${value} ${currency}`}</div>
        </Panel>
      ))}
      {forCard && (
        <div className="debt-info__desc-card">
          {amount.refinancingAmount !== 0 && amount.advanceDebt.value !== 0
            ? translate('calculator.debtForCard')
            : amount.advanceDebt.value !== 0
            ? translate('calculator.advanceDebtForCard')
            : translate('calculator.refinancingAmountForCard')}
        </div>
      )}
    </Panel>
  );
};

export default DebtInfo;
