import React, { FC } from 'react';
import { Icon, Panel } from '@kapital-bank/kb-ui-design-system';
import PhoneIcon from 'shared/assets/images/compressImage/phone-square-alt.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ESalesChannel } from '../../enum';

import './index.scss';

interface IProps {
  socialIconsKb?: any;
  socialIconsBB?: any;
}

const SocialMediaCommon: FC<IProps> = ({ socialIconsKb, socialIconsBB }) => {
  const { channelType } = useGeneralInfoContext();
  const socials = channelType === ESalesChannel.BIRBANK_AZ ? socialIconsBB : socialIconsKb;
  return (
    <Panel>
      <ul className={`footer__social__media footer__social__media__${channelType}`}>
        <li className="phone__number">
          <a href="tel:196">
            <img src={PhoneIcon} alt="phone" />
            <span>196</span>
          </a>
        </li>
        {socials?.map(({ icon, href }: any, i: number) => (
          <li key={socials === 'bb' ? `${i}socialbb` : `${i}socialkb`} className="socials">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href={href}>
              <Icon iconName={icon} />
            </a>
          </li>
        ))}
      </ul>
    </Panel>
  );
};

export default SocialMediaCommon;
