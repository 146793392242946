import { Panel } from '@kapital-bank/kb-ui-design-system';
import React, { FC, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import { translate } from 'shared/utils/translation';
import { onSanitizeValue } from 'shared/utils/helpers';
import { ELogLevel } from 'shared/enum';
import { logger } from 'shared/utils/logger';

import './index.scss';

interface IProps {
  panTo?: any;
  setInput?: any;
  inputValue?: any;
  setDeliveryErrorCode?: any;
}

const Search: FC<IProps> = ({ panTo, setInput, inputValue, setDeliveryErrorCode }) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      location: { lat: () => 40.409264, lng: () => 49.867092 },
      // location: new google.maps.LatLng(40, 49),
      radius: 2000,
      componentRestrictions: { country: 'az' },
    },
    cacheKey: 'AZ',
    debounce: 500,
  });

  const selectAddress = async (val: string, geocode = false) => {
    const address = val;
    clearSuggestions();
    setInput(address);
    if (inputValue === '' || inputValue.length >= 1) setDeliveryErrorCode(null);
    try {
      // google.maps.places.PlacesService.getDetails(info.place_id) // get place details.
      // get geocode only when user select from list.
      if (geocode) {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        panTo({ lat, lng });
      }
    } catch (error) {
      logger(error as any, ELogLevel.ERROR);
    }
  };

  return (
    <Panel className="address-modal__body__input-panel search-panel" justifyContent={0} alignItems={2}>
      <input
        autoComplete="off"
        className="address-modal__body__input-panel__input search-panel__input"
        placeholder={translate('delivery.choose.deliveryLocation')}
        value={inputValue}
        onChange={e => {
          const event = onSanitizeValue(e);
          setValue(event.target.value);
          selectAddress(event.target.value);
        }}
        list="addressList"
        name="address"
        id="address"
        disabled={!ready}
      />

      {status === 'OK' && (
        <ul id="addressList" className="search-panel__input__list">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {data.map(({ id, description }) => {
            return (
              <li
                key={id}
                onClick={() => {
                  setValue(description, false);
                  selectAddress(description, true);
                }}
                value={description}
              >
                {description}
              </li>
            );
          })}
        </ul>
      )}
    </Panel>
  );
};

export default Search;
