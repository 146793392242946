import React, { useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { ETextAlign, Input, Panel } from '@kapital-bank/kb-ui-design-system';
import { Controller, useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';

import ButtonCommon from 'shared/components/ButtonCommon';
import HeadingText from 'shared/components/HeadingText';
import ReactSelect from 'shared/components/ReactSelect';
import { useLoading } from 'shared/context/loading';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { checkDuplicateCharacters, getCookie, getSubPage, onSanitizeValue, setCookie } from 'shared/utils/helpers';
import { logger } from 'shared/utils/logger';
import { prefixOptions } from 'shared/consts/select-options';
import { translate } from 'shared/utils/translation';
import { styleModal } from 'shared/assets/styles/react-modal';
import { API } from 'shared/api/loan';
import { numberFormatter } from 'shared/utils/numberFormatter';
import { ELogLevel, EProductCode, ESalesChannel } from 'shared/enum';
import Container from 'shared/components/Container';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import { PHONE_NUMBER, PIN, PROCESS_ID, UTM_PARAMS } from 'shared/consts';
import Checkboxs from 'shared/components/Checkboxs';
import { getErrorCodeTranslation } from 'shared/api/rest';
import { IUTMReq } from 'shared/model';

import './index.scss';

interface IFormData {
  pin: string;
  prefix: any;
  number: string;
  accept: boolean;
}

interface IDataLayerEvent {
  event: string;
  formName: string;
  cardName?: string;
}

const PinAndPhone = () => {
  const { channelType, rest, productCode, setProcessId } = useGeneralInfoContext();
  const [visible, setVisible] = useState(false);
  const [openPinModal, setPinModal] = useState(false);
  const phoneRef = useRef<HTMLInputElement>();
  const { setLoading } = useLoading();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const phone = params.get('phone') || '';
  const pinFull = /^((?![Oo])[A-Za-z\d])+$/;
  const pinValidChar = /(?![oO])[A-Za-z\d]/g;
  const [responseError, setResponseError] = useState<string>('');

  // check if there is an utm data exist
  const utmParams: Array<Record<string, string>> = useMemo(() => {
    let existingParams: Array<Record<string, string>> = Array.from(params.entries())
      .filter(([key]) => key.startsWith('utm_') || key === 'tvr_id' || key === 'redirection')
      .map(info => {
        return { key: info[0], value: info[1] };
      });

    if (existingParams.length) {
      const cookieParams = getCookie(UTM_PARAMS);
      if (!cookieParams) {
        setCookie(UTM_PARAMS, JSON.stringify(existingParams), 7200);
      } else {
        existingParams = JSON.parse(cookieParams);
      }
    }
    return existingParams;
  }, []);

  // *** DataLayer for  GTM
  useEffect(() => {
    const pageViewDataLayer = {
      MainCategory: 'For-Individuals',
      SubCategory: productCode === EProductCode.GTKR ? 'Loans' : 'Cards',
      Page: productCode === EProductCode.GTKR ? 'Cash-Loans' : 'Installment',
      SubPage: getSubPage(productCode),
    };
    TagManager.dataLayer({ dataLayer: pageViewDataLayer });
  }, [productCode]);

  const sendDataLayerInfo = () => {
    let dataLayer: IDataLayerEvent = {
      event: 'CardOrder',
      formName: 'Online-Card-Order',
      cardName: getSubPage(productCode),
    };
    if (productCode === EProductCode.GTKR) {
      dataLayer = {
        event: 'GetaLoan',
        formName: 'Cash-Loan',
      };
    }
    TagManager.dataLayer({ dataLayer });
  };

  const { prefix, number } = useMemo(() => {
    let prefix = '(_ _ _)';
    let number = '';
    if (phone && phone.length === 10) {
      const prefixStart = phone.substring(0, 3);
      if (prefixOptions.find(el => el.value === prefixStart)) {
        prefix = prefixStart;
        number = phone.substring(3);
        setVisible(true);
      }
    }
    return { prefix, number };
  }, [phone]);

  const handleClosepinModal = () => {
    setPinModal(!openPinModal);
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      accept: true,
      number,
      pin: '',
      prefix: { value: prefix, label: prefix },
    },
  });
  const watchField = watch();

  // ON SUBMIT
  const onSubmit = (data: IFormData) => {
    setLoading(true); // This loading waits socket event -> OTPPage: ESocketEvent.OTP
    const customerData = {
      pin: data.pin.toUpperCase(),
      phone: numberFormatter(data.prefix.value, data.number),
      productCode,
      source: channelType,
    };
    localStorage.setItem(PHONE_NUMBER, numberFormatter(data.prefix.value, data.number));
    localStorage.setItem(PIN, data.pin.toUpperCase());
    rest
      .post(API.loan.checkedCustomer, customerData, undefined, { withRespHeaders: true })
      .then(async res => {
        const processId = res.headers.location;
        localStorage.setItem(PROCESS_ID, res.headers.location);
        sendDataLayerInfo();
        if (utmParams.length) {
          const utmData = {
            data: utmParams,
            groupId: processId,
          };
          try {
            await rest.post<IUTMReq, void>(API.google.gtmSend, utmData, undefined, { skipGlobalError: true });
          } catch (err) {
            logger(err as any, ELogLevel.ERROR);
          }
        }
        setProcessId(processId);
      })
      .catch(error => {
        if (error.response.data?.errors?.length) {
          setResponseError(getErrorCodeTranslation(error.response.data.errors));
        }
        setLoading(false);
      });
  };

  const handlePinPhone = () => {
    setVisible(true);
  };

  // phone focus
  const setInputRef = (el: HTMLInputElement | null) => {
    phoneRef.current = el ?? undefined;
  };

  // pin validation
  const renderPinError = () => {
    const type = errors?.pin?.type;
    switch (type) {
      case 'minLength':
      case 'required':
        return <div className="error-text">{translate('common.error.finLength')}</div>;
      case 'pattern':
        return <div className="error-text">{errors?.pin?.message}</div>;
      default:
        return null;
    }
  };

  return (
    <>
      <Panel className="order-step-container" justifyContent={2}>
        <Panel
          textAlign={ETextAlign.CENTER}
          className={`order-step-container__box check__process ${channelType === ESalesChannel.KAPITAL_AZ && ' check__process__kb'}`}
        >
          <HeadingText
            classHeader={`check__process__title ${productCode === EProductCode.GTKR ? 'check__process__title__cash' : ''}`}
            text={
              productCode === EProductCode.GTKR ? (
                <span>
                  {translate('titles.cash.firstPart')} <span className={`dangerColor-${channelType}`}>{translate('titles.cash.secondPart')} </span>
                  {translate('titles.cash.thirdPart')}
                </span>
              ) : (
                <span>
                  {translate('titles.birKart.firstPart')}
                  <span className={`dangerColor-${channelType}`}>{` ${translate('titles.birKart.secondPart')}`} </span>
                  {` ${translate('titles.birKart.thirdPart')}`}
                  <span className={`dangerColor-${channelType}`}>{` ${translate('titles.birKart.fourthPart')}`}</span>
                  {` ${translate('titles.birKart.fifthPart')}`}
                </span>
              )
            }
          />
          <Container noPadding>
            <Panel justifyContent={2} alignItems={2}>
              <form onSubmit={handleSubmit(onSubmit)} className="check__process-form">
                <Panel className="check__process-form__items">
                  <Panel className="check__process-form__items__phone-box">
                    <Panel className="check__process-form__items__phone-box__input">
                      <div className="check__process-form__items__phone-box__input__label">
                        <span>{translate('firstStep.mobilePhone')} </span>
                      </div>
                      <Panel
                        justifyContent={0}
                        alignItems={2}
                        className={`check__process-form__items__phone-box__input__mask ${
                          !watchField.prefix.value.includes('_') ? 'phone-box-full' : ''
                        }`}
                      >
                        <Controller
                          name="prefix"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { name, onChange, value } }) => (
                            <ReactSelect
                              classNamePrefix="check__process-form__items__phone-box__input__mask__select"
                              name={name}
                              onChange={e => {
                                phoneRef.current?.focus();
                                onChange(e);
                              }}
                              value={value}
                              options={prefixOptions}
                              isSearchable={false}
                              asPrefix
                            />
                          )}
                        />

                        <Controller
                          name="number"
                          control={control}
                          rules={{
                            required: true,
                            validate: value => checkDuplicateCharacters(value),
                            minLength: 7,
                          }}
                          render={({ field: { ref, name, onChange, value } }) => (
                            <InputMask
                              ref={ref}
                              name={name}
                              onChange={e => {
                                onChange(onSanitizeValue(e));
                              }}
                              defaultValue={value}
                              className="check__process-form__items__phone-box__input__mask__numbers"
                              mask="999 99 99"
                              type="tel"
                              placeholder="___ __ __"
                              inputRef={el => setInputRef(el)}
                            />
                          )}
                        />
                      </Panel>
                    </Panel>
                    {errors?.number?.type === 'validate' && <div className="error-text">{translate('common.error.correctPhone')} </div>}
                  </Panel>

                  {visible && (
                    <>
                      <Panel className={`check__process-form__items__fin fin__${channelType}`}>
                        <img
                          src={InfoSolid}
                          className="check__process-form__items__fin__info"
                          onClick={() => setPinModal(!openPinModal)}
                          alt="info"
                          role="none"
                        />

                        <Controller
                          name="pin"
                          control={control}
                          rules={{
                            required: true,
                            minLength: 7,
                            pattern: {
                              value: pinFull,
                              message: translate('common.error.finCorrectly'),
                            },
                          }}
                          render={({ field: { ref, ...restData } }) => (
                            <Input
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...restData}
                              label={translate('firstStep.pin')}
                              maxLength={7}
                              autoComplete="off"
                              autoFocus
                              className="input-block"
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              onInput={e => {
                                const { value } = onSanitizeValue(e).target;
                                e.target.value = value.toUpperCase();
                                if (!pinFull.test(value)) {
                                  const checked = value.match(pinValidChar);
                                  e.target.value = checked ? checked.join('') : '';
                                }
                              }}
                            />
                          )}
                        />
                      </Panel>
                      {errors?.pin && renderPinError()}
                      {responseError && <div className="error-text">{responseError}</div>}
                      <Panel>
                        <Controller
                          control={control}
                          name="accept"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Panel
                              justifyContent={0}
                              marginTop={4}
                              marginBottom={4}
                              paddingLeft={1}
                              className="check__process-form__items__approved-content"
                            >
                              <Checkboxs value={value} onChange={onChange} />
                              <div className="check__process-form__items__approved-content__text">
                                <span>{translate('firstStep.pinPhone.firstPart')}</span>
                                <span className={`dangerColor-${channelType}`}>{translate('firstStep.pinPhone.secondPart')}</span>
                                <span>{translate('firstStep.pinPhone.thirdPart')}</span>
                                <span className={`dangerColor-${channelType}`}>{translate('firstStep.pinPhone.fourthPart')}</span>
                                <span>{translate('firstStep.pinPhone.fifthPart')}</span>
                              </div>
                            </Panel>
                          )}
                        />
                        {errors?.accept && <div className="error-text">{translate('common.actions.checked')}</div>}
                      </Panel>
                    </>
                  )}
                </Panel>

                <Panel className="check__process-form__btn">
                  <ButtonCommon
                    disable={!(!watchField.number.includes('_') && watchField.number.length > 0 && watchField.prefix.value.length === 3)}
                    handleClick={handlePinPhone}
                    channelType={channelType}
                    text={visible ? translate('actions.amountFormalizeCredit') : translate('common.actions.continue')}
                  />
                </Panel>
              </form>
            </Panel>
          </Container>
        </Panel>
      </Panel>

      {/* -------FIN InFO Modal------- */}

      <ReactModal style={styleModal} isOpen={openPinModal} onRequestClose={handleClosepinModal} shouldCloseOnOverlayClick={false} className="modal">
        <Panel>
          <Panel className="modal__header" justifyContent={4} alignItems={2}>
            <span className="modal__header__title">{translate('firstStep.example')}</span>
            <img onClick={handleClosepinModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
          </Panel>
        </Panel>
        <Panel className="modal-line" marginBottom={2} />
        <Panel justifyContent={2} paddingTop={2} className="check__process-form__imgs">
          <img src="/assets/images/pin.png" alt="pin" />
        </Panel>
      </ReactModal>
    </>
  );
};

export default PinAndPhone;
