import { Panel } from '@kapital-bank/kb-ui-design-system';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.scss';

const SkeletonProcessInfo = () => {
  return (
    <Panel className="process-info-skeleton">
      <Skeleton height={60} circle className="process-info-skeleton__img" />
      <Skeleton height={32} className="process-info-skeleton__title" />
      <Skeleton height={150} />
      <Skeleton height={64} className="process-info-skeleton__footer" />
    </Panel>
  );
};

export default SkeletonProcessInfo;
