import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import { IProductData } from 'shared/model';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import SkeletonConditions from './Skeleton';

import './index.scss';

interface IProps {
  productData?: IProductData;
}

const Conditions: FC<IProps> = ({ productData }) => {
  const { channelType } = useGeneralInfoContext();
  const [conditionsFirst, setConditionsFirst] = useState<string[]>([]);
  const [conditionsSecond, setConditionsSecond] = useState<string[]>([]);
  const conditions = useMemo(() => productData?.conditions || [], [productData]);

  const middleIndex = Math.ceil(conditions.length / 2);

  useEffect(() => {
    setConditionsFirst(conditions.slice(0, middleIndex));
    setConditionsSecond(conditions.slice(middleIndex));
  }, [conditions]);

  const createConditionsList = (list: string[]) => (
    <ul className="conditions__content__list">
      {list?.map((condition: string, i: number) => (
        <li className={`conditions__content__list__item disc__${channelType}`} key={i}>
          <span>{condition}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <Panel className="conditions">
      <Panel className={`conditions__title conditions__title__${channelType}`}>
        <span>{translate('common.conditions')}</span>
      </Panel>

      {productData?.title ? (
        <>
          <Panel className={`conditions__content conditions__content__${channelType}`}>
            <Row dataTestId="row">
              <Col md={6} sm={12}>
                {createConditionsList(conditionsFirst)}
              </Col>
              <Col md={6} sm={12}>
                {createConditionsList(conditionsSecond)}
              </Col>
            </Row>
          </Panel>
          <Panel className="conditions__content__add-info">{translate('common.addCoditionInfo')}</Panel>
        </>
      ) : (
        <SkeletonConditions />
      )}
    </Panel>
  );
};

export default Conditions;
