import React, { FC } from 'react';
import { Col, Panel, Row } from '@kapital-bank/kb-ui-design-system';

import AppStoreBlack from 'shared/assets/images/compressImage/app_store__black.svg';
import QRCodeBb from 'shared/assets/images/compressImage/bb-qr-code.svg';
import MobilePhoneImage from 'shared/assets/images/compressImage/mobile-phone-image.svg';
import GooglePayBlack from 'shared/assets/images/compressImage/google_play__black.svg';
import AppGalleryBlack from 'shared/assets/images/compressImage/app_gallery__black.svg';
import { translate } from 'shared/utils/translation';

import './index.scss';

const BirbankAppInfo: FC = () => {
  return (
    <Panel className="success__app">
      <div className="success__app__title">{translate('success.app.title')}</div>
      <div className="success__app__description">{translate('success.app.description')}</div>
      <Row dataTestId="row">
        <Col lg={7} xs={7} className="success__app__phone">
          <img src={MobilePhoneImage} alt="mobile" />
        </Col>
        <Col lg={4} xs={4}>
          <div className="success__app__qr">
            <div className="success__app__qr__title">{translate('success.app.qr')}</div>
            <div className="success__app__qr__code">
              <img src={QRCodeBb} alt="qr-code" />
            </div>
          </div>
          <div className="success__app__mobile__photos">
            <img src={AppStoreBlack} alt="app_store" />
            <img src={GooglePayBlack} alt="google_play" />
            <img src={AppGalleryBlack} alt="app_gallery" />
          </div>
        </Col>
      </Row>
    </Panel>
  );
};

export default BirbankAppInfo;
