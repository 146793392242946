import { baseApiUrl, strapiEnv } from 'shared/consts';
import { EFeedbackType } from 'shared/enum';

const prefix = '/v1';

export const API = {
  loan: {
    feedback: {
      options(type: EFeedbackType) {
        return `/api/feedbacks?processType=LOAN&feedbackType=${type}`;
      },
      save() {
        return `/api${prefix}/feedbacks`;
      },
    },
    get checkedCustomer() {
      return `${prefix}/loan-process/check`;
    },
    get verifyOtp() {
      return `${prefix}/process-otp/verify`;
    },
    get initOtp() {
      return `${prefix}/process-otp/send`;
    },
    get completeDelivery() {
      return `${prefix}/loan-process/delivery`;
    },
    get sendOrderDetail() {
      return `${prefix}/loan-process/create`;
    },
    get confirmInfo() {
      return `${prefix}/loan-process/customer-info`;
    },
    get getRegions() {
      return `${prefix}/regions`;
    },
    getOrderDetail(processId: string) {
      return `${prefix}/product-order-detail/${processId}`;
    },
    getProductInfo(product: string) {
      return `${prefix}/products/types/LOAN?code=${product}`;
    },
    getImage(image: string) {
      return `${baseApiUrl}${prefix}/media/loan/images/${image}.png`;
    },
    getGiftCardDetails() {
      return `${prefix}/gift-card`;
    },
  },
  transfer: {
    get cashTransfer() {
      return `${prefix}/loan-process/transfer`;
    },
  },
  questionnaire: {
    get getQuestionnaire() {
      return `${prefix}/questionnaires/questions`;
    },
  },
  delivery: {
    get getVenues() {
      return `/api/delivery/v1/venues`;
    },
    sendDeliveryDetail(venueId: string) {
      return `/api/delivery/v1/${venueId}/shipment-promises`;
    },
    get getDate() {
      return `/api/delivery/v1/available-date`;
    },
  },
  branch: {
    getBranch(serviceId: string) {
      return `/v1/q-matic/branches/?serviceId=${serviceId}`;
    },
    getWorkDays(branchId: string) {
      return `/v1/q-matic/branches/${branchId}/workdays`;
    },
    getWorkHours(branchId: string, day: string) {
      return `/v1/q-matic/branches/${branchId}/days/${day}/work-hours`;
    },
  },
  google: {
    get gtmSend() {
      return `/api${prefix}/analytic`;
    },
  },
};
