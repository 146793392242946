import React, { ReactElement } from 'react';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { Theme } from '@kb-front/ui-kit';
import Footer from '../components/birbank/Footer';
import Header from '../components/birbank/Header';
import FooterKb from '../components/kapital/FooterKb';
import HeaderKb from '../components/kapital/Header';
import { ESalesChannel } from '../enum';

const Layout: React.FC = ({ children }): ReactElement => {
  const { channelType } = useGeneralInfoContext();
  const isKBChannel = channelType !== ESalesChannel.TERMINAL && channelType !== ESalesChannel.BIRBANK_AZ;
  const header = channelType === ESalesChannel.TERMINAL ? '' : isKBChannel ? <HeaderKb /> : <Header />;
  const footer = channelType === ESalesChannel.TERMINAL ? '' : isKBChannel ? <FooterKb /> : <Footer />;
  return (
    <Theme.Provider>
      {({ theme }) => (
        <Theme theme={theme}>
          <div className={isKBChannel ? 'kb-design' : ''}>
            {header}
            <main>{children}</main>
            {footer}
          </div>
        </Theme>
      )}
    </Theme.Provider>
  );
};

export default Layout;
