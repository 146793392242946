import React, { FC, useState } from 'react';
import { Hr, Panel } from '@kapital-bank/kb-ui-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import BirbankLogo from 'shared/assets/images/compressImage/birbank-logo.svg';
import Container from '../../../Container';
import MobileNavLink from '../MobileNavLink';

import './index.scss';

const NavbarBrend: FC = () => {
  // const userBb =  translate('headerMenu.userTypeBb').map(({ label, path}: any, i: number) => (
  //     <li key={i+'bbnavuser'}><a href={path}> { label } </a></li>
  // ));

  const [openMenu, setMenu] = useState<boolean>(false);

  const toggleClass = () => {
    setMenu(!openMenu);
  };

  return (
    <Panel className="heading">
      <Container>
        <Panel className="heading__container">
          <Panel flexDirection={0} className="heading__container__content">
            <Panel marginRight={4}>
              <img src={BirbankLogo} alt="Logo" />
            </Panel>
            {/* <Panel paddingTop={3} className="heading__container__content__user">
                            <ul>
                                {userBb}
                            </ul>
                        </Panel> */}
            <Panel className="heading__container__content__hamburger">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button className="sign-in" type="button">
                <FontAwesomeIcon icon={faSignInAlt} />
              </button>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={`menu-btn ${openMenu ? 'open' : ''}`} onClick={toggleClass}>
                <div className="menu-btn__burger" />
              </div>
            </Panel>
          </Panel>
        </Panel>
      </Container>
      <Hr className="heading__hr" />

      {openMenu && <MobileNavLink />}
    </Panel>
  );
};

export default NavbarBrend;
