/**
 *
 * @param prefix type String  051
 * @param number type String 3 6 8  6 3  0 0
 * @returns formatting string
 * @example  ("051","3 6 8  6 3  0 0") => "0513686378"
 */
export const numberFormatter = (prefix: string, number: string): string => `${prefix}${number.replace(/\s/g, '')}`;

export const numberValidator = (value: string) => {
  return (
    value
      .replace(/\s/g, '')
      .split('_')
      .filter((item: string) => item.length !== 0)
      .join('').length < 7
  );
};
