import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'shared/utils/translation';

import './index.scss';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <h2 className="not-found__header">
        4
        <FontAwesomeIcon icon={faTimesCircle} size="xs" />
        4
        <i className="las la-phone-volume" />
      </h2>
      <h1 className="not-found__description">{translate('common.notFound.title')}</h1>
      {/* <div className="not-found__btn_holder">
        <ButtonCommon handleClick={goPage} channelType={channelType} className="not-found__btn" text={translate('common.notFound.basisPage')} />
      </div> */}
    </div>
  );
};

export default NotFoundPage;
