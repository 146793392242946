import { Panel } from '@kapital-bank/kb-ui-design-system';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Container from 'shared/components/Container';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { translate } from 'shared/utils/translation';
import { logger } from 'shared/utils/logger';
import SuccessIcon from 'shared/assets/images/compressImage/success.svg';
import UnsuccessIcon from 'shared/assets/images/compressImage/unsuccess-icon.svg';
import { IDetails, IErrorDetails, IFeedbackOption, IFinalProcessInfoDetails, IProductData, IProductDetails, ISuccessData } from 'shared/model';
import { API } from 'shared/api/loan';
import { EFeedbackType, EFinalPageType, ELang, EProductCode, EProductStatus, ESalesChannel, ProductType, ToastType } from 'shared/enum';
import { useLoading } from 'shared/context/loading';
import { dateFormatter } from 'shared/utils/formatter';
import { ToastTer } from 'shared/utils/toastr';
import { rejectFeedbackType } from 'shared/consts';
import BirbankAppInfo from './BirbankAppInfo';
import ProcessInfo from './ProcessInfo';
import FeedbackModal from './Feedback';
import FeedbackChoicesModal from './Feedback/FeedbackChoices';

import './index.scss';

const Final = () => {
  const POPUP_VISIBILTY_DELAY = 1500;

  const { channelType, rest, processId, productCode, lang } = useGeneralInfoContext();

  const { setLoading } = useLoading();
  const [errorData, setErrorData] = useState<IErrorDetails>({
    link: '',
    text: '',
    type: EFinalPageType.SUCCESS,
    code: '',
    title: '',
  });
  const [finalPageType, setFinalPageType] = useState<string>('');
  const [cardName, setcardName] = useState<string>();
  const [cash, setCash] = useState({
    amount: '',
    shipmentMethod: '',
  });
  const [hasGift, setHasGift] = useState<boolean>(false);

  const [card, setCard] = useState({
    amount: '',
    shipmentMethod: '',
    shipmentAddress: '',
    shipmentTime: '',
  });
  const [processInfoDetails, setProcessInfoDetails] = useState<IFinalProcessInfoDetails>({
    image: '',
    title: '',
    content: null,
    btnText: '',
    btnLink: '',
    hasHomeLink: false,
  });
  const [feedbackModalShown, setFeedbackModalShown] = useState(false);
  const [feedbackChoicesModalShown, setFeedbackChoicesModalShown] = useState(false);
  const [feedbackOption, setFeedbackOption] = useState<IFeedbackOption>();

  const handleCloseFeedbackModal = useCallback(() => {
    setLoading(true);
    rest
      .post(API.loan.feedback.save(), {
        processId,
        feedbackType: rejectFeedbackType,
        productType: ProductType.LOAN,
      })
      .then(() => {
        setFeedbackChoicesModalShown(false);
        setFeedbackOption(undefined);
        setFeedbackModalShown(false);
        ToastTer.success({ title: translate('success.info.toaster_text'), type: ToastType.SUCCESS });
      })
      .catch(e => {
        ToastTer.error({ title: e, type: ToastType.ERROR });
      })
      .finally(() => {
        setLoading(false);
      });

    setFeedbackModalShown(false);
    setFeedbackChoicesModalShown(false);
  }, [setFeedbackModalShown, setFeedbackChoicesModalShown]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setFeedbackModalShown(true);
      clearTimeout(timerId);
    }, POPUP_VISIBILTY_DELAY);
  }, []);

  const handleSubmitFeedback = (type: EFeedbackType) => {
    setLoading(true);
    rest
      .post(API.loan.feedback.save(), {
        processId,
        feedbackType: type,
        productType: ProductType.LOAN,
      })
      .then(() => {
        rest.get<IFeedbackOption>(API.loan.feedback.options(type)).then(({ data }) => {
          setFeedbackOption(data);
          setFeedbackChoicesModalShown(true);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseFeedbackChoicesModal = (selectedFeedbackOptions: number[]) => {
    const feedbackDetails = feedbackOption?.options
      .filter(item => selectedFeedbackOptions.includes(item.id))
      ?.map(item => ({
        feedbackId: item.id,
        feedbackOption: item.option,
      }));

    if (!feedbackDetails?.length) {
      setFeedbackChoicesModalShown(false);
      setFeedbackOption(undefined);
      setFeedbackModalShown(false);
      ToastTer.success({ title: translate('success.info.toaster_text'), type: ToastType.SUCCESS });
      return;
    }

    setLoading(true);

    rest
      .put(API.loan.feedback.save(), {
        processId,
        feedbackDetails,
      })
      .then(() => {
        setFeedbackChoicesModalShown(false);
        setFeedbackOption(undefined);
        setFeedbackModalShown(false);
        ToastTer.success({ title: translate('success.info.toaster_text'), type: ToastType.SUCCESS });
      })
      .catch(e => {
        ToastTer.error({ title: e, type: ToastType.ERROR });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isKbOrUm = useMemo(() => {
    return [ESalesChannel.KAPITAL_AZ, ESalesChannel.UMICO].includes(channelType);
  }, [channelType]);

  const redirectMainSite = useMemo(() => {
    return isKbOrUm ? `https://kapitalbank.az/${lang}` : 'https://birbank.az';
  }, [isKbOrUm, lang]);

  const curierText = translate('success.info.courier');
  const filiaText = translate('success.info.branch.text');
  const [cardDate, cardTime] = card?.shipmentTime?.split(' ') || [];

  const dataCash: ISuccessData[] = [
    { title: translate('common.credit.type'), amount: translate('success.cash') },
    { title: translate('common.credit.amount'), amount: `${cash.amount} AZN` },
    {
      title: translate('delivery.method'),
      amount:
        cash.shipmentMethod === 'BRANCH_ANY'
          ? translate('common.fromBranch')
          : cash.shipmentMethod === 'PAN'
          ? translate('delivery.depositToCard')
          : undefined,
    },
  ];

  const dataCard: ISuccessData[] = [
    { title: translate('common.credit.cardType'), amount: cardName },
    { title: translate('common.credit.limit'), amount: `${card.amount} AZN` },
    {
      title: translate('delivery.method'),
      amount:
        card.shipmentMethod === 'COURIER' ? translate('delivery.courier') : card.shipmentMethod === 'BRANCH' ? translate('common.fromBranch') : '',
      free: 'free',
    },
    {
      title: translate('delivery.address'),
      amount: card.shipmentAddress,
    },
    // {
    //   title: `${translate('delivery.date')}\\${translate('delivery.hour')}`,
    //   amount: `${dateFormatter.display(cardDate, 'dd-mm-yyyy')} \\ ${cardTime?.substring(0, 5).replace('-', ':')}`,
    // },
  ];

  const listCash = (list: ISuccessData[]) => (
    <>
      {hasGift && <h5 className="success__personal__information__title">{translate('success.cashTitle')}</h5>}
      <ul className="success__personal__information__list">
        {list?.map(({ title = '', amount, free }) => (
          <li className="success__personal__information__list__item" key={`${title + amount}`}>
            <div className="success__personal__information__list__item__title">{title}</div>
            <div className="success__personal__information__list__item__amount">
              {card.shipmentMethod === 'COURIER' && free ? (
                <div className={`free-curier  free-curier__${channelType}`}>{translate('common.free')}</div>
              ) : (
                ''
              )}
              <div> {amount}</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );

  const getSuccessContent = () => (
    <>
      {productCode === EProductCode.GTKR ? listCash(dataCash) : listCash(dataCard)}
      <Panel className="success__info">{card.shipmentMethod === 'COURIER' ? curierText : card.shipmentMethod === 'BRANCH' ? filiaText : ''}</Panel>
      {hasGift && (
        <Panel>
          <h5 className="success__personal__information__title">{translate('success.info.birbank_card_giftSectionTitle')}</h5>
          <p className="success__personal__information__gift_info">{translate('success.info.branch.text')}.</p>
        </Panel>
      )}
    </>
  );
  const generateErrorMessages = () => {
    let redirectLink;

    // if not telesales incident then redirect user to product website according to type.
    if (errorData.type !== EFinalPageType.TELESALES_INCIDENT) {
      redirectLink = redirectMainSite;
    } else {
      // if telesales incident user can retry and redirect to homepage.
      redirectLink = `/${lang}/order/${productCode}?t=${channelType}`;
    }

    return {
      ...processInfoDetails,
      image: EFinalPageType.TELESALES ? SuccessIcon : UnsuccessIcon,
      title: errorData.title,
      content: errorData?.text,
      btnText: errorData.type === EFinalPageType.TELESALES_INCIDENT ? translate('finalPage.btnText.againTry') : translate('finalPage.returnHomePage'),
      btnLink: redirectLink,
      hasHomeLink: false,
      hasError: true,
    };
  };

  const finalPageInfo = () => {
    switch (finalPageType) {
      case EFinalPageType.TECHERROR:
        return setProcessInfoDetails({
          ...processInfoDetails,
          image: UnsuccessIcon,
          title: translate('finalPage.title.forTechError'),
          content: translate('finalPage.contentText.forTechError'),
          btnText: translate('finalPage.btnText.againTry'),
          btnLink: `/${lang}/order/${productCode}?t=${channelType}`,
          hasHomeLink: false,
          hasError: true,
        });
      case EFinalPageType.SUCCESS:
        return setProcessInfoDetails({
          ...processInfoDetails,
          image: SuccessIcon,
          title: translate('finalPage.title.forSuccess'),
          content: productCode && getSuccessContent(),
          btnText: translate('finalPage.btnText.homePage'),
          btnLink: redirectMainSite,
          hasHomeLink: false,
        });
      case EFinalPageType.DEBIT_FLOW:
        return setProcessInfoDetails({
          ...processInfoDetails,
          image: UnsuccessIcon,
          title: errorData?.title,
          content: errorData?.text || translate('finalPage.contentText.forUnsuccess'),
          btnText: translate('finalPage.btnText.orderDebet'),
          // kapitalbank.az not worked /az/{url} for lang AZ. excluded it from path.
          btnLink: isKbOrUm ? `https://www.kapitalbank.az${lang !== ELang.AZ ? `/${lang}` : ''}/cards/simpleCards` : 'https://birbank.az/cards/all',
          hasHomeLink: true,
          homeLink: redirectMainSite,
        });
      default:
        return setProcessInfoDetails(generateErrorMessages());
    }
  };

  useEffect(() => {
    if (finalPageType) {
      finalPageInfo();
    }
  }, [finalPageType, hasGift, card, cash]);

  useEffect(() => {
    setLoading(false); // Hide loading that was set in DeliveryPage (Branch or Currier)
    if (processId) {
      rest
        .get<IDetails>(API.loan.getOrderDetail(processId))
        .then(({ data }) => {
          if (!data.error && (data.progressingStatus === EProductStatus.END || data.progressingStatus === EProductStatus.COMPLETED)) {
            const successInfo = data.product;
            const { amount, code, shipmentMethod, shipmentAddress, shipmentTime } = successInfo ?? ({} as IProductDetails);
            setFinalPageType(EFinalPageType.SUCCESS);
            // get Card type
            rest.get<Array<IProductData>>(API.loan.getProductInfo(code)).then(({ data: productData }) => {
              const title = productData[0]?.title ?? '';
              setcardName(title.substring(0, title.lastIndexOf(' ')));
            });
            setHasGift(data.calculator?.giftCardSelected);
            // get SuccessInfo data
            if (code === EProductCode.GTKR) {
              setCash({ ...cash, amount, shipmentMethod });
            } else {
              setCard({
                ...card,
                amount,
                shipmentMethod,
                shipmentAddress,
                shipmentTime,
              });
            }
          } else if (data.error?.type && data.error?.text) {
            setErrorData(data.error);
            setFinalPageType(data.error?.type);
          } else {
            setFinalPageType(EFinalPageType.TECHERROR);
          }
        })
        .catch(err => {
          /* Unhandled error. */
          setFinalPageType(EFinalPageType.TECHERROR);
          logger(err);
        });
    } else {
      setFinalPageType(EFinalPageType.TECHERROR);
    }
  }, [productCode]);

  return (
    <Panel className="success">
      <Container className={`success__container success__container__${channelType}`}>
        <ProcessInfo processInfoDetails={processInfoDetails} />
        <Panel>
          <BirbankAppInfo />
        </Panel>
      </Container>
      <FeedbackModal modalOpen={feedbackModalShown} onSubmit={handleSubmitFeedback} onClose={handleCloseFeedbackModal} />
      <FeedbackChoicesModal option={feedbackOption} modalOpen={feedbackChoicesModalShown} handleToggleModal={handleCloseFeedbackChoicesModal} />
    </Panel>
  );
};

export default Final;
