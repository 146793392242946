import React, { useState } from 'react';
import { render } from 'react-dom';
import ToastItem from './item';

const uid = () => {
  return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);
};

const selector = (el: string) => {
  return document.querySelector(el);
};

let toasts: Array<any> = [];

const Toast = () => {
  const [items, setItems] = useState(toasts);

  const handleRemoveToast = (id?: string) => {
    setTimeout(() => {
      toasts = toasts.filter((item: any) => item.id !== id);
      setItems(toasts);
    }, 500);
  };
  return (
    <>
      {items.map((item: any) => (
        <ToastItem key={item.id} item={item} handleRemoveToast={handleRemoveToast} />
      ))}
    </>
  );
};

export const createToast = (data: any) => {
  let container = selector('#kb-toast');
  const { title, type } = data;

  toasts.push({ id: uid(), title, type });
  if (!container) {
    container = document.createElement('div');
    document.body.appendChild(container);
  }
  render(<Toast />, container);
};

export const ToastTer = {
  error: (data: any) => createToast(data),
  success: (data: any) => createToast(data),
};
