import React from 'react';
import ReactDOM from 'react-dom';
import { ELogLevel } from 'shared/enum';
import { logger } from 'shared/utils/logger';

import { translationInit } from 'shared/utils/translation';

import App from './App';

translationInit()
  // eslint-disable-next-line react/no-render-return-value
  .then(() => ReactDOM.render(<App />, document.getElementById('app')))
  .catch((err: any) => logger(err, ELogLevel.ERROR));
