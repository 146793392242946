import React, { FC } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import ButtonCommon from 'shared/components/ButtonCommon';
import { styleModal } from 'shared/assets/styles/react-modal';
import { translate } from 'shared/utils/translation';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { IQuestion } from 'shared/model';

import Question from './Question';

import './index.scss';

interface IProps {
  openQuestionnaireModal: boolean;
  handleCloseQuestionnaireModal: () => void;
  dataQuestionnaire: IQuestion[];
  setDataQuestionnaire: (questions: IQuestion[]) => void;
}

const Questionnaire: FC<IProps> = ({ openQuestionnaireModal, handleCloseQuestionnaireModal, dataQuestionnaire, setDataQuestionnaire }) => {
  const { channelType } = useGeneralInfoContext();
  return (
    <ReactModal
      style={styleModal}
      isOpen={openQuestionnaireModal}
      onRequestClose={handleCloseQuestionnaireModal}
      shouldCloseOnOverlayClick={false}
      className="questionnaire-modal modal"
    >
      <Panel className="modal__header" justifyContent={4} alignItems={2}>
        <span className="modal__header__title">{translate('checkInfo.survey.title')}</span>
        <img onClick={handleCloseQuestionnaireModal} src={CloseIcon} alt="close" className="modal-close" role="none" />
      </Panel>
      <Panel className="modal-line" />
      <Panel className="modal-common-context questionnaire-modal__context">
        <Panel className="questionnaire-modal__context__info" justifyContent={0} marginTop={3} marginBottom={2}>
          <Panel>
            <img src={InfoSolid} alt="info" />
          </Panel>
          <Panel>
            <Panel>
              <span className={`danger-${channelType}`}> {translate('checkInfo.survey.info.firstPart')}</span>
              <span> {translate('checkInfo.survey.info.secondPart')}</span>
              <span className={`danger-${channelType}`}> {translate('checkInfo.survey.info.thirdPart')}</span>
            </Panel>
            <Panel>
              <span> {translate('checkInfo.modal.contactBranches')}</span>
            </Panel>
          </Panel>
        </Panel>

        <ul className="questionnaire__context__list">
          <Question channelType={channelType} dataQuestionnaire={dataQuestionnaire} setDataQuestionnaire={setDataQuestionnaire} />
        </ul>
        <ButtonCommon channelType={channelType} text={translate('common.actions.confirm')} handleClick={handleCloseQuestionnaireModal} />
      </Panel>
    </ReactModal>
  );
};

export default Questionnaire;
