import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import InfoSolid from 'shared/assets/images/compressImage/info-solid.svg';
import { ICrossSellData } from 'shared/model';
import Checkboxs from 'shared/components/Checkboxs';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import InsuranceModal from '../InsuranceModal';

import './index.scss';

interface IProps {
  crossSellList: ICrossSellData[];
  selectedList: string[];
  setSelectedList: Dispatch<SetStateAction<string[]>>;
  titleForMobile: React.ReactNode;
  titleForDesktop: React.ReactNode;
}

const InsuranceProducts: FC<IProps> = ({ selectedList, setSelectedList, crossSellList, titleForDesktop, titleForMobile }) => {
  const { channelType } = useGeneralInfoContext();
  const [openInsuranceModal, setInsuranceModal] = useState(false);
  const [insuranceModalData, setModalData] = useState<ICrossSellData>({
    amount: '',
    code: '',
    truncated: '',
    detail: '',
    generalInformation: '',
    note: '',
    title: '',
    summary: '',
  });

  const handleCloseModal = () => {
    setInsuranceModal(!openInsuranceModal);
  };

  const openModal = (item: ICrossSellData) => {
    setInsuranceModal(!openInsuranceModal);
    setModalData({
      ...insuranceModalData,
      amount: item?.amount,
      code: item?.code,
      generalInformation: item?.generalInformation,
      note: item?.note,
      title: item?.title,
      detail: item?.cost?.detailed,
    });
  };

  const handlecheckbox = (code: string) => {
    if (selectedList.includes(code)) {
      setSelectedList(selectedList.filter((val: string) => val !== code));
    } else {
      setSelectedList([...selectedList, code]);
    }
  };

  return (
    <Panel className="scoring__card__insurance">
      <Panel className="desktop">
        {titleForDesktop}
        <div>
          {crossSellList?.map(item => (
            <Panel className="scoring__card__insurance__item" flexDirection={0} justifyContent={4} marginTop={2} key={item.code}>
              <Panel flexDirection={0} alignItems={2} className="scoring__card__insurance__item__title">
                <Checkboxs
                  id={item.code}
                  name={item.title}
                  value={selectedList.includes(item.code)}
                  onChange={() => handlecheckbox(item.code)}
                  className="scoring__card__insurance__item__checkbox"
                />
                <Panel>
                  <Panel className="scoring__card__insurance__item__title__text">{item?.title}</Panel>
                  <Panel className="scoring__card__insurance__item__title__summary">{item?.summary}</Panel>
                </Panel>
              </Panel>
              <Panel flexDirection={0} alignItems={2} className="scoring__card__insurance__item__info__box">
                <Panel className={`scoring__card__insurance__item__info-text info__${channelType}`}>{item?.cost?.truncated}</Panel>
                <img src={InfoSolid} alt="info" onClick={() => openModal(item)} role="none" />
              </Panel>
            </Panel>
          ))}
        </div>
      </Panel>

      <Panel className="scoring__mobile">
        {titleForMobile}
        <div>
          {crossSellList?.map(item => (
            <Panel className="scoring__card__insurance__item" marginBottom={2} key={item.code}>
              <Panel flexDirection={0} className="scoring__card__insurance__item__mobile__title">
                <Panel>
                  <Panel className="scoring__card__insurance__item__mobile__title__text">{item?.title}</Panel>
                  <Panel className="scoring__card__insurance__item__mobile__title__summary">
                    {item?.summary}
                    <Panel className={`scoring__card__insurance__item__context__info info__${channelType}`} onClick={() => openModal(item)}>
                      &nbsp;{translate('common.actions.moreInfo')}
                    </Panel>
                  </Panel>
                </Panel>
              </Panel>
              <Panel className="scoring__card__insurance__item__mobile__info-text">{item?.cost?.truncated}</Panel>
              <Checkboxs
                id={item.code}
                name={item.title}
                value={selectedList.includes(item.code)}
                onChange={() => handlecheckbox(item.code)}
                className="scoring__card__insurance__item__checkbox"
              />
            </Panel>
          ))}
        </div>
      </Panel>
      <InsuranceModal {...{ openInsuranceModal, handleCloseModal, channelType, crossSellList, insuranceModalData }} />
    </Panel>
  );
};

export default InsuranceProducts;
