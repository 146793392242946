import React from 'react';
import { Col, EIconName, Row } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import Container from '../../Container';
import Copyright from '../../CopyRight';
import MobileAppsCommon from '../../MobileAppsCommon';
import SocialMediaCommon from '../../SocialMediaCommon';

import './index.scss';

interface ILink {
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  path: string;
}

const Footer = () => {
  const { channelType } = useGeneralInfoContext();
  const mobileAppsBb = translate('footer.Bb.mobileAppsBb');

  const socialIconsBB = [
    { icon: EIconName.FACEBOOK, href: 'https://www.facebook.com/birbank' },
    { icon: EIconName.INSTAGRAM, href: 'https://www.instagram.com/birbank.az/' },
    { icon: EIconName.YOUTUBE, href: 'https://www.youtube.com/channel/UC1p5TDF6qfzUm4sPqSYSGUA' },
    { icon: EIconName.TWITTER, href: 'https://www.instagram.com/birbank.az/' },
    { icon: EIconName.LINKEDIN, href: 'https://www.youtube.com/channel/UC1p5TDF6qfzUm4sPqSYSGUA' },
  ];

  const footerLinkFirts = translate('footer.Bb.dataFooterLink.linksFirst').map(({ label, path }: ILink, i: number) => (
    <li key={`${i * 2}firstlink`} className="footer__links__item">
      <a href={path}>{label}</a>
    </li>
  ));

  const footerLinkSecond = translate('footer.Bb.dataFooterLink.linksSecond').map(({ label, path }: ILink, i: number) => (
    <li key={`${i * 2}+secondlink`} className="footer__links__item">
      <a href={path}>{label}</a>
    </li>
  ));

  return (
    <footer className="footer">
      <Container>
        <Row dataTestId="row">
          <Col sm={9}>
            <SocialMediaCommon {...{ socialIconsBB, channelType }} />
            <ul className="footer__links first">{footerLinkFirts}</ul>
            <ul className="footer__links second">{footerLinkSecond}</ul>
          </Col>
          <Col sm={3} className="mobile-apps-container">
            <MobileAppsCommon {...{ mobileAppsBb, channelType }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Copyright text=" «Kapital Bank» ASC. Bütün hüquqlar qorunur." />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
