import React, { FC, useCallback, useState } from 'react';
import { EAlignItems, EElementSize, EJustifyContent, Panel } from '@kapital-bank/kb-ui-design-system';
import ReactModal from 'react-modal';

import { styleModal } from 'shared/assets/styles/react-modal';
import CloseIcon from 'shared/assets/images/compressImage/close-soft-icon.svg';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import ButtonCommon from 'shared/components/ButtonCommon';
import { translate } from 'shared/utils/translation';
import { EFeedbackType } from 'shared/enum';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';

import './index.scss';

interface IProps {
  modalOpen: boolean;
  onSubmit: (option: EFeedbackType) => void;
  onClose: () => void;
}

const FeedbackModal: FC<IProps> = ({ modalOpen, onSubmit, onClose }) => {
  const { channelType } = useGeneralInfoContext();
  const feedbackCases = Object.values(EFeedbackType);
  const [selectedFeedbackCase, setSelectedFeedbackCase] = useState<EFeedbackType>();

  const isMobile = useMediaQuery('(max-width: 500px)');

  const handleSelectFeedback = (fbCase: EFeedbackType): void => {
    setSelectedFeedbackCase(fbCase);
    if (isMobile) {
      onSubmit(fbCase);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(() => {
    if (selectedFeedbackCase) {
      onSubmit(selectedFeedbackCase);
    }
  }, [onSubmit, selectedFeedbackCase]);

  return (
    <ReactModal style={styleModal} isOpen={modalOpen} onRequestClose={handleClose} shouldCloseOnOverlayClick={false} className="modal feedback">
      <>
        <Panel>
          <Panel className="modal__header" justifyContent={4} alignItems={2}>
            <h4 className="feedback__header_text">{translate(`feedback.title`)}</h4>
            <img className="modal-close" onClick={handleClose} src={CloseIcon} alt="close" role="none" />
          </Panel>
        </Panel>
        <Panel className="modal-common-context">
          <Panel className="feedback__icons_holder" justifyContent={EJustifyContent.SPACE_AROUND}>
            {feedbackCases.map(feedbackCase => {
              return (
                <Panel key={feedbackCase} className="feedback__icons_holder__icon_content">
                  <Panel
                    onClick={() => handleSelectFeedback(feedbackCase)}
                    className={`feedback__icons_holder__icon_box ${
                      feedbackCase === selectedFeedbackCase && 'feedback__icons_holder__icon_box_selected'
                    }`}
                    alignItems={EAlignItems.CENTER}
                    justifyContent={EJustifyContent.CENTER}
                  >
                    <img src={`/assets/images/feedback/${feedbackCase}.svg`} alt={feedbackCase} />
                  </Panel>
                  <span>{translate(`feedback.options.${feedbackCase}`)}</span>
                </Panel>
              );
            })}
          </Panel>
          <Panel className="modal-line-bottom" />
          <Panel marginTop={7} className="approve__box" justifyContent={EJustifyContent.END}>
            <ButtonCommon
              size={isMobile ? EElementSize.LG : EElementSize.MD}
              channelType={channelType}
              className="approve__box__btn"
              text={translate('common.actions.judge')}
              disable={!selectedFeedbackCase}
              handleClick={handleOnSubmit}
            />
          </Panel>
        </Panel>
      </>
    </ReactModal>
  );
};

export default FeedbackModal;
