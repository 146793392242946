import React, { FC } from 'react';
import CloseIcon from 'shared/assets/images/compressImage/close_white.svg';
import { ToastType } from 'shared/enum';

import './index.scss';

interface IProps {
  item?: any;
  handleRemoveToast(id: any): void;
}

const ToastItem: FC<IProps> = ({ item, handleRemoveToast }) => {
  return (
    <div className={`toast-box ${item.type === ToastType.SUCCESS ? 'toast-box__success' : ''}`} id={item.id}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: item.title }} />
      <img src={CloseIcon} alt="close" onClick={() => handleRemoveToast(item.id)} role="none" />
    </div>
  );
};
export default ToastItem;
