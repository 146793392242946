import React, { FC, useEffect, useState } from 'react';

import Conditions from 'components/Conditions';
import ProductInfo from 'components/ProductInfo';
import { API } from 'shared/api/loan';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { IProductData } from 'shared/model';

import { ESalesChannel } from '../../enum';
import Container from '../Container';

const OrderStepWrapper: FC = ({ children }) => {
  const { channelType, rest, productCode } = useGeneralInfoContext();
  const [productData, setProductData] = useState<IProductData>({});

  useEffect(() => {
    rest.get<Array<IProductData>>(API.loan.getProductInfo(productCode)).then(response => {
      setProductData(response?.data[0]);
    });
  }, []);

  return (
    <Container fluid={channelType === ESalesChannel.KAPITAL_AZ || channelType === ESalesChannel.UMICO}>
      {/* <BreadCrumb /> */}
      <ProductInfo productData={productData} />
      <div className="order-step-wrapper">{children}</div>
      {/* <Calculator /> */}
      <Conditions productData={productData} />
    </Container>
  );
};

export default OrderStepWrapper;
