import React, { FC } from 'react';
import { Button, EElementSize, Panel } from '@kapital-bank/kb-ui-design-system';

import { translate } from 'shared/utils/translation';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

interface IProps {
  duration?: number;
  setDuration?: any;
  scoringData?: any;
  setPeriods?: any;
}

const Months: FC<IProps> = ({ duration, setDuration, scoringData, setPeriods }) => {
  const { channelType } = useGeneralInfoContext();
  const { orderOffers } = scoringData.calculator;
  const handleClick = (month: string) => {
    setDuration(parseInt(month, 10));
    setPeriods(orderOffers[month]);
  };

  const monthButtons = Object.keys(orderOffers).map((month, i) => (
    <Button
      key={i}
      className={`${month === duration?.toString() ? `months_selected-${channelType}` : `months-btn__${channelType}`}`}
      onClick={() => handleClick(month)}
      size={EElementSize.MD}
    >
      {month}
    </Button>
  ));

  return (
    <Panel className="credit-month">
      <Panel className="credit-month__label">
        <span>
          {translate('common.credit.term')} ({translate('common.month')?.toUpperCase()})
        </span>
      </Panel>
      <Panel justifyContent={0} alignItems={2} className={`months  ${Object.values(orderOffers).length > 4 ? 'credit-month-many' : ''}`}>
        {monthButtons}
      </Panel>
    </Panel>
  );
};

export default Months;
