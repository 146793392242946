import React from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';
import Skeleton from 'react-loading-skeleton';
import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';

import './index.scss';

const SkeletonConditions = () => {
  const { channelType } = useGeneralInfoContext();
  const renderSkeletonColumn = (rows: number) => (
    <Panel className="skeleton__conditions__column">
      {[...Array(rows)].map(() => (
        <Panel marginBottom={8} className="skeleton__conditions__item" key={`${Math.random()}conditionsSkleten`}>
          <Skeleton height={60} />
        </Panel>
      ))}
    </Panel>
  );
  return (
    <Panel className={`skeleton__conditions skeleton__conditions__${channelType}`}>
      {renderSkeletonColumn(4)}
      {renderSkeletonColumn(4)}
    </Panel>
  );
};

export default SkeletonConditions;
