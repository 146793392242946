import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { translate } from 'shared/utils/translation';
import { ELang } from '../enum';
import { baseApiUrl } from '../consts';
import { IValidationError } from '../model';

type TRequestMethod = 'get' | 'post' | 'put';

interface AxiosResp<T> {
  headers?: any;
  data: T;
  status?: number;
}

interface RestOptions {
  skipGlobalError?: boolean;
  withRespHeaders?: boolean;
  errorTitle?: string;
}

export const restClient = (lang: ELang, commonErrorCallback: () => void) => {
  const axios = Axios.create();

  const requestApi = <D, T>(
    method: TRequestMethod,
    url: string,
    data?: D,
    config: AxiosRequestConfig = {},
    options: RestOptions = {}
  ): Promise<AxiosResp<T>> => {
    const requestConfig: AxiosRequestConfig = {
      ...config,
      baseURL: baseApiUrl,
      headers: {
        ...config.headers,
        'Accept-Language': lang,
      },
      data,
      method,
      url,
    };

    // eslint-disable-next-line no-async-promise-executor,@typescript-eslint/no-misused-promises
    return new Promise<AxiosResp<T>>(async (resolve, reject) => {
      try {
        const axiosResponse = await axios.request<T>(requestConfig);
        const response = { data: axiosResponse.data, ...(options.withRespHeaders ? { headers: axiosResponse.headers } : {}) };
        resolve(response);
      } catch (err) {
        const error = err as Error | AxiosError;
        let errorMsg = '';
        let skipAxiosError = false;
        if (Axios.isAxiosError(error)) {
          const errorMessages = error.response?.data?.errors;
          const status = error.response?.status;
          // in case there is "errors" array we should handle it on its component and show error message.
          if (status !== 409 && !errorMessages?.length) {
            errorMsg = translate('common.error.optimizationOngoing');
            if (
              status === 400 &&
              (error?.response?.data?.error === 'DROPOFF_OUTSIDE_OF_DELIVERY_AREA' || error?.response?.data?.error === 'VENUE_CLOSED')
            ) {
              skipAxiosError = true;
            }
          } else {
            skipAxiosError = true;
          }
        } else {
          const params = {
            code: error.name || 'UNKNOWN_ERROR',
            message: error.message || translate('common.error.optimizationOngoing'),
          };
          errorMsg = options.errorTitle ?? `${params.code}: ${params.message}`;
        }
        if (!options.skipGlobalError && !skipAxiosError) {
          commonErrorCallback();
        }
        reject(err);
      }
    });
  };

  const getMethod = async <T>(url: string, config?: AxiosRequestConfig, options?: RestOptions) => {
    return requestApi<undefined, T>('get', url, undefined, config, options);
  };

  const post = async <D, T>(url: string, data?: D, config?: AxiosRequestConfig, options?: RestOptions) => {
    return requestApi<D, T>('post', url, data, config, options);
  };

  const put = async <D, T>(url: string, data?: D, config?: AxiosRequestConfig, options?: RestOptions) => {
    return requestApi<D, T>('put', url, data, config, options);
  };

  return {
    get: getMethod,
    post,
    put,
  };
};

export const getErrorCodeTranslation = (errors: IValidationError[]): string => {
  // currently we have 1 validation error at a time. But considered much possible
  const errorTexts: string[] = [];
  errors.forEach(err => {
    switch (err.code) {
      case 'CARD_CHECK_RESIDENCE_FAILED':
        return errorTexts.push(translate('common.error.foreignCardTransferNotAvailable'));
      case 'CARD_CHECK_PAN_FAILED':
        return errorTexts.push(translate('common.error.cardNumberNotFound'));
      case 'PIN_NOT_FOUND':
        return errorTexts.push(translate('common.error.pinNotCorrect'));
      case 'GENERAL_PIN':
        return errorTexts.push(translate('common.error.finLength'));
      default:
        return null;
    }
  });

  return errorTexts.join('. ');
};
