import React from 'react';
import { Panel } from '@kapital-bank/kb-ui-design-system';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import { ELang } from 'shared/enum';
import { changeLang, translate } from 'shared/utils/translation';
import KbLogo from 'shared/assets/images/compressImage/kb-logo.svg';

import './index.scss';

const NavbarBrendKb = () => {
  const { lang } = useGeneralInfoContext();

  const userKb = translate('headerMenu.userTypeKb').map(({ label, path }: any, i: number) => (
    <li key={`${i}userKb`}>
      <a href={path}>{label}</a>
    </li>
  ));

  const navbarLangs = Object.values(ELang)
    .filter(lng => lng !== lang)
    .map(filteredLang => (
      <div key={filteredLang} className="navbar__brend__kb__content__lang" onClick={() => changeLang(filteredLang)} role="none">
        {filteredLang.toUpperCase()}
      </div>
    ));

  return (
    <Panel className="navbar__brend__kb">
      <Panel className="navbar__brend__kb__logo">
        <img src={KbLogo} alt="Logo" />
      </Panel>
      <Panel className="navbar__brend__kb__content" flexDirection={0}>
        <ul>{userKb}</ul>
        {navbarLangs}
      </Panel>
    </Panel>
  );
};

export default NavbarBrendKb;
