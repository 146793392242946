import React, { FC } from 'react';

import { useGeneralInfoContext } from 'shared/context/WithGeneralInfo/GeneralInfoContext';
import './index.scss';

interface IProps {
  onChange?: () => void;
  value?: boolean;
  id?: string;
  name?: string;
  className?: string;
}

const Checkboxs: FC<IProps> = ({ value, onChange, id, name, className }) => {
  const { channelType } = useGeneralInfoContext();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`checkbox__container ${className}`} htmlFor={id}>
      <input type="checkbox" checked={value} onChange={onChange} className="checkbox-input" id={id} name={name} />
      <span className={`checkmark checkmark__${channelType}`} />
    </label>
  );
};

export default Checkboxs;
